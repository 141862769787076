import React, { Component } from 'react';
import {withStyles} from "@material-ui/core/styles";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

const drawerWidth = 400;

const styles = theme => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    }
});

class SideContent extends Component {

    state = {
        open: this.props.open
    };

    componentWillReceiveProps(nextProps) {
        //console.log(nextProps.open);
        //if(!this.state.open) {
            this.setState({
                open: nextProps.open
            });
        //}
    }

    open = () => {
        this.setState({open:true});
    };

    close = () => {
        this.setState({open:false});
    };

    render() {
        const { classes } = this.props;

        return(
            <SwipeableDrawer
                className={classes.drawer}
                anchor="right"
                open={this.state.open}
                onClose={this.close}
                onOpen={this.open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                {this.props.content}
            </SwipeableDrawer>
        )
    }
}

export default withStyles(styles, { withTheme: true })(SideContent);