import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core";
import { API } from 'aws-amplify';
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import Utils from '../../utils';
import SideContent from "./SideContent";
import OrderDetails from "./OrderDetails";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
    tableWrapper: {
        overflowX: 'auto',
        padding: 15
    },
    table: {
        width: '100%'
    },
    tableFirstCell: {
        paddingLeft: 0
    },
    tableLastCell: {
        paddingRight: "0 !important"
    }
});

class OrdersTable extends Component {

    orderApiPath = '/orders';
    clientApiPath = '/clients';

    state = {
        selectedOrderId: false,
        openSidebarContent: false,
        content: '<div />',
        orders: false,
        orderDirection:false,
        clients:false,
        clientFilterBy:false,
        refFilterBy:''
    };

    basketUpdate =() => {
        this.props.basketUpdate();
    };

    getData = async () => {
        const { user } = this.props;
        const { clientFilterBy } = this.state;

        if(user.ClientName==='Admin' && !clientFilterBy) {

            try {
                const clients = await API.get("PrintPortalAPI", this.clientApiPath, {
                    headers: {}
                });

                this.setState({clients:clients.data});

            } catch(e) {
                console.log(e);
            }

            try {
                let orders;
                orders = await API.get("PrintPortalAPI", this.orderApiPath, {
                    headers: {},
                });

                this.setState({orders: orders.data});
            } catch (e) {
                console.log(e);
            }
        } else {
            try {
                let orders;
                orders = await API.get("PrintPortalAPI", this.orderApiPath + '/client/' + user.Client, {
                    headers: {},
                });

                this.setState({orders: orders.data});
            } catch (e) {
                console.log(e);
            }
        }

    };

    componentDidMount = async () => {
        await this.getData();
    };

    viewOrder = (order) => {
        this.setState({
            selectedOrderId: order.orderId,
            openSidebarContent: true,
            content:<OrderDetails closeOrderDetails={this.closeOrderDetails} basketUpdate={this.basketUpdate} user={this.props.user} userGroup={this.props.userGroup} order={order} />
        });
    };

    closeOrderDetails = () => {
        this.setState({
            openSidebarContent: false,
            selectedOrderId: false
        })
    };

    sortBy = (key) => {
        let { orderDirection } = this.state;

        let orders = this.state.orders;
        orders = Utils.sortByKey(orders, key, orderDirection);


        if(orderDirection==='ASC') {
            orderDirection='DESC';
        } else {
            orderDirection='ASC';
        }

        this.setState({orders, orderDirection, openSidebarContent:false})
    };

    handleClientFilter = async (option, noEv) => {
        if(!option) {
            option = {};
            option.value='all';
        }
        this.setState({
            orders:false,
            openSidebarContent: false,
            refFilterBy:''
        });

        let value;
        if(!noEv) {
            value = option.value;
        } else {
            value = this.state.clientFilterBy;
        }

        let orders;
        if(value==='all' || !value || value==='') {
            orders = await API.get("PrintPortalAPI", this.orderApiPath, {
                headers: {},
            });

            this.setState({clientFilterBy:false});
            this.setState({orders: orders.data});
        } else {
            orders = await API.get("PrintPortalAPI", this.orderApiPath + '/client/' + value, {
                headers: {},
            });

            this.setState({
                orders: orders.data,
                clientFilterBy:value,
                orderDirection: false
            });
        }
    };

    handleRefChange = async (e) => {
        const value = e.target.value;
        this.setState({
            refFilterBy: value,
            openSidebarContent: false,
            orderDirection: false
        });

        if (!value) {
            if (this.state.clientFilterBy) {
                await this.handleClientFilter(false, true);
            }
        }
    };

    handleRefFilter = async(e) => {
        const { refFilterBy } = this.state;
        const { userGroup,  user } = this.props;

        if(e.key && e.key!=='Enter') {
            return;
        }

        this.setState({
            orders:false
        });

        if(!refFilterBy || refFilterBy==='') {
            this.setState({
                refFilterBy:''
            });
            if (!this.state.clientFilterBy) {
                this.setState({
                    clientFilterBy: 'all'
                })
            }

            await this.handleClientFilter(false, true);

        } else {
            try {
                let orders;
                if(userGroup==='Admin') {
                    orders = await API.post("PrintPortalAPI", this.orderApiPath + '/search', {
                        headers: {},
                        body: {
                            ref: refFilterBy.toLowerCase()
                        }
                    });
                } else {
                    orders = await API.post("PrintPortalAPI", this.orderApiPath + '/search/client', {
                        headers: {},
                        body: {
                            ref: refFilterBy.toLowerCase(),
                            client:user.Client
                        }
                    });
                }


                this.setState({
                    orders: orders.data
                });


            } catch (e) {
                console.log(e);
            }
        }

    };

    render() {
        const { classes, userGroup } = this.props;
        const { orderDirection, clients, clientFilterBy } = this.state;
        const ORDERS = this.state.orders;

        let orderIcon = false;
        if(orderDirection==='DESC') {
            orderIcon = (<ArrowDownwardIcon />);
        }
        if(orderDirection==='ASC') {
            orderIcon = (<ArrowUpwardIcon />);
        }

        let clientFilter = false;
        let currentClient = false;

        if(clients) {

            const options = [{
                'name' : 'All',
                'value' : 'all'
            }].concat(
                clients.map(CLIENT => {

                    if(clientFilterBy && clientFilterBy===CLIENT.ID) {
                        currentClient = CLIENT.Name
                    }

                    return(
                        {
                            name : CLIENT.Name,
                            value : CLIENT.ID
                        }
                    )
                })
            );

            clientFilter = (
                    <Grid item md={6} sm={6} xs={6}>
                    <Autocomplete
                        options={options}
                        onChange={(e, option) => this.handleClientFilter(option)}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                            <TextField {...params} label='Filter client' variant="outlined" fullWidth />
                        )}
                    />

                    { (currentClient ? <p>Filtered by client : <b>{currentClient}</b></p> : '') }

                </Grid>
            );
        }


        let refFilter = (
            <Grid item md={6} sm={6} xs={6}>
                <InputLabel shrink>
                    Filter Reference
                </InputLabel>
                <Input
                    fullWidth
                    id="Ref"
                    onChange={(e) => this.handleRefChange(e)}
                    value={this.state.refFilterBy}
                    onKeyDown={(e) => this.handleRefFilter(e)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Toggle password visibility"
                                onClick={(e) => this.handleRefFilter(e)}
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <br /><br />
            </Grid>
        );

        let ordersTable = (<CircularProgress color="secondary"/>);
        if(ORDERS) {
            ordersTable =
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableFirstCell}>Order Ref</TableCell>
                            <TableCell>
                                <Tooltip
                                    title="Sort by date"
                                    placement="top"
                                    enterDelay={300}
                                >
                                <TableSortLabel
                                    onClick={() => this.sortBy('Date')}
                                >
                                    Date { orderIcon }
                                </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell>Products</TableCell>
                            {
                                (userGroup==='Admin' ? (<TableCell>Client</TableCell>) : '')

                            }
                            <TableCell className={classes.tableLastCell}>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            ORDERS.map(ORDER => {
                                return (
                                    <TableRow key={ORDER.Reference}>
                                        <TableCell className={classes.tableFirstCell}>{Utils.pruneReference(ORDER.Reference)}</TableCell>
                                        <TableCell>{Utils.getNiceDate(ORDER.Date)}</TableCell>
                                        <TableCell>| {
                                            ORDER.Products.map(PRODUCT => {
                                                return PRODUCT.Title + " x " + PRODUCT.Quantity + " | ";
                                            })
                                        }</TableCell>
                                        {
                                            (userGroup==='Admin' ? (<TableCell>{ORDER.ClientName}</TableCell>) : '')

                                        }
                                        <TableCell className={classes.tableLastCell}>
                                            <div className={classes.button}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => this.viewOrder(ORDER)}
                                                >
                                                    View
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>;
        }

        return(
            <div>
                <Grid container spacing={2}>
                    {clientFilter}
                    {refFilter}
                </Grid>
                {ordersTable}
                <SideContent open={this.state.openSidebarContent} content={this.state.content} />
            </div>
        );
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(OrdersTable));