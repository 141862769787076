import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from "@material-ui/core/Paper";
import {withStyles} from "@material-ui/core/styles";

import { API } from 'aws-amplify';

import uuid from "uuid";

import Utils from '../../utils';

import ProductAttributes from "../../components/ProductAttributes";
import ProductDialog from '../../components/page/ProductDialog';
import ProductFiles from "../../components/ProductFiles";
import ProductGallery from "../../components/page/ProductGallery";
import ProductImages from "../../components/ProductImages";
import ProductOrder from "../../components/page/ProductOrder";
import ProductPricesAdmin from "./components/ProductPricesAdmin";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(12),
    },
    thumb: {
        maxWidth:250
    },
    tabs: {
        background:'#efefef'
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        height: 36,
        minHeight: 36,
        padding:"3px 0",
        alignItems: 'flex-end'
    },
    toolBar: {
        minHeight:30
    },
    panel: {
        paddingTop:10
    }
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

class ProductAdd extends Component {

    productApiPath = "/products";
    clientApiPath = "/clients";

    defaultState = () => {
        return {
            access : 'Admin',
            error : false,
            loading:false,
            body: {
                ID: uuid.v4(),
                Title:'',
                Description:' ',
                Thumb:false,
                Attributes:false,
                Gallery:false,
                Files:false,
                Client: "all",
                Cost:0,
                Selling:0,
                QuantitySteps:1,
                Prices:[]
            },
            thumbSRC : false,
            buttonText : 'Add',
            type : 'add',
            tab: 0,
            duplicateMediaWarning: false,
            clients:[],
            preview: false,
            showHiddenAttributes:true
        }
    };

    constructor(props) {
        super(props);

        this.state = this.defaultState();
    }

    componentDidMount = async() => {

        const { props } = this;

        this.setState({
            loading: true,
        });

        const clients = await API.get("PrintPortalAPI", this.clientApiPath, {
            headers: {}
        });

        this.setState({clients:clients.data});

        if(props.type==='edit') {

            const { match: { params } } = props;

            this.setState({
                buttonText : 'Update',
                type: 'edit'
            });

            try {
                const product = await API.get("PrintPortalAPI", this.productApiPath + '/' + params.ID, {
                    headers: {}
                });
                if(product.length>0) {
                    product[0].Attributes = JSON.parse(product[0].Attributes);

                    if(product[0].Thumb) {
                        const thumbSRC = await Utils.getMedia(product[0].Thumb);
                        this.setState({thumbSRC});
                    }

                    if(product[0].Gallery) {
                        product[0].Gallery.imageVals = [];
                    }

                    if(this.state.thumbSRC) {
                        if(!product[0].Gallery) {
                            product[0].Gallery = {};
                            product[0].Gallery.imageVals = [];
                            product[0].Gallery.imageKeys = [];
                        }
                        product[0].Gallery.imageKeys.push(product[0].Thumb);
                        product[0].Gallery.imageVals.push(this.state.thumbSRC);
                    }

                    if(product[0].Gallery) {
                        let gallerySrc;
                        for(let i = 0; i<product[0].Gallery.imageKeys.length; i++) {
                            gallerySrc = await Utils.getMedia(product[0].Gallery.imageKeys[i]);
                            product[0].Gallery.imageVals.push(gallerySrc);
                        }

                        product[0].Gallery.imageVals = product[0].Gallery.imageVals.filter(function(item, pos) {
                            return product[0].Gallery.imageVals.indexOf(item) === pos;
                        });
                        product[0].Gallery.imageKeys = product[0].Gallery.imageKeys.filter(function(item, pos) {
                            return product[0].Gallery.imageKeys.indexOf(item) === pos;
                        });

                    }

                    if(!product[0].Prices) {
                        product[0].Prices = [];
                    }

                    this.setState({
                        body: product[0],
                        loading:false
                    });

                } else {
                    return this.props.history.push('/products');
                }
            } catch(e) {
                console.log(e);
            }

        } else {
            this.setState(this.defaultState());
            this.setState({clients:clients.data})
        }
    };

    insertProduct = async (ev) => {
        ev.preventDefault();

        const { body } = this.state;

        if(!body.Selling) {
            body.Selling = 0;
        }

        if(!body.Cost) {
            body.Cost = 0;
        }

        this.setState({
            buttonText : <CircularProgress color="secondary" />
        });

        if(body.Attributes) {
            body.Attributes = JSON.stringify(body.Attributes);
        }

        try {
            const apiResponse = await API.put("PrintPortalAPI", this.productApiPath, {
                headers: {},
                body
            });

            if(apiResponse.formResponse.error.length>0) {
                this.setState({error:apiResponse.formResponse.error});
            } else {
                return this.props.history.push('/products');
            }
        } catch(e) {
            console.log(e);
        }

        this.setState({
            buttonText : 'Add Product'
        });

    };

    handleInputChange = async (property, e) => {
        let value;
        if(
            property==='Gallery' ||
            property==='Attributes' ||
            property==='Files' ||
            property==='Thumb' ||
            property==='Prices'
        ) {
            value = e;
        }

        else {
            value = e.target.value;
        }

        let body = {...this.state.body};
        body[property] = value;

        this.setState({body});
    };

    getError(field) {
        if(this.state.error) {
            for(let i = 0; i < this.state.error.length; i++) {
                if(field===this.state.error[i].field) {
                    return true;
                }
            }
        }
        return false;
    }

    updateTab = (event, tab) => {
        this.setState({ tab });
    };

    duplicateMediaWarningClose = () => {
        this.setState({
            duplicateMediaWarning: false
        })
    };

    openPreviewProduct = () => {
        this.setState({preview:true})
    };

    closePreviewProduct = () => {
        this.setState({
            preview: false,
            showHiddenAttributes : true
        })
    };

    toggleHiddenAttributes =(showHiddenAttributes) => {
        this.setState({showHiddenAttributes})
    };

    render() {
        const { userGroup, classes } = this.props;
        const { body, tab, thumbSRC, showHiddenAttributes } = this.state;

        if(userGroup!==this.state.access) {
            return Utils.accessDenied();
        }

        let thumb = false;
        if(this.state.thumbSRC) {
            thumb = (<img className={classes.thumb} src={this.state.thumbSRC} alt="" />)
        }

        if(this.state.loading) {
            return (<CircularProgress color="secondary" />);
        }

        let previewProduct = false;
        if(this.state.preview) {

            let gallery = body.Gallery;
            if(gallery) {
                if(thumbSRC) {
                    if(!gallery.imageVals.includes(thumbSRC)) {
                        gallery.imageVals.unshift(thumbSRC);
                    }
                }
            } else {
                if(thumbSRC) {
                    gallery = {};
                    gallery.imageVals = [];
                    gallery.imageVals.push(thumbSRC);
                }
            }

            let content =
                <div>
                    {(gallery ? (<ProductGallery gallery={gallery.imageVals} />) : '')}
                    <ProductOrder
                        orderReview={this.orderReview}
                        onChange={(e) => this.onChange(e)}
                        data={this.state.body}
                        noOrderButton={true}
                        showHiddenAttributes={showHiddenAttributes}
                        preview={true}
                        toggleHiddenAttributes={(showHiddenAttributes) => this.toggleHiddenAttributes(showHiddenAttributes)}
                    />
                </div>;
            previewProduct = <ProductDialog
                title={"Previewing - " + this.state.body.Title}
                content={content}
                open={this.state.preview}
                closeProduct={this.closePreviewProduct}
                toggleHiddenAttributes={this.toggleHiddenAttributes}
                preview={true}
            />
        }

        return(
            <div>
                <form onSubmit={this.insertProduct} noValidate autoComplete="off">
                    <Paper className={classes.root} elevation={1}>

                        <Dialog
                            open={this.state.duplicateMediaWarning}
                            onClose={this.closeDeleteDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">The media filename you have selected already exists.</DialogTitle>
                            <DialogActions>
                                <Button onClick={this.duplicateMediaWarningClose} color="primary">
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Grid container spacing={2}>
                            <Grid item md={8} sm={12}>
                                <Typography variant="h4" gutterBottom>
                                    Details
                                </Typography>

                                <TextField
                                    error={this.getError('Title')}
                                    fullWidth
                                    required
                                    id="Title"
                                    label="Title"
                                    margin="normal"
                                    value={body.Title}
                                    onChange={(e) => this.handleInputChange('Title', e)}
                                />

                                <TextField
                                    fullWidth
                                    multiline={true}
                                    rows={4}
                                    rowsMax={8}
                                    id="Description"
                                    label="Description"
                                    margin="normal"
                                    value={(body.Description===' ' ? '' : body.Description)}
                                    onChange={(e) => this.handleInputChange('Description', e)}
                                />

                                {/* <div>
                                    <br /><br />
                                    <InputLabel shrink>
                                        Add Thumbnail
                                    </InputLabel>
                                    <br />
                                </div>
                                <input
                                    accept="image/*"
                                    id="Thumb"
                                    type="file"
                                    onChange={(e) => this.handleInputChange('Thumb', e)}
                                /><br /><br />
                                <Card>
                                    <CardContent style={{ textAlign: 'center' }}>
                                        {thumb}
                                    </CardContent>
                                </Card>
                                <br /><br /> */}

                                <div>
                                    <br /><br />
                                    <InputLabel shrink>
                                        Quantity Steps
                                    </InputLabel>
                                    <TextField
                                        onChange={(e) => this.handleInputChange('QuantitySteps', e)}
                                        fullWidth
                                        type="number"
                                        value={body.QuantitySteps}
                                        InputProps={{ inputProps: { min: 1 } }}
                                    />
                                    <br /><br />
                                </div>

                                <InputLabel shrink>
                                    Client
                                </InputLabel>
                                <Select
                                    error={this.getError('Client')}
                                    fullWidth
                                    required
                                    id="Client"
                                    label="Client"
                                    value={body.Client}
                                    onChange={(e) => this.handleInputChange('Client', e)}
                                >
                                    <MenuItem value="all">
                                        All
                                    </MenuItem>
                                    {
                                        this.state.clients.map(CLIENT => {
                                            return(
                                                <MenuItem key={CLIENT.ID} value={CLIENT.ID}>
                                                    {CLIENT.Name}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>

                                { /* <TextField
                                    fullWidth
                                    required
                                    id="Cost"
                                    label="Cost"
                                    margin="normal"
                                    value={body.Cost}
                                    onChange={(e) => this.handleInputChange('Cost', e)}
                                />

                                <TextField
                                    fullWidth
                                    required
                                    id="Selling"
                                    label="Selling"
                                    margin="normal"
                                    value={body.Selling}
                                    onChange={(e) => this.handleInputChange('Selling', e)}
                                /> */ }

                            </Grid>

                            <Grid item md={8} sm={12}>
                                <Tabs value={tab} onChange={this.updateTab} className={classes.tabs}>
                                    <Tab label="Gallery" />
                                    <Tab label="Attributes" />
                                    <Tab label="Artwork" />
                                    <Tab label="Pricing" />
                                    <Tab label="Notes" />
                                </Tabs>
                                {tab === 0 && <TabContainer>
                                    <Typography variant="h4" gutterBottom>
                                        Gallery
                                    </Typography>
                                    <ProductImages
                                        value={body.Gallery}
                                        theThumb={body.Thumb}
                                        setThumb={(property, e) => this.handleInputChange(property, e)}
                                        onChange={(e) => this.handleInputChange('Gallery', e)}
                                    />
                                </TabContainer>}
                                {tab === 1 && <TabContainer>
                                    <Typography variant="h4" gutterBottom>
                                        Attributes
                                    </Typography>
                                    <ProductAttributes
                                        value={body.Attributes}
                                        onChange={(e) => this.handleInputChange('Attributes', e)}
                                    />
                                </TabContainer>}
                                {tab === 2 && <TabContainer>
                                    <Typography variant="h4" gutterBottom>
                                        Files
                                    </Typography>
                                    <ProductFiles
                                        value={body.Files}
                                        onChange={(e) => this.handleInputChange('Files', e)}
                                    />
                                </TabContainer>}
                                {tab === 3 && <TabContainer>
                                    <Typography variant="h4" gutterBottom>
                                        Pricing
                                    </Typography>
                                    <ProductPricesAdmin
                                        prices={body.Prices}
                                        onChange={(e) => this.handleInputChange('Prices', e)}
                                    />
                                </TabContainer>}
                                {tab === 4 && <TabContainer>
                                    <Typography variant="h4" gutterBottom>
                                        Notes
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        multiline={true}
                                        rows={4}
                                        rowsMax={8}
                                        id="Notes"
                                        label="Notes"
                                        margin="normal"
                                        value={body.Notes}
                                        onChange={(e) => this.handleInputChange('Notes', e)}
                                    />
                                </TabContainer>}

                            </Grid>
                        </Grid>

                        <Grid container spacing={24}>
                            <Grid item md={6} sm={12}>
                                <br /><br />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    {this.state.buttonText}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    <AppBar position="fixed" color="primary" className={classes.appBar}>
                        <Toolbar className={classes.toolBar}>
                            <Button type="submit" size="small" variant="contained" color="secondary">{this.state.buttonText}</Button>&nbsp;&nbsp;
                            {<Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={this.openPreviewProduct}
                            >Preview
                            </Button>}
                        </Toolbar>
                    </AppBar>
                </form>
                {previewProduct}
            </div>
        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(ProductAdd));