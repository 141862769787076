import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Hidden from '@material-ui/core/Hidden';
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import {withStyles} from "@material-ui/core/styles";

import { API } from 'aws-amplify';

import uuid from "uuid";

import Utils from "../../utils";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    logoImg: {
        width: "100%"
    }
});

class ClientAdd extends Component {

    apiPath = "/clients";
    defaultState = () => {
        return {
            access: 'Admin',
            error: false,
            body: {
                ID: uuid.v4(),
                Name: '',
                Street: '',
                Town: '',
                PostalCode: '',
                Country: '',
                VATNumber: '',
                AdminEmail: '',
                BrandingColourPrimary: '#009fff',
                BrandingColourSecondary: '#535353',
                LogoDefault: false,
                LogoInverted: false,
                RecipientEmails: '',
                AdminEmails: '',
                Notes:''
            },
            loading:false,
            LogoDefaultSrc: false,
            LogoInvertedSrc: false,
            buttonText: 'Add Client',
            type: 'add'
        }
    };

    constructor(props) {
        super(props);

        this.state = this.defaultState();
    }

    componentDidMount = async () => {

        const { props } = this;

        this.setState({
            loading: true,
        });

        if(props.type==='edit') {
            const { match: { params } } = props;

            this.setState({
                buttonText : 'Update client',
                type: 'edit'
            });

            // If editing get the item
            try {
                const client = await API.get("PrintPortalAPI", this.apiPath + '/' + params.ID, {
                    headers: {}
                });
                if(client.length>0) {
                    this.setState({
                        body: client[0]
                    });

                    if(client[0].LogoDefault) {
                        const LogoDefaultSrc = await Utils.getMedia(client[0].LogoDefault);
                        this.setState({LogoDefaultSrc});
                    }
                    if(client[0].LogoInverted) {
                        const LogoInvertedSrc = await Utils.getMedia(client[0].LogoInverted);
                        this.setState({LogoInvertedSrc});
                    }

                    this.setState({loading:false})
                } else {
                    return props.history.push('/products');
                }
            } catch(e) {
                console.log(e);
            }

        } else {
            this.setState(this.defaultState());
        }
    };

    insertClient = async (ev) => {
        ev.preventDefault();

        let { body } = this.state;
        body = Utils.filterObjectForDB(body);

        this.setState({
            buttonText : <CircularProgress color="secondary" />
        });

        try {
            const apiResponse = await API.put("PrintPortalAPI", this.apiPath, {
                headers: {},
                body
            });

            if (apiResponse.formResponse.error.length > 0) {
                this.setState({error: apiResponse.formResponse.error});
            } else {
                return this.props.history.push('/clients');
            }
        } catch(e) {
            console.log(e);
        }

        this.setState({
            buttonText : 'Add Client'
        });

    };

    handleInputChange = async (property, e) => {
        let value;
        if(property==='LogoDefault' || property==='LogoInverted') {
            const mediaObject = await Utils.insertMedia(e);
            value = mediaObject.key;
            const mediaSRC = await Utils.getMedia(value);
            this.setState({[property+"Src"] : mediaSRC});
        } else {
            value = e.target.value;
        }

        let body = {...this.state.body};
        if(!value) {
           delete body[property];
        } else {
            body[property] = value;
        }

        this.setState({body});
    };

    getError(field) {
        if(this.state.error) {
            for(let i = 0; i < this.state.error.length; i++) {
                if(field===this.state.error[i].field) {
                    return true;
                }
            }
        }
        return false;
    }

    render() {
        const { userGroup, classes } = this.props;
        const { body, LogoDefaultSrc, LogoInvertedSrc  } = this.state;

        if(userGroup!==this.state.access) {
            return Utils.accessDenied();
        }

        let logoDefault = false,
            logoInverted = false;

        if(LogoDefaultSrc) {
            logoDefault = (<img className={classes.logoImg} src={LogoDefaultSrc} alt="" />)
        }
        if(LogoInvertedSrc) {
            logoInverted = (<img className={classes.logoImg} src={LogoInvertedSrc} alt="" />)
        }

        if(this.state.loading) {
            return (<CircularProgress color="secondary" />);
        }

        return(
            <Paper className={classes.root} elevation={1}>
                <form onSubmit={this.insertClient} noValidate autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item md={6} sm={12}>
                            <Typography variant="h4" gutterBottom>
                                Details
                            </Typography>

                            <TextField
                                error={this.getError('Name')}
                                fullWidth
                                required
                                id="Name"
                                label="Name"
                                margin="normal"
                                value={body.Name}
                                onChange={(e) => this.handleInputChange('Name', e)}
                            />

                            <TextField
                                fullWidth
                                id="Street"
                                label="Street"
                                margin="normal"
                                value={body.Street}
                                onChange={(e) => this.handleInputChange('Street', e)}
                            />

                            <TextField
                                fullWidth
                                id="Town"
                                label="Town"
                                margin="normal"
                                value={body.Town}
                                onChange={(e) => this.handleInputChange('Town', e)}
                            />

                            <TextField
                                fullWidth
                                id="PostalCode"
                                label="PostalCode"
                                margin="normal"
                                value={body.PostalCode}
                                onChange={(e) => this.handleInputChange('PostalCode', e)}
                            />

                            <TextField
                                fullWidth
                                id="Country"
                                label="Country"
                                margin="normal"
                                value={body.Country}
                                onChange={(e) => this.handleInputChange('Country', e)}
                            />

                            <TextField
                                fullWidth
                                id="VATNumber"
                                label="VAT Number / Company Registration Number"
                                margin="normal"
                                value={body.VATNumber}
                                onChange={(e) => this.handleInputChange('VATNumber', e)}
                            />

                            <Hidden xsUp>
                            <TextField
                                error={this.getError('AdminEmail')}
                                fullWidth
                                id="AdminEmail"
                                label="Admin Email"
                                margin="normal"
                                value={body.AdminEmail}
                                onChange={(e) => this.handleInputChange('AdminEmail', e)}
                            />
                            </Hidden>

                            <br /><br />
                            <Typography variant="h4" gutterBottom>
                                Recipient Order Emails
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Provide a list of comma separated recipients to be copied into the Order Email
                            </Typography>

                            <Typography variant="body1" gutterBottom>
                                The following e-mail address will receive the order <b>WITHOUT</b> the XML file
                            </Typography>
                            <TextField
                                fullWidth
                                required
                                multiline
                                rowsMax="4"
                                id="RecipientEmails"
                                label="Client Emails"
                                margin="normal"
                                value={body.RecipientEmails}
                                onChange={(e) => this.handleInputChange('RecipientEmails', e)}
                            />

                            <br /><br />
                            <Typography variant="body1" gutterBottom>
                                The following e-mail address will receive the order <b>WITH</b> the XML file
                            </Typography>
                            <TextField
                                fullWidth
                                required
                                multiline
                                rowsMax="4"
                                id="AdminEmails"
                                label="Admin Emails"
                                margin="normal"
                                value={body.AdminEmails}
                                onChange={(e) => this.handleInputChange('AdminEmails', e)}
                            />

                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Typography variant="h4" gutterBottom>
                                Branding
                            </Typography>

                            <TextField
                                error={this.getError('BrandingColourPrimary')}
                                fullWidth
                                required
                                id="BrandingColourPrimary"
                                label="Primary Colour"
                                margin="normal"
                                value={body.BrandingColourPrimary}
                                onChange={(e) => this.handleInputChange('BrandingColourPrimary', e)}
                            />
                            <TextField
                                error={this.getError('BrandingColourSecondary')}
                                fullWidth
                                required
                                id="BrandingColourSecondary"
                                label="Secondary Colour"
                                margin="normal"
                                value={body.BrandingColourSecondary}
                                onChange={(e) => this.handleInputChange('BrandingColourSecondary', e)}
                            />

                            <Grid container spacing={2}>
                                <Grid item sm={6}>
                                    <div>
                                        <br /><br />
                                        <InputLabel shrink>
                                            Add Default Logo
                                        </InputLabel>
                                        <br />
                                    </div>
                                    <input
                                        accept="image/*"
                                        id="LogoDefault"
                                        type="file"
                                        onChange={(e) => this.handleInputChange('LogoDefault', e)}
                                    /><br /><br />
                                    <Card>
                                        <CardContent style={{ textAlign: 'center' }}>
                                            {logoDefault}
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item sm={6}>
                                    <div>
                                        <br /><br />
                                        <InputLabel shrink>
                                            Add Inverted Logo
                                        </InputLabel>
                                        <br />
                                    </div>
                                    <input
                                        accept="image/*"
                                        id="LogoInverted"
                                        type="file"
                                        onChange={(e) => this.handleInputChange('LogoInverted', e)}
                                    /><br /><br />
                                    <Card>
                                        <CardContent style={{ textAlign: 'center' }}>
                                            {logoInverted}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>

                            <br /><br />
                            <Typography variant="h4" gutterBottom>
                                Notes
                            </Typography>

                            <TextField
                                fullWidth
                                required
                                multiline
                                rowsMax="10"
                                id="Notes"
                                label="Notes"
                                margin="normal"
                                value={body.Notes}
                                onChange={(e) => this.handleInputChange('Notes', e)}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={24}>
                        <Grid item md={6} sm={12}>
                            <br /><br />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {this.state.buttonText}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        );
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(ClientAdd));