import React, { Component } from "react";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket"
import {withStyles} from "@material-ui/core";
import { withRouter } from 'react-router-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Utils from "../../utils";
import Button from "@material-ui/core/Button";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import uuid from "uuid";
import CircularProgress from '@material-ui/core/CircularProgress';
import { API } from 'aws-amplify';

const styles = theme => ({
    box: {
        padding:15
    },
    embolden: {
        fontWeight:'bold'
    },
    title: {
        marginTop:10,
        marginBottom:10,
        paddingBottom:10
    },
    spacing: {
        marginTop:10,
        marginBottom:10
    },
    productList: {
        marginTop:10,
        marginBottom:10
    },
    productItem: {
        fontStyle: 'italic'
    },
    addToBasket: {
        marginTop:25
    },
    moveUp : {
        position:"relative",
        top:"-5px"
    },
    caption: {
        marginTop:5
    }
});

class OrderDetails extends Component {

    basketApiPath = '/basket';
    productApiPath = '/products';

    state = {
        reOrder:false,
        loading:false,
        notAddedToBasket:false
    };

    /*reOrder = () => {
        this.setState({
            reOrder:true
        })
    };*/

    addToBasket = async (products) => {

        const { user } = this.props;

        this.setState({
            loading:true
        });

        let notAddedToBasket = [];
        for(let i=0; i<products.length; i++) {
            let product = await API.get("PrintPortalAPI", this.productApiPath + '/object/' + products[i].ProductID);
            if(Object.keys(product).length === 0 && product.constructor === Object) {
                notAddedToBasket.push(products[i].Title);
            } else {
                let body = {};
                body.Attributes = [];
                body.ID = uuid.v4();
                body.Client = user.Client;
                body.Username = user.Username;
                body.userId = user.ID;

                for (var property in products[i]) {
                    if (products[i].hasOwnProperty(property)) {
                        if(
                            property!=='Title' &&
                            property!=='Quantity' &&
                            property!=='ProductID' &&
                            property!=='Selling' &&
                            property!=='Cost'
                        ) {
                            body.Attributes.push({
                                label:property,
                                value: products[i][property]
                            })
                        } else {
                            body[property] = products[i][property];
                        }
                    }
                }

                try {
                    await API.put("PrintPortalAPI", this.basketApiPath, {
                        headers: {},
                        body
                    });
                } catch(e) {
                    console.log(e);
                }
            }
        }

        if(notAddedToBasket.length===0) {
            notAddedToBasket = false;
            this.props.closeOrderDetails();
        }

        this.props.basketUpdate();
        this.setState({
            loading:false,
            notAddedToBasket
        });
    };

    closeNotificationDialog = () => {
        this.setState({
            notAddedToBasket:false
        });
        this.props.closeOrderDetails();
    };


    /* handleCloseReorder = () => {
        this.setState({
            reOrder:false
        })
    }; */

    /* placeOrder = async (order) => {
        const { user } = this.props;

        this.setState({
            loading:true
        });

        order.ClientEmail = user.Email;
        order.ClientTheme = user.ClientTheme;
        order.Reference = uuid.v4();

        try {
            await API.put("PrintPortalAPI", this.apiPath, {
                headers: {},
                body:order
            });

            return this.props.history.push('/order-placed');

        } catch(e) {
            console.log(e);
        }

        this.setState({
            loading:false
        });
    }; */

    render() {
        const { classes, order } = this.props;
        const { loading, notAddedToBasket } = this.state;
        const PRODUCTS = order.Products;

        let buttonCap = "Add products to Basket";
        if(PRODUCTS.length===1) {
            buttonCap = "Add product to Basket";
        }

        let reOrderButton = false;
        if(this.props.userGroup==='Client') {
            let buttonText = <div><ShoppingBasketIcon/><span className={classes.moveUp}>&nbsp;&nbsp;{buttonCap}</span></div>
            if(loading) {
                buttonText = <CircularProgress />
            }
            reOrderButton = (
                <div>
                    <Button
                        className={classes.addToBasket}
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => this.addToBasket(PRODUCTS)}
                    >
                        {buttonText}
                    </Button>
                    <br />
                    <Typography className={classes.caption} variant="caption">
                        You can edit the quantity within your basket.
                    </Typography>
                </div>
            );

            /* if(this.state.reOrder) {
                order.Date = new Date();
                //order.Reference = uuid.v4();
                let displayOrder = Object.assign({}, order.Products[0]);
                const { Title, Quantity } = order.Products[0];
                delete displayOrder.Title;
                delete displayOrder.ProductID;
                delete displayOrder.Cost;
                delete displayOrder.Selling;
                delete displayOrder.Quantity;

                let content = (
                    <DialogContentText>
                        <Typography variant="body1">
                            <b>{Title} x {Quantity}</b><br />
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            {
                                Object.keys(displayOrder).map((k) => {
                                    if(!Utils.checkIfAttHidden(displayOrder[k])) {
                                        return (<div><b>{k}</b>: {displayOrder[k]}</div>);
                                    }
                                    return false;
                                })
                            }
                        </Typography>
                    </DialogContentText>
                );

                let title = 'Order the following?';

                if(this.state.loading) {
                    title = 'Order being processed.';
                    content = (<CircularProgress />)
                }

                return (
                    <Dialog
                        open={this.state.reOrder}
                        onClose={this.handleCloseReorder}
                    >
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>
                            {content}
                        </DialogContent>
                        <DialogActions>
                            {
                                (this.state.loading ? '' : <div>
                                        <Button onClick={this.handleCloseReorder} color="primary">
                                            Cancel
                                        </Button>
                                        <Button onClick={() => this.placeOrder(order)} color="primary">
                                            Order
                                        </Button></div>
                                )
                            }

                        </DialogActions>
                </Dialog>)
            } */
        }

        let NotAddedNotification = false;
        if(notAddedToBasket) {
            NotAddedNotification =
                <Dialog
                    open={this.state.notAddedToBasket}
                >
                    <DialogTitle>Notification</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography variant="body1">
                                The following products could not be added to your basket because they are no longer available.
                            </Typography>
                            <ul>
                            {notAddedToBasket.map(k => {
                                return <li>{k}</li>
                            })}
                            </ul>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeNotificationDialog}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
        }

        const ProductList =
            <div className={classes.productList}>
                {
                    PRODUCTS.map(PRODUCT => {

                        const { Title, Quantity, ProductID } = PRODUCT;
                        let ProductMeta = PRODUCT;

                        if(ProductMeta) {
                            ProductMeta =
                                <Typography className={classes.spacing}>
                                    {
                                        Object.keys(ProductMeta).map(key => {
                                        if(key!=='Quantity' && key!=='ProductID' && key!=='Title' && key!=='Selling' && key!=='Cost') {
                                                let prefix = key;
                                                let value = ProductMeta[key];

                                                if(value && value!==' ') {
                                                    if (!Utils.checkIfAttHidden(value)) {

                                                        if (prefix) {
                                                            prefix =
                                                                <span className={classes.embolden}>
                                                        {prefix}&nbsp;:&nbsp;
                                                    </span>
                                                        }

                                                        return (
                                                            <span key={uuid.v4()} className={classes.productItem}>
                                                    {prefix}
                                                                {value}
                                                                <br/>
                                                </span>
                                                        )
                                                    }
                                                }
                                        }

                                        return false;
                                        })
                                    }
                                </Typography>
                        }

                        return(
                            <div key={ProductID}>
                                <Typography className={classes.embolden} color="primary" variant="h6">
                                    {Title}
                                </Typography>
                                {ProductMeta}
                                <Typography className={classes.spacing}>
                                    <span className={classes.embolden}>Quantity: </span> {Quantity}
                                </Typography>
                            </div>
                        );
                    })
                }
                {reOrderButton}
            </div>;

        return(
            <div className={classes.box}>
                <Typography className={classes.title} color="primary" variant="h5">
                    Order Details <br />REF: {Utils.pruneReference(order.Reference)}
                </Typography>
                <Divider />
                {ProductList}
                {NotAddedNotification}
            </div>
        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(OrderDetails));