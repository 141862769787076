import React, { Component } from "react"
import { withStyles } from '@material-ui/core/styles/index';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import uuid from "uuid";
import Utils from '../../utils';


const styles = theme => ({
    paper : {
        minWidth:400,
        maxWidth:650,
        width:'100%'
    },
    orderActions : {
        textAlign:"center",
        marginTop:25
    }
});

class OrderReview extends Component {

    apiPath = "/orders";

    state = {
        title: this.props.title,
        attributes: this.props.order,
        loading:true
    };

    componentDidMount = async () => {
        const { order, user } = this.props;

        let newOrder = {
            Reference: uuid.v4(),
            Date: new Date(),
            Client: user.Client,
            ClientName: user.ClientName,
            ClientTheme: user.ClientTheme,
            Products: []
        },
            product = {};


        for (var property in order) {
            if (order.hasOwnProperty(property)) {
                if(
                    property!=='Quantity' && property!=='ProductID' && property!=='Cost' && property!=='Selling'
                ) {
                    if(order[property]['label']!==undefined) {
                        if (typeof(order[property]['value']) === 'undefined') {
                            product[order[property]['label']] = ' ';
                        } else {
                            product[order[property]['label']] = order[property]['value'];
                        }
                    }
                } else {
                    product[property] = order[property]
                }
            }
        }

        newOrder.Products[0] = product;
        newOrder.Products[0]['Title'] = this.state.title;

        this.setState({
            order: newOrder,
            loading:false
        });


    };

    getAttsHtml = () => {
        const { attributes } = this.state;
        return Object.keys(attributes).map(name => {

                if(!Utils.checkIfAttHidden(attributes[name].value)) {
                    return (
                        <Grid key={name} container spacing={24}>
                            <Grid item md={6} sm={6} xs={6}>
                                <Typography variant="body1">
                                    <b>{attributes[name].label}</b>
                                </Typography>
                            </Grid>
                            <Grid item md={6} sm={6} xs={6}>
                                <Typography variant="body1">
                                    {attributes[name].value}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                }
            })
    };

    render() {
        const { title, attributes } = this.state;
        const { classes } = this.props;
        const attsHtml = this.getAttsHtml();

        return(
            <div>
                <Grid className={classes.paper} container spacing={24}>
                    <Grid item xs={12}>
                        <Typography variant="body1"><b>{title}</b></Typography>
                        <Divider />
                    </Grid>
                </Grid>
                {attsHtml}
                <Grid  container spacing={24}>
                    <Grid item md={6} sm={6} xs={6}>
                        <Typography variant="body1">
                            <b>Quantity</b>
                        </Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={6}>
                        <Typography variant="body1">
                            {attributes.Quantity}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.orderActions}>
                    {
                        (this.state.loading ? <CircularProgress /> : <div>
                            <Button onClick={() => this.props.backToProduct(this.props.order)} color="primary">
                            Back
                            </Button>&nbsp;
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.placeOrder}
                            >
                                <ShoppingCartIcon />&nbsp;&nbsp;Add to Basket
                            </Button></div>
                        )
                    }


                </div>
            </div>
        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(OrderReview));