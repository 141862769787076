import React, { Component } from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    image: {
        maxWidth:'100%',
        maxHeight: '100%'
    },
    container: {
       // height: 350
        textAlign:'center'
    },
    caption: {
        textAlign:'center'
    }
});

class ProductGallery extends Component {
    state = {
        activeStep : 0
    };

    handleNext = () => {
        this.setState(prevState => ({
            activeStep: prevState.activeStep + 1,
        }));
    };

    handleBack = () => {
        this.setState(prevState => ({
            activeStep: prevState.activeStep - 1,
        }));
    };

    render() {
        const { gallery, classes } = this.props;

        const { activeStep } = this.state;
        const maxSteps = gallery.length;
        let output = (<div />);
        if(gallery) {
            output = (
                (
                    <div>
                        <div className={classes.container}>
                            <img
                                className={classes.image}
                                src={gallery[activeStep]}
                                alt={gallery[activeStep]}
                            />
                        </div>
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            className={classes.mobileStepper}
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={this.handleNext}
                                    disabled={activeStep === maxSteps - 1}
                                >
                                    Next
                                    <KeyboardArrowRight />
                                </Button>
                            }
                            backButton={
                                <Button
                                    size="small"
                                    onClick={this.handleBack}
                                    disabled={activeStep === 0}
                                >
                                    <KeyboardArrowLeft />
                                    Back
                                </Button>
                            }
                        />
                    </div>
                )
            );
        }
        return output;
    }
}

export default withStyles(styles)(ProductGallery);