const MAINLINKS = [
    {
        id:1,
        label: 'Dashboard',
        title: 'Dashboard',
        component: 'Dashboard',
        route: '/',
        access: ['Client', 'Admin']
    },
    {
        id:2,
        label: 'Products',
        title: 'Products',
        component: 'Products',
        route: '/products',
        access: ['Admin'],
        subs : [
            {
                id:21,
                label: 'Add Product',
                title: 'Add Product',
                route: '/product/add',
                access: ['Admin']
            },
            {
                id:22,
                label: 'Edit Product',
                title: 'Edit Product',
                route: '/product/edit/*',
                display: 'hidden',
                highlight: '/products',
                access: ['Admin']
            }
        ]
    },
    {
        id:3,
        label: 'Clients',
        title: 'Clients',
        component: 'Clients',
        route: '/clients',
        access: ['Admin'],
        subs : [
            {
                id:31,
                label: 'Add Client',
                title: 'Add Client',
                route: '/client/add',
                access: ['Admin']
            },
            {
                id:32,
                label: 'Edit Client',
                title: 'Edit Client',
                route: '/client/edit/*',
                display: 'hidden',
                highlight: '/clients',
                access: ['Admin']
            }
        ]
    },
    {
        id:4,
        label: 'Users',
        title: 'Users',
        component: 'Users',
        route: '/users',
        access: ['Admin'],
        subs : [
            {
                id:41,
                label: 'Add User',
                title: 'Add User',
                route: '/user/add',
                access: ['Admin']
            },

            {
                id:42,
                label: 'Edit User',
                title: 'Edit User',
                route: '/user/edit/*',
                display: 'hidden',
                highlight: '/users',
                access: ['Admin']
            }
        ]
    },
    {
        id:5,
        label: 'Catalogue',
        title: 'Catalogue',
        component: 'Catalogue',
        route: '/catalogue',
        access: ['Client', 'Admin'],
    },
    {
        id:6,
        label: 'Orders',
        title: 'Orders',
        component: 'Orders',
        route: '/orders',
        access: ['Client', 'Admin'],
    },
    {
        id:7,
        label: 'Request Bespoke Product',
        title: 'Request Bespoke Product',
        component: 'RequestBespokeProduct',
        route: '/product/bespoke',
        access: ['Client']
    },
    /*{
        id:8,
        label: 'Activate Bespoke Portal',
        title: 'Activate Bespoke Portal',
        component: 'ActivatePortal',
        route: '/activate',
        access: ['Client']
    }*/
];

export default MAINLINKS;