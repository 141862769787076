import React, { Component } from "react"
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";

const styles = theme => ({
    link:{
        backgroundColor: theme.palette.secondary.main,
        height:'100%',
        padding:10,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign:'center'
    },
    text: {
        color:theme.palette.secondary.contrastText
    },
    button: {
        textAlign:'center',
        margin: theme.spacing(1),
    }
});

class CreateBespokeProductBox extends Component {
    gotoBespokeProduct = () => {
        this.props.history.push("/product/bespoke");
    };

    render() {
        const { classes } = this.props;

        return(
            <div className={classes.link}>
                <Typography className={classes.text} variant="h5" gutterBottom>
                    Need to request a new bespoke product?
                </Typography>
                <Typography className={classes.text} variant="body1" gutterBottom>
                    Request using your simple form
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.gotoBespokeProduct}
                >
                    REQUEST BESPOKE PRODUCT
                </Button>
            </div>
        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(CreateBespokeProductBox));