import React, { Component } from 'react';
import {withRouter} from "react-router-dom"
import {withStyles} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    navBox: {
        marginBottom: theme.spacing(1),
        flexDirection: 'column',
        height:250
    },
    text: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        height:"100%"
    },
    link: {
        height:"100%"
    },
    paper: {
        height:"100%"
    }
});

class AdminDashboard extends Component {

    navigate(where) {
        return this.props.history.push('/'+where);
    }

    render() {
        const { classes } = this.props;
        /* <Grid  item xs={12} sm={6} md={3} className={classes.navBox}> */
        return(
            <Grid container spacing={2}>
                <Grid item xs={3} sm={3} md={3} className={classes.navBox}>
                    <Link className={classes.link} onClick={() => this.navigate('products')}>
                        <Paper className={classes.paper} >
                            <Typography variant="h5" className={classes.text}>
                                Products
                            </Typography>
                        </Paper>
                    </Link>
                </Grid>
                <Grid  item xs={3} sm={3} md={3} className={classes.navBox}>
                    <Link className={classes.link}  onClick={() => this.navigate('clients')}>
                        <Paper className={classes.paper}>
                            <Typography  variant="h5" className={classes.text}>
                                Clients
                            </Typography>
                        </Paper>
                    </Link>
                </Grid>
                <Grid  item xs={3} sm={3} md={3} className={classes.navBox}>
                    <Link className={classes.link}  onClick={() => this.navigate('users')}>
                        <Paper className={classes.paper}>
                            <Typography  variant="h5" className={classes.text}>
                                Users
                            </Typography>
                        </Paper>
                    </Link>
                </Grid>
                <Grid  item xs={3} sm={3} md={3} className={classes.navBox}>
                    <Link className={classes.link}  onClick={() => this.navigate('orders')}>
                        <Paper className={classes.paper}>
                            <Typography  variant="h5" className={classes.text}>
                                Orders
                            </Typography>
                        </Paper>
                    </Link>
                </Grid>
            </Grid>
        );
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(AdminDashboard));