import React, { Component } from 'react';
import {withStyles} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import DeleteIcon from '@material-ui/icons/Delete';
import Utils from "../utils";

const styles = theme => ({
    fileList: {
        backgroundColor: theme.palette.background.paper,
    }
});

class ProductFiles extends Component {
    state = {
        fileKeys : [],
        fileVals : [],
        confirmRemoveFile: false,
        removeFileKey: false,
        loading: false
    };

    componentWillReceiveProps = async () => {
        this.setState({loading:true});

        if(this.props.value) {
            const { fileKeys } = this.props.value;

            let fileVals = [];
            await Promise.all(fileKeys.map(async (fileKey) => {
                let val = await Utils.getMedia(fileKey);
                fileVals.push(val);
            }));

            this.setState({
                fileKeys,
                fileVals,
                loading:false
            });
        }
    };

    componentDidMount = async () => {

        if(this.props.value) {
            const { fileKeys } = this.props.value;

            let fileVals = [];
            await Promise.all(fileKeys.map(async (fileKey) => {
                let val = await Utils.getMedia(fileKey);
                fileVals.push(val);
            }));

            this.setState({
                fileKeys,
                fileVals,
                loading:false
            });

        }
    };

    handleFileAdd = async (e) => {
        this.setState({loading:true});
        const { fileKeys, fileVals } = this.state;
        const mediaObject = await Utils.insertMedia(e);
        let key = mediaObject.key;
        fileKeys.push(key);
        let val = await Utils.getMedia(key);
        fileVals.push(val);
        this.props.onChange({
            fileKeys
        });

        this.setState({
            fileKeys,
            fileVals,
            loading: false
        });

    };

    handleFileRemove = () => {
        const { fileKeys, fileVals, removeFileKey } = this.state;

        for(let i = 0; i<fileKeys.length; i++) {
            if(removeFileKey===fileKeys[i]) {
                fileKeys.splice(i,1);
                fileVals.splice(i,1);
            }
        }

        this.setState({
            fileKeys,
            fileVals,
            removeFileKey : false,
            confirmRemoveFile: false
        })
    }

    openDialogBox = (key) => {
        this.setState({
            confirmRemoveFile: true,
            removeFileKey : key
        });
    };

    closeDialogBox = () => {
        this.setState({
            confirmRemoveFile: false,
            removeFileKey : false
        });
    };

    render() {
        const { fileVals, fileKeys, loading } = this.state;
        const { classes } = this.props;

        let files = false;
        if(fileVals.length>0) {
            let i=0;
            files = <List className={classes.fileList} component="nav"> {
                fileVals.map((src) => {
                    let key = fileKeys[i];
                    i++;
                    return (
                        <ListItem button key={i} onClick={() => {window.open(src)}}>
                            <ListItemIcon>
                                <OpenInBrowserIcon />
                            </ListItemIcon>
                            <ListItemText>{key}</ListItemText>
                            <ListItemSecondaryAction>
                                <IconButton
                                    aria-label="Delete"
                                    onClick={(e) => this.openDialogBox(key)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })}
            </List>;

        }

        let wait = false;
        if(loading) {
            wait = <CircularProgress color="secondary" />
        }

        return(
            <div>
                {wait}
                {files}
                <br /><br />
                <div>
                    <InputLabel shrink>
                        Add File (PDF Only)
                    </InputLabel>
                    <br />
                </div>
                <input
                    accept="pdf/*"
                    id="File"
                    type="file"
                    onChange={(e) => this.handleFileAdd(e)}
                />

                <Dialog
                    open={this.state.confirmRemoveFile}
                    onClose={this.closeDialogBox}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Remove file?</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.closeDialogBox} color="primary">
                            No
                        </Button>
                        <Button onClick={this.handleFileRemove} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ProductFiles);