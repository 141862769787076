import React, { Component } from "react"
import { withRouter } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core/styles/index';

import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

import EditIcon from "@material-ui/icons/Edit";
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';

import { API } from 'aws-amplify';

import Utils from "../../utils";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    loader: {
        margin: theme.spacing(2),
    }
});

class UserList extends Component {

    userApiPath = "/users";
    clientsApiPath = "/clients";

    state = {
        access: 'Admin',
        products: false,
        clients: false,
        clientFilterBy: false,
        disableUserID: false,
        disableUserTitle: false,
        confirmDisableUser : false
    };

    gotoUser(e, id) {
        e.preventDefault();
        return this.props.history.push('/user/edit/' + id);
    }

    getData = async (client) => {

        let users;

        if(client) {
            try {
                users = await API.post("PrintPortalAPI", this.userApiPath + '/client', {
                    headers: {},
                    body: {
                        client
                    }
                });

            } catch(e) {
                console.log(e);
            }
        } else {
            try {
                users = await API.get("PrintPortalAPI", this.userApiPath, {
                    headers: {}
                });

            } catch (e) {
                console.log(e);
            }
        }

        if (users.data) {
            for (let i = 0; i < users.data.length; i++) {
                if (users.data[i].Client !== 'all') {
                    users.data[i].Client = await Utils.getClientNameById(users.data[i].Client);
                }
            }
            this.setState({users: users.data})
        } else {
            this.setState({users: 'none'})
        }
    };

    handleClientFilter = async (option) => {
        this.setState({
            users:false,
        }, async () => {
            if(option.value==='all') {
                await this.getData(false);
                this.setState({
                    clientFilterBy:false
                })
            } else {
                await this.getData(option.value);
                this.setState({
                    clientFilterBy:option.value
                })
            }
        });

    };

    componentDidMount = async () => {
        const { user } = this.props;
        const { clientFilterBy } = this.state;

        if(user.ClientName==='Admin' && !clientFilterBy) {
            try {
                const clients = await API.get("PrintPortalAPI", this.clientsApiPath, {
                    headers: {}
                });

                this.setState({clients:clients.data});

            } catch(e) {
                console.log(e);
            }
        }

        await this.getData(false);
    };

    openUpdateDialog = (ID, Username, state) => {
        this.setState({
            confirmUpdateUser: true,
            updateUserID : ID,
            updateUserUsername: Username,
            dialogAction:state
        });
    };

    closeDialog = () => {
        this.setState({
            confirmUpdateUser: false,
            updateUserID : false,
            updateUserEmail: false,
        });
    };

    handleUserUpdate = async () => {
        this.setState({updatingUser:true});
        let { users } = this.state;

        try {
            const res = await API.post("PrintPortalAPI", this.userApiPath + '/update-user', {
                headers: {},
                body:{
                    ID:this.state.updateUserID,
                    Username: this.state.updateUserUsername,
                    Token: this.props.token,
                    Action:this.state.dialogAction
                }
            });

            for(let i = 0; i<this.state.users.length; i++) {
                if(users[i]['ID']===this.state.updateUserID) {
                    if(this.state.dialogAction==='Disable') {
                        users[i]['Disabled'] = true;
                    } else {
                        users[i]['Disabled'] = false;
                    }

                    this.setState({users});
                }
            }

            this.setState({
                updatingUser:false,
                confirmUpdateUser: false,
                updateUserID : false,
                updateUserEmail: false,
                dialogAction:'',
            });

        } catch(e) {
            console.log(e);
        }
    };

    render(){

        const {userGroup, classes} = this.props;
        const {clients, clientFilterBy} = this.state;

        if (userGroup !== this.state.access) {
            return Utils.accessDenied();
        }

        let dialogActions = (<DialogActions>
            <Button onClick={this.closeDialog} color="primary">
                No
            </Button>
            <Button onClick={this.handleUserUpdate} color="primary" autoFocus>
                Yes
            </Button>
        </DialogActions>);
        if(this.state.updatingUser) {
            dialogActions =  (<DialogContent ><CircularProgress className={this.loader} color="secondary"/></DialogContent>)
        }

        let content;
        if(!this.state.users) {
            content = (<CircularProgress color="secondary"/>);
        } else if(this.state.users==='none') {
            content = (<div>
                <Typography variant="h4" gutterBottom>No users in database</Typography>
                <Button variant="contained" onClick={() => {this.props.history.push("/user/add")}}>
                    Add User
                </Button>
            </div>);
        } else {

            let clientFilter = false;
            let currentClient = false;

            if(clients) {

                const options = [{
                    'name' : 'All',
                    'value' : 'all'
                }].concat(
                    clients.map(CLIENT => {

                        if(clientFilterBy && clientFilterBy===CLIENT.ID) {
                            currentClient = CLIENT.Name
                        }

                        return(
                            {
                                name : CLIENT.Name,
                                value : CLIENT.ID
                            }
                        )
                    })
                );

                clientFilter = (

                    <Grid item md={6} sm={6} xs={6}>

                        <Autocomplete
                            options={options}
                            onChange={(e, option) => this.handleClientFilter(option)}
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                                <TextField {...params} label='Filter client' variant="outlined" fullWidth />
                            )}
                        />

                        { (currentClient ? <p>Filtered by client : <b>{currentClient}</b></p> : '') }
                        <br /><br />
                    </Grid>

                );
            }

            content = (
                <div>
                    <Grid container>
                        {clientFilter}
                    </Grid>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableFirstCell}>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Client</TableCell>
                                <TableCell className={classes.tableLastCell}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.users.map(USER => {
                                    let enableDisableAction;
                                    if(USER.Disabled) {
                                       enableDisableAction = (
                                           <IconButton
                                               onClick={() => this.openUpdateDialog(USER.ID, USER.Username, 'Enable')}
                                               aria-label="Enable user"
                                           >
                                               <ErrorIcon />
                                           </IconButton>
                                       )
                                    } else {
                                        enableDisableAction = (
                                            <IconButton
                                                onClick={() => this.openUpdateDialog(USER.ID, USER.Username, 'Disable')}
                                                aria-label="Disable user"
                                            >
                                                <CheckIcon />
                                            </IconButton>
                                        )
                                    }

                                    return(
                                        <TableRow key={USER.ID}>
                                            <TableCell><Link href="" onClick={(e) => this.gotoUser(e, USER.ID)}>{USER.Name}</Link></TableCell>
                                            <TableCell>{USER.Email}</TableCell>
                                            <TableCell>{USER.Client}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={(e) => this.gotoUser(e, USER.ID)}  aria-label="Edit">
                                                    <EditIcon />
                                                </IconButton>
                                                {enableDisableAction}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                    <Dialog
                        open={this.state.confirmUpdateUser}
                        onClose={this.closeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{this.state.dialogAction} User <b>{this.state.updateUserEmail}</b>?</DialogTitle>
                        {dialogActions}
                    </Dialog>
                </div>
            )
        }

        return <Paper className={classes.root} elevation={1}>{content}</Paper>;
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(UserList));