import React from 'react';

import Typography from '@material-ui/core/Typography';

//  ------------------------------------------------------------------------------------------------------------------------------------------

export function withAdminUserGuard(Guarded) {
   return (props) => (props.userGroup === 'Admin') ? (<Guarded {...props} />) : (<AccessDenied />);
}

//  ------------------------------------------------------------------------------------------------------------------------------------------

function AccessDenied(props) {
   return (<Typography>Access Denied</Typography>);
}

//  ------------------------------------------------------------------------------------------------------------------------------------------

export default AccessDenied;

