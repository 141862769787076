import React, { Component } from 'react';
import {withStyles} from "@material-ui/core/styles";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from "@material-ui/core/Input";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import uuid from "uuid";

import Utils from "../../../utils.js";

const styles = theme => ({
    wrapper : {
        width:"100%"
    },
    table: {
        marginTop: theme.spacing(2),
        marginBottom : theme.spacing(2)
    },
    cell: {
        width:200,
        padding:"15px 5px",
        verticalAlign:'top',
        borderBottom:0
    },
    cellHead: {
        width:200,
        padding:"15px 5px",
        textAlign:'left',
        backgroundColor:'#efefef',
        fontWeight:'bold'
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    calcWrapper: {
        width:"100%"
    }
});

class ProductPriceCalculator extends Component {

    state = {
        id:uuid.v4(),
        quantity: 0,
        rrp: 0,
        cost: 0,
        affiliate: 0,
        affiliateCalcType: 'percent',
        affiliateCost: 0,
        markUpPercent: 0,
        markUpAmount: 0,
        clientMarkUp: 0,
        clientMarkUpCalcType: 'percent',
        clientCost: 0,
        typesettingCost: 0,
        designCost: 0,
        otherCost: 0,
        affiliateSplit: 0,
        sellingPrice: 0,
        rrpVsSelling: 0,
        tpppMargin: 0,
        affiliateCommission: 0
    };

    componentDidMount() {
        const { data } = this.props;

        this.setState(data, this.updateValues);
    }

    isNumeric(value) {
        return !isNaN(value - parseFloat(value));
    }

    sanitiseValue(value) {
        if(this.isNumeric(value)) {
            return parseFloat(Math.round(value * 100) / 100);
        }

        return 0;
    }

    handleValueChange = (ev) => {
        let { name, value } = ev.currentTarget;

        this.setState({
            [name]: value
        }, this.updateValues);
    };

    updateValues() {
        let {
            rrp,
            cost,
            affiliate,
            affiliateCalcType,
            clientMarkUp,
            clientMarkUpCalcType,
            typesettingCost,
            designCost,
            otherCost,
            affiliateSplit
        } = this.state;

        let affiliateCost,
            markUpPercent,
            markUpAmount,
            clientCost,
            sellingPrice,
            rrpVsSelling,
            tpppMargin,
            affiliateCommission;

        if(!rrp) {
            rrp = "0";
        }

        if(!cost) {
            cost = "0";
        }

        if(!affiliate) {
            affiliate = "0";
        }

        if(!clientMarkUp) {
            clientMarkUp = "0";
        }

        if(!typesettingCost) {
            typesettingCost = "0";
        }

        if(!designCost) {
            designCost = "0";
        }

        if(!otherCost) {
            otherCost = "0";
        }

        if(!affiliateSplit) {
            affiliateSplit = "0";
        }


        if(affiliateCalcType==='percent') {
            affiliateCost =
                Utils.penceToPounds(
                (Utils.poundsToPence(cost) * affiliate) / 100 + Utils.poundsToPence(cost)
                );
        } else {
            affiliateCost =
                Utils.penceToPounds(
                    (Utils.poundsToPence(cost) + Utils.poundsToPence(affiliate))
                )
        }

        if(clientMarkUpCalcType==='percent') {
            clientCost =
                Utils.penceToPounds(
                (Utils.poundsToPence(affiliateCost) * clientMarkUp) / 100 + Utils.poundsToPence(affiliateCost)
                );
        } else {
            clientCost =
                Utils.penceToPounds(
                    (Utils.poundsToPence(clientMarkUp) + Utils.poundsToPence(affiliateCost))
                );
        }

        affiliateCost =
            this.sanitiseValue(affiliateCost);
        markUpPercent =
            this.sanitiseValue((affiliateCost - cost) * 100 / cost);
        markUpAmount =
            Utils.penceToPounds(
                this.sanitiseValue(((Utils.poundsToPence(cost) * markUpPercent / 100) + Utils.poundsToPence(cost)) - Utils.poundsToPence(cost))
            );

        //console.log(clientCost, clientMarkUp);

        sellingPrice =
            Utils.penceToPounds(
                this.sanitiseValue(
                    (
                        Utils.poundsToPence(clientCost) +
                        Utils.poundsToPence(typesettingCost) +
                        Utils.poundsToPence(designCost) +
                        Utils.poundsToPence(otherCost)
                    )
                )
            );
        rrpVsSelling =
            this.sanitiseValue(rrp - sellingPrice);

        tpppMargin =
            this.sanitiseValue((sellingPrice - affiliateCost) * (100-affiliateSplit) / 100+markUpAmount);

        affiliateCommission =
            this.sanitiseValue((sellingPrice - affiliateCost) * affiliateSplit / 100);

        this.setState({
            rrp,
            cost,
            affiliate,
            affiliateCalcType,
            clientMarkUp,
            clientMarkUpCalcType,
            typesettingCost,
            designCost,
            otherCost,
            affiliateSplit,
            affiliateCost,
            markUpPercent,
            markUpAmount,
            clientCost,
            sellingPrice,
            rrpVsSelling,
            tpppMargin,
            affiliateCommission
        }, () => {
            this.props.onChange(this.state);
        })



    }

    render() {

        const { classes } = this.props;

        const {
            quantity,
            rrp,
            cost,
            affiliate,
            affiliateCalcType,
            affiliateCost,
            markUpPercent,
            markUpAmount,
            clientMarkUp,
            clientMarkUpCalcType,
            clientCost,
            typesettingCost,
            designCost,
            otherCost,
            affiliateSplit,
            sellingPrice,
            rrpVsSelling,
            tpppMargin,
            affiliateCommission
        } = this.state;

        return(

            <div className={classes.calcWrapper}>
                <FormControl>
                    <InputLabel>Quantity *</InputLabel>
                    <Input
                        variant="outlined"
                        name="quantity"
                        fullWidth
                        type="number"
                        value={quantity}
                        onChange={this.handleValueChange}
                        required
                    />
                </FormControl>

                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cellHead}>Edit</TableCell>
                            <TableCell className={classes.cellHead}>Read Only</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <InputLabel>RRP *</InputLabel>
                                    <Input
                                        variant="outlined"
                                        name="rrp"
                                        fullWidth
                                        onChange={this.handleValueChange}
                                        required
                                        value={rrp}
                                    />
                                </FormControl>
                            </TableCell>
                            <TableCell className={classes.cell}>&nbsp;</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <InputLabel>Cost *</InputLabel>
                                    <Input
                                        variant="outlined"
                                        name="cost"
                                        fullWidth
                                        onChange={this.handleValueChange}
                                        required
                                        value={cost}
                                    />
                                </FormControl>
                            </TableCell>
                            <TableCell className={classes.cell}>&nbsp;</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <InputLabel>Affiliate Markup (%/£)</InputLabel>
                                    <Input
                                        variant="outlined"
                                        name="affiliate"
                                        fullWidth
                                        onChange={this.handleValueChange}
                                        required
                                        value={affiliate}
                                    />
                                </FormControl>

                                <div>
                                    <FormControl component="fieldset">
                                        <RadioGroup row name="affiliateCalcType"  onChange={this.handleValueChange} value={affiliateCalcType}>
                                            <FormControlLabel value="percent" control={<Radio />} label="%" labelPlacement="end" />
                                            <FormControlLabel value="unit" control={<Radio />} label="£" labelPlacement="end" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>

                            </TableCell>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <InputLabel>Affiliate Cost</InputLabel>
                                    <Input
                                        fullWidth
                                        variant="filled"
                                        value={Utils.formatMoney(affiliateCost)}
                                        readOnly
                                    />
                                    {(affiliateCalcType==='unit' ?
                                        <small>{Utils.getPercentageDiff(
                                            Utils.poundsToPence(affiliateCost),
                                            Utils.poundsToPence(cost)
                                        )}%</small> : ''
                                    )}
                                </FormControl>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cellHead}>Edit</TableCell>
                            <TableCell className={classes.cellHead}>Read Only</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <InputLabel>Client Markup (%/£)</InputLabel>
                                    <Input
                                        variant="outlined"
                                        name="clientMarkUp"
                                        fullWidth
                                        onChange={this.handleValueChange}
                                        required
                                        value={clientMarkUp}
                                    />
                                </FormControl>

                                <div>
                                    <FormControl component="fieldset">
                                        <RadioGroup row name="clientMarkUpCalcType"  onChange={this.handleValueChange} value={clientMarkUpCalcType}>
                                            <FormControlLabel value="percent" control={<Radio />} label="%" labelPlacement="end" />
                                            <FormControlLabel value="unit" control={<Radio />} label="£" labelPlacement="end" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <InputLabel>Our mark-up (%)</InputLabel>
                                    <Input
                                        variant="outlined"
                                        fullWidth
                                        value={markUpPercent}
                                        readOnly
                                    />
                                </FormControl>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.cell}>&nbsp;</TableCell>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <InputLabel>Our mark-up (£)</InputLabel>
                                    <Input
                                        variant="outlined"
                                        fullWidth
                                        value={Utils.formatMoney(markUpAmount)}
                                        readOnly
                                    />
                                </FormControl>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.cell}>&nbsp;</TableCell>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <Input
                                        type="hidden"
                                        variant="outlined"
                                        fullWidth
                                        value={Utils.formatMoney(clientCost)}
                                        readOnly
                                    />
                                </FormControl>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cellHead}>Edit</TableCell>
                            <TableCell className={classes.cellHead}>Read Only</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <InputLabel>Typesetting (£)</InputLabel>
                                    <Input
                                        variant="outlined"
                                        name="typesettingCost"
                                        fullWidth
                                        onChange={this.handleValueChange}
                                        required
                                        value={typesettingCost}
                                    />
                                </FormControl>
                            </TableCell>
                            <TableCell className={classes.cell}>&nbsp;</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <InputLabel>Design (£)</InputLabel>
                                    <Input
                                        variant="outlined"
                                        name="designCost"
                                        fullWidth
                                        onChange={this.handleValueChange}
                                        required
                                        value={designCost}
                                    />
                                </FormControl>
                            </TableCell>
                            <TableCell className={classes.cell}>&nbsp;</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <InputLabel>Other (£)</InputLabel>
                                    <Input
                                        variant="outlined"
                                        name="otherCost"
                                        fullWidth
                                        onChange={this.handleValueChange}
                                        required
                                        value={otherCost}
                                    />
                                </FormControl>
                            </TableCell>
                            <TableCell className={classes.cell}>&nbsp;</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cellHead}>Edit</TableCell>
                            <TableCell className={classes.cellHead}>Read Only</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <InputLabel>Affiliate Split (%)</InputLabel>
                                    <Input
                                        variant="outlined"
                                        name="affiliateSplit"
                                        fullWidth
                                        onChange={this.handleValueChange}
                                        required
                                        value={affiliateSplit}
                                    />
                                </FormControl>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <InputLabel>Selling Price (£)</InputLabel>
                                    <Input
                                        variant="outlined"
                                        fullWidth
                                        value={Utils.formatMoney(sellingPrice)}
                                        readOnly
                                    />
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cell}>&nbsp;</TableCell>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <InputLabel>RRP vs Selling</InputLabel>
                                    <Input
                                        variant="outlined"
                                        fullWidth
                                        value={Utils.formatMoney(rrpVsSelling)}
                                        readOnly
                                    />
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cell}>&nbsp;</TableCell>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <InputLabel>TPPP Margin (£)</InputLabel>
                                    <Input
                                        variant="outlined"
                                        fullWidth
                                        value={Utils.formatMoney(tpppMargin)}
                                        readOnly
                                    />
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cell}>&nbsp;</TableCell>
                            <TableCell className={classes.cell}>
                                <FormControl>
                                    <InputLabel>Affiliate Commission (£)</InputLabel>
                                    <Input
                                        variant="outlined"
                                        fullWidth
                                        value={Utils.formatMoney(affiliateCommission)}
                                        readOnly
                                    />
                                </FormControl>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ProductPriceCalculator);