import React from "react";
import { SignIn } from "aws-amplify-react";
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import InfoIcon from '@material-ui/icons/Info';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    container: {
        overflow: 'hidden',
        position:'absolute',
        width:'100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    box: {
        maxWidth:410,
        flex: 1,
        margin:"0 auto"
    },
    paper: {
        //marginTop: theme.spacing.unit * 8,
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    logo: {
        maxWidth:'100%'
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    warningIcon: {
        position:'relative',
        left: '-10px'
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    snackBar: {
        backgroundColor:'#ffa000',
        marginTop:15,
        marginBottom:15
    },
    passwordPolicy: {
        paddingLeft:15,
        marginTop:5
    }
});

class CustomSignIn extends SignIn {

    state = {
        email: '',
        password: '',
        repassword: '',
        code:'',
        template: false,
        message: false,
        user: false,
        buttonText : 'Sign in'
    };

    handleInputChange = (property) => {
        return e => {
            this.setState({
                [property]: e.target.value
            })
        }
    };

    async signIn() {
        this.setState({
            error: false,
            message: false,
            buttonText : <CircularProgress color="primary" />
        });

        try {
            let user = await Auth.signIn(this.state.email, this.state.password);
            if(typeof(user.challengeName) !== 'undefined') {
                if(user.challengeName==='NEW_PASSWORD_REQUIRED') {
                    this.setState({
                        template: 'setPassword',
                        user,
                        password:'',
                        repassword:'',
                        buttonText : 'Set Password'
                    });
                }
            } else {
                window.location.reload();
            }

        } catch(err) {
            if(err.code === 'PasswordResetRequiredException') {
                //console.log(err);
                this.setState({
                    error: true,
                    message: 'Password Reset Required',
                    buttonText: 'Sign in'
                });
            } else {
                this.setState({
                    error: true,
                    message: 'Incorrect username or password.',
                    buttonText: 'Sign in'
                })
            }
        }
    };

    async setPassword() {
        this.setState({
            error: false,
            message: false,
            buttonText : 'Set Password'
        });

        if(this.state.password!==this.state.repassword || !this.state.password || !this.state.repassword) {
            this.setState({
                error: true,
                message: "Your passwords do no match",
                buttonText: "Set Password"
            });
        } else {
            if(this.state.code) {
                try {
                    await Auth.forgotPasswordSubmit(
                        this.state.email,
                        this.state.code,
                        this.state.password
                    );

                    window.location.reload();

                } catch(e) {
                    this.setState({
                        error: true,
                        message : e.message
                    });
                }
            } else {
                this.setState({
                    buttonText: <CircularProgress color="primary"/>
                });

                try {
                    await Auth.completeNewPassword(
                        this.state.user, this.state.password
                    );
                } catch(e) {
                    this.setState({
                        error: true,
                        message: e.message,
                        buttonText: "Set Password"
                    });
                }

                await Auth.signIn(this.state.email, this.state.password);
                window.location.reload();

            }
        }

    }

    async resetPassword() {
        this.setState({
            error: false,
            message: false,
            template: 'resetPassword',
            buttonText : 'Get code'
        });

        if(this.state.email!=='') {
            this.setState({
                buttonText : <CircularProgress color="primary" />
            });

            try {
                const res = await Auth.forgotPassword(this.state.email);
                this.setState({
                    template:'resetPasswordCode',
                    error:false,
                    message: false,
                    buttonText : 'Submit code'
                });
            } catch(e) {
                console.log(e);
                let message = 'Email address not found';
                if(e.code==='LimitExceededException') {
                    message = 'Reset attempts exceeded. Please try again later';
                }
                this.setState({
                    buttonText : 'Get code',
                    error: true,
                    message
                });
            }
        }
    }

    getForm() {

        const { classes } = this.props;

        let error = false;
        if(this.state.error) {
            error = <SnackbarContent
                className={classes.error}
                aria-describedby="client-snackbar"
                message={
                    <span
                        id="client-snackbar"
                        className={classes.message}>
                        {this.state.message}
                        </span>
                }
            />
        }

        if(this.state.template==='resetPassword') {
            return (<div>
                <Typography>
                    Please enter your e-mail
                </Typography>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="email">Email Address</InputLabel>
                    <Input
                        id="email"
                        name="email"
                        autoComplete="email"
                        value={this.state.email}
                        onChange={this.handleInputChange('email')}
                        onKeyDown={e => {  if(e.key==='Enter') { this.resetPassword(e) } } }
                        startAdornment={
                            <InputAdornment position="start">
                                <Icon>email</Icon>
                            </InputAdornment>
                        }
                        autoFocus
                    />
                </FormControl>
                {error}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={e => this.resetPassword(e)}
                >
                    {this.state.buttonText}
                </Button>
            </div>)
        }

        if(this.state.template==='resetPasswordCode') {
            return (<div>
                <Typography>
                    Please enter your verification code
                </Typography>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="code">Code</InputLabel>
                    <Input
                        id="code"
                        name="code"
                        value={this.state.code}
                        onChange={this.handleInputChange('code')}
                        onKeyDown={e => {  if(e.key==='Enter') { this.setPassword(e) } } }
                        autoFocus
                    />
                </FormControl>
                <Typography varient="body1">
                    Please set your new password
                </Typography>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input
                        name="password"
                        type="password"
                        id="password"
                        value={this.state.password}
                        onChange={this.handleInputChange('password')}
                        onKeyDown={e => {  if(e.key==='Enter') { this.setPassword(e) } } }
                        startAdornment={
                            <InputAdornment position="start">
                                <Icon>lock</Icon>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText>
                        Password must include:<br />
                        <ul className={classes.passwordPolicy}>
                            <li>1x Number</li>
                            <li>1x Uppercase letter</li>
                            <li>1x Lowecase letter</li>
                            <li>1x Special character (such as @, #, $, %, &, * and +)</li>
                        </ul>
                    </FormHelperText>
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="repassword">Re-enter Password</InputLabel>
                    <Input
                        name="repassword"
                        type="password"
                        id="repassword"
                        value={this.state.repassword}
                        onChange={this.handleInputChange('repassword')}
                        onKeyDown={e => {  if(e.key==='Enter') { this.setPassword(e) } } }
                        startAdornment={
                            <InputAdornment position="start">
                                <Icon>lock</Icon>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                {error}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={e => this.setPassword(e)}
                >
                    {this.state.buttonText}
                </Button>
            </div>)
        }

        if(this.state.template==='setPassword') {
            return (<div>
                <Typography>
                    <SnackbarContent className={classes.snackBar} message={<span className={classes.message}>
                        <InfoIcon className={classes.warningIcon} />
                        To access the Print Portal you must first set Your Password.
                        </span>} />
                </Typography>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input
                        name="password"
                        type="password"
                        id="password"
                        value={this.state.password}
                        onChange={this.handleInputChange('password')}
                        onKeyDown={e => {  if(e.key==='Enter') { this.setPassword(e) } } }
                        startAdornment={
                            <InputAdornment position="start">
                                <Icon>lock</Icon>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText>
                        Password must include:<br />
                        <ul className={classes.passwordPolicy}>
                            <li>1x Number</li>
                            <li>1x Uppercase letter</li>
                            <li>1x Lowecase letter</li>
                            <li>1x Special character (such as @, #, $, %, &, * and +)</li>
                        </ul>
                    </FormHelperText>
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="repassword">Re-enter Password</InputLabel>
                    <Input
                        name="repassword"
                        type="password"
                        id="repassword"
                        value={this.state.repassword}
                        onChange={this.handleInputChange('repassword')}
                        onKeyDown={e => {  if(e.key==='Enter') { this.setPassword(e) } } }
                        startAdornment={
                            <InputAdornment position="start">
                                <Icon>lock</Icon>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                {error}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={e => this.setPassword(e)}
                >
                    {this.state.buttonText}
                </Button>
            </div>);

        } else {
            return (
                <div>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">Email Address</InputLabel>
                        <Input
                            id="email"
                            name="email"
                            autoComplete="email"
                            value={this.state.email}
                            onChange={this.handleInputChange('email')}
                            onKeyDown={e => {  if(e.key==='Enter') { this.signIn(e) } } }
                            startAdornment={
                                <InputAdornment position="start">
                                    <Icon>email</Icon>
                                </InputAdornment>
                            }
                            autoFocus
                        />
                        <FormHelperText>NOTE : Email addresses are case sensitive.</FormHelperText>
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                            name="password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={this.state.password}
                            onChange={this.handleInputChange('password')}
                            onKeyDown={e => {  if(e.key==='Enter') { this.signIn(e) } } }
                            startAdornment={
                                <InputAdornment position="start">
                                    <Icon>lock</Icon>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    {error}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={e => this.signIn(e)}
                    >
                        {this.state.buttonText}
                    </Button>
                    <br /><br />
                    <Typography component="p" variant="body1">Not yet registered? {/*<Button size="small" href="//thepersonalprintportal.com" variant="contained" color="secondary">Create FREE Account</Button>*/}
                    <Link href="//thepersonalprintportal.com" color="secondary">Create FREE Account</Link></Typography>
                    <br />
                    <Typography component="p" variant="body1">Already have an account? &nbsp;
                    <Link
                        variant="body1"
                        color="secondary"
                        onClick={() => this.resetPassword()}
                    >
                        Reset password
                    </Link></Typography>
                </div>
            );
        }
    };

    showComponent(theme) {
        const { classes } = this.props;

        return (
            <div className={classes.container}>
                <div className={classes.box}>
                    <CssBaseline />
                    <img className={classes.logo} src="/images/tppimage.png" alt="" />

                    <Paper className={classes.paper}>
                        <Typography component="h1" variant="h5">
                            Online Print Portal Login
                        </Typography>
                        {this.getForm()}
                    </Paper>
                </div>
            </div>
        );
    }
}

CustomSignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CustomSignIn);