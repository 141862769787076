import React, { Component } from "react"
import { withStyles } from '@material-ui/core/styles/index';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

const styles = theme => ({
    /*modal: {
        overflow: 'scroll'
    },*/
    titleContainer: {
        paddingTop:10,
        paddingBottom:0
    },
    contentContainer: {
        minWidth:600,
        overflowX:'hidden'
    },
    title: {
        float:'left',
        width:'100%'
    },
    icon: {
        float: "right"
    },
});

class ProductDialog extends Component {
    state = {
        showHiddenAttributes : false
    };

    toggleShowHiddenAttributes = () => {
        const { showHiddenAttributes } = this.state;

        if(showHiddenAttributes) {
            this.setState({
                showHiddenAttributes : false,
            });

        } else {
            this.setState({
                showHiddenAttributes : true
            });
        }

        this.props.toggleHiddenAttributes(showHiddenAttributes);
    };

    render() {
        const { content, open, classes, preview } = this.props;
        const { showHiddenAttributes } = this.state;

        let actions = false;
        if(preview) {
            let showHiddenAttributesButtonTxt = 'Remove hidden attributes';
            if(showHiddenAttributes) {
                showHiddenAttributesButtonTxt = 'Show hidden attributes';
            }
            actions = (
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={this.toggleShowHiddenAttributes}
                    >{showHiddenAttributesButtonTxt}</Button>
                </DialogActions>)
        }

        return(
            <Dialog
                scroll="paper"
                open={open}
                onClose={this.props.closeProduct}
                className={classes.modal}
            >

                    <DialogTitle className={classes.titleContainer}>
                        <div className={classes.title}>
                            {this.props.title}
                            <div className={classes.icon}>
                                <IconButton
                                    onClick={this.props.closeProduct}
                                    color="primary"
                                >
                                    <Icon>
                                        close
                                    </Icon>
                                </IconButton>
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent dividers className={classes.contentContainer}>
                        {content}
                        <br />
                    </DialogContent>
                    {actions}
            </Dialog>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ProductDialog);