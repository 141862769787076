import { API } from 'aws-amplify';

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Link from '@material-ui/core/Link';
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {withStyles} from "@material-ui/core/styles";

import { withAdminUserGuard } from './components/AccessDenied';

//  ------------------------------------------------------------------------------------------------------------------------------------------

const styles = (theme) => ({
   root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
   },
   moreResults: {
      margin:"10px 0",
      textAlign:"center"
   }
});

//  ------------------------------------------------------------------------------------------------------------------------------------------

function ClientList(props) {

   const [ clients, setClients ] = useState(null)

   useEffect(() => {
      if ( clients === null ) {
         API.get("PrintPortalAPI", "/clients", {
            headers  : { },
            response : false
         }).then((response) => {
            setClients(response.data.map((c) => ({ ID : c.ID, Name : c.Name })).sort((a, b) => (a.Name < b.Name) ? -1 : (a.Name > b.Name) ? 1 : 0));
         }).catch((error) => {
            console.log(error);
         });
      }
   }, [ ]);

   function gotoClient(event, id) {
      event.preventDefault();
      return props.history.push('/client/edit/' + id);
   }

   function gotoEdit(event, id) {
      return props.history.push('/client/edit/' + id);
   }

   const { classes } = props;

   return (clients === null) ? (
      <CircularProgress color="secondary"/>
   ) : (
      <Paper className={classes.root} elevation={1}>
         <Table>
            <TableHead>
               <TableRow>
                  <TableCell className={classes.tableFirstCell}>Name</TableCell>
                  <TableCell className={classes.tableLastCell}>Actions</TableCell>
               </TableRow>
            </TableHead>
            <TableBody>{
               clients.map((client) => (
                  <TableRow key={client.ID}>
                     <TableCell>
                        <Link href="" onClick={(e) => gotoClient(e, client.ID)}>{client.Name}</Link>
                     </TableCell>
                     <TableCell>
                        <IconButton onClick={(e) => gotoEdit(e, client.ID)} aria-label="Edit">
                           <EditIcon />
                        </IconButton>
                     </TableCell>
                  </TableRow>
               ))
            }</TableBody>
         </Table>
      </Paper>
   );

}

//  ------------------------------------------------------------------------------------------------------------------------------------------

export default withAdminUserGuard(withRouter(withStyles(styles, { withTheme: true })(ClientList)));

