const PRODUCTS = [
    {
        SKU:1,
        title:"A5 Notebook",
        thumb:"/images/product-thumb.png",
        description:"An A5 Notebook",
        gallery: [
            {
                "label" : "Business Card Front",
                "src" : "/images/product-gallery.png",
                "caption" : "Gallery 1"
            },
            {
                "label" : "Business Card Back",
                "src" : "/images/product-gallery.png",
                "caption" : "Gallery 2"
            }
        ],
        attributes:[
            {
                label: "Size",
                name: "size",
                field: "readonly",
                value: "A5"
            },
            {
                label: "Cover Print",
                name:"cover-print",
                field: "readonly",
                value: "Full colour both sides",
            },
            {
                label: "Cover Laminate",
                name:"cover-laminate",
                field: "readonly",
                value: "1700 microns"
            }
        ]
    },
    {
        SKU:2,
        title:"Business Cards Individual",
        thumb:"/images/product-thumb.png",
        description:"Individual Business Cards",
        gallery: [
            {
                "label" : "Business Card Front",
                "src" : "/images/product-gallery.png"
            },
            {
                "label" : "Business Card Back",
                "src" : "/images/product-gallery.png"
            }
        ],
        attributes: [
            {
                "label" : "Full Name",
                "name"  : "name",
                "field" : "text",
                "required" : true
            },
            {
                "label" : "Job title",
                "name"  : "position",
                "field" : "text",
                "required" : true
            },
            {
                "label" : "Email",
                "name"  : "email",
                "field" : "email",
                "required" : true
            },
            {
                "label" : "Telephone number",
                "name"  : "telephone",
                "field" : "text",
                "required" : true
            },
            {
                "label" : "Mobile number",
                "name"  : "mobile",
                "field" : "text",
                "required" : true
            }
        ]
    },
    {
        SKU:3,
        title:"Business Cards WIFI",
        thumb:"/images/product-thumb.png",
        description:"WIFI Business Cards",
        gallery: [
            {
                "label" : "Business Card Front",
                "src" : "/images/product-gallery.png",
                "caption" : "Gallery 1"
            },
            {
                "label" : "Business Card Back",
                "src" : "/images/product-gallery.png",
                "caption" : "Gallery 2"
            }
        ],
        attributes:[]
    },
    {
        "SKU" : "123",
        "title":"A5 Presentation Cover",
        "thumb":"/images/product-thumb.png",
        "gallery": [
            {
                "label" : "Business Card Front",
                "src" : "/images/product-gallery.png",
                "caption" : "Gallery 1"
            },
            {
                "label" : "Business Card Back",
                "src" : "/images/product-gallery.png",
                "caption" : "Gallery 2"
            }
        ],
        "attributes":[
            {
                "label": "Size",
                "name":"size",
                "field": "readonly",
                "value": "A5"
            }
        ],
        "description": "Test description"
    }
];

export default PRODUCTS;