import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {withStyles} from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import RootRef from "@material-ui/core/RootRef";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import uuid from "uuid";

import Utils from "../../../utils";
import ProductPriceCalculator from "./ProductPriceCalculator";

const styles = theme => ({
    panelDetails: {
        width:"100%",
        display:"block",
        paddingBottom:theme.spacing(2)
    }
});

const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)"
    })
});

let defaultPrices = {
    quantity: 0,
    rrp: 0,
    cost: 0,
    affiliate: 0,
    affiliateCalcType: 'percent',
    affiliateCost: 0,
    markUpPercent: 0,
    markUpAmount: 0,
    clientMarkUp: 0,
    clientMarkUpCalcType: 'percent',
    clientCost: 0,
    typesettingCost: 0,
    designCost: 0,
    otherCost: 0,
    affiliateSplit: 0,
    sellingPrice: 0,
    rrpVsSelling: 0,
    tpppMargin: 0,
    affiliateCommission: 0
};

class ProductPricesAdmin extends Component {
    state = {
        prices:[],
        allExpanded: [],
        removeQuantityPrompt:false,
        removeQuantityId:false,
        removeQuantityDialog :false
    };

    componentDidMount() {
        const { prices } = this.props;

        let allExpanded = [];
        for(let i=0; i<prices.length;i++) {
            allExpanded[i] = false;
        }

        this.setState({
            prices,
            allExpanded
        })
    }

    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        let { prices } = this.state;

        prices = Utils.reorder(
            prices,
            result.source.index,
            result.destination.index
        );

        this.props.onChange(prices)
        this.setState({prices});
    };

    setExpanded = (expanded, index) => {
        let {allExpanded} = this.state;

        allExpanded[index] = expanded;

        this.setState({allExpanded});
    };

    setAllExpanded = () => {
        const { prices } = this.state;
        let { allExpanded } = this.state;

        for(let i=0; i<prices.length; i++) {
            allExpanded[i] = true;
        }

        this.setState({prices});
    };

    setAllShrink = () => {
        const { prices } = this.state;
        let { allExpanded } = this.state;

        for(let i=0; i<prices.length; i++) {
            allExpanded[i] = false;
        }

        this.setState({allExpanded});
    };


    addQuantity = () => {
        let { prices } = this.state;

        let newPrice =  Object.assign({}, defaultPrices);
        newPrice.id = uuid.v4();

        prices.push(newPrice);

        this.setState({
            prices
        });

    };

    openRemoveQuantityDialog = (id) => {
        this.setState({
            removeQuantityDialog : true,
            removeQuantityId: id
        })
    };

    closeRemoveQuantityDialog = () => {
        this.setState({
            removeQuantityDialog : false,
            removeQuantityId: false
        })
    }

    handleRemoveQuantity = () => {
        let prices = this.state.prices;
        for(let i = 0; i<prices.length; i++) {
            if(prices[i].id===this.state.removeQuantityId) {
                prices.splice(i,1);
                this.props.onChange(prices);
                this.setState({
                    prices,
                    removeQuantityDialog : false,
                    removeQuantityId: false
                });
            }
        }
    };

    onChange = (data) => {
        let { prices } = this.state;

        prices.map((key, index) => {
            if(key.id===data.id) {
                return prices[index] = data;
            }
        });

        this.props.onChange(prices);
    };

    render() {

        const { prices, allExpanded } = this.state;
        const { classes } = this.props;

        return(
            <div>
                <div style={{ display: 'flex', justifyContent:'flex-end', marginBottom:5 }}>
                    <Button size="small" color="inherit" onClick={this.setAllExpanded}>
                        Expand all
                    </Button>
                    <Button size="small" color="inherit" onClick={this.setAllShrink}>
                        Shrink all
                    </Button>
                </div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => {
                            return(
                                <RootRef rootRef={provided.innerRef}>
                                    <div>
                                        {
                                            prices.map((key, index) =>
                                                <Draggable key={key.id} draggableId={key.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <RootRef rootRef={provided.innerRef}>
                                                        <ExpansionPanel
                                                                expanded={allExpanded[index]}
                                                                onChange={(ev, expanded) => {
                                                                    this.setExpanded(expanded, index)
                                                                }}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}>
                                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                    <Typography>QUANTITY : <b>{key.quantity}</b></Typography>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails className={classes.panelDetails}>
                                                                    <ProductPriceCalculator data={key} onChange={this.onChange} />
                                                                    <Button
                                                                        style={{float: 'right'}}
                                                                        component="span"
                                                                        onClick={() => this.openRemoveQuantityDialog(key.id)}
                                                                    >
                                                                        x Remove Quantity
                                                                    </Button>
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                        </RootRef>
                                                    )}
                                                </Draggable>
                                            )
                                        }
                                    </div>
                                </RootRef>
                            )
                        }}
                    </Droppable>
                </DragDropContext>
                <Button
                component="span"
                onClick={this.addQuantity}
            >
                + Add Quantity
            </Button>
                <Dialog
                    open={this.state.removeQuantityDialog}
                    onClose={this.closeRemoveQuantityDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Delete quantity <b>{this.state.id}</b>?</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.closeRemoveQuantityDialog} color="primary">
                            No
                        </Button>
                        <Button onClick={this.handleRemoveQuantity} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>);
    }
}

export default withStyles(styles, { withTheme: true })(ProductPricesAdmin);