import React, { Component } from "react"
import Typography from "@material-ui/core/Typography"
import {withStyles} from "@material-ui/core/styles";


const styles = theme => ({
    foot: {
        position:'fixed',
        bottom:0,
        height:'25px',
        background:'#efefef',
        width:240,
        zIndex:9999
    }
});

class Foot extends Component {

    state = {
        year : false
    };

    componentDidMount() {
        const date = new Date();
        this.setState({
            year: date.getFullYear()
        })
    }

    render() {
        const { classes } = this.props;
        const { year } = this.state;

        return(
            <div className={classes.foot}>
                <Typography varient="body2">
                    &nbsp;&copy; {year} The Print Portal Ltd
                </Typography>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Foot);