import React, { Component } from 'react';
import Divider from "@material-ui/core/Divider";
import { NavLink } from "react-router-dom"
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography"
import SignOutConfirm from "./SignOutConfirm";
import { withStyles } from "@material-ui/core/styles";
import MAINLINKS from "../data/MAINLINKS";
import Foot from "./Foot";
import Utils from "../utils";

const drawerWidth = 240;

const styles = theme => ({
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        }
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    logo:{
        maxWidth:"100%",
       // maxHeight:"60px"
    },
    logoContainer: {
        width:150,
        margin:"0 auto"
    },
    logosWrapper: {
        width: "100%",
        textAlign:"center",
        marginTop:20,
        marginBottom:20
    },
    logosHeader: {
        color:theme.palette.primary.dark,
        fontWeight:'bold'
    }
});

class Navigate extends Component {

    state = {
        signOut : false,
        logo : false
    };

    logout = () => {
        this.setState({
            signOut : true
        })
    };

    componentDidMount = async() => {
        const { logo } = this.props;
        this.setState({
            logo
        })
    };

    handleNavigationClick = () => {
        this.props.handleNavigationToggle();
    };

    setSignoutBoxDefault = () => {
        this.setState({
            signOut: false
        })
    };

    render() {

        const { classes, location, user } = this.props;
        let { logo } = this.state;

        if(logo) {
            logo = (<div className={classes.logoContainer}><img className={classes.logo} src={logo} alt="" /></div>);
        }

        const path = location.pathname.replace(/^.*\/\/[^\/]+/, '');
        const links =
            <List> {
                MAINLINKS.map(LINK => {

                    let selected = false,
                        forceHighlight = false;

                    if(!LINK.access.includes(this.props.group)) {
                        return false;
                    }

                    if(LINK.route===path) {
                        selected = true;
                    }

                    let subLinks;

                    if(LINK.subs) {

                        subLinks = LINK.subs.map(SUB => {

                                let subSelected = false;
                                var slug,
                                    route = SUB.route;
                                if(route.indexOf("/*") !== -1) {
                                    route = route.replace("/*", "");
                                    slug = path.substr(path.lastIndexOf('/') + 1);
                                    slug = path.replace('/' + slug, '');
                                } else {
                                    slug = path;
                                }

                                if (slug === route) {
                                    subSelected = true;
                                }

                                if (
                                    SUB.display !== 'hidden'
                                ) {

                                    return (
                                        <ListItem
                                            onClick={() => this.handleNavigationClick()}
                                            key={SUB.id}
                                            component={NavLink}
                                            to={SUB.route}
                                            selected={subSelected}
                                            button
                                        >
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography type="body2">
                                                        &nbsp;-&nbsp;
                                                        {SUB.label}
                                                    </Typography>}
                                            />
                                        </ListItem>
                                    )
                                } else {
                                    if(SUB.highlight && subSelected) {
                                        forceHighlight = SUB.highlight;
                                    }
                                }

                                return false;
                            }
                        )
                    }

                    if(forceHighlight===LINK.route) {
                        selected = true;
                    }

                    if(LINK.component==='ActivatePortal') {
                        if(!Utils.isDemo(user)) {
                            return false;
                        }
                    }
                    return (
                        <div key={LINK.id}>
                            <ListItem
                                onClick={() => this.handleNavigationClick()}
                                component={NavLink}
                                to={LINK.route}
                                selected={selected}
                                button
                            >
                                <ListItemText>{LINK.label}</ListItemText>
                            </ListItem>
                            {subLinks}
                            <Divider />
                        </div>
                    )
                })
            }
            <ListItem
                onClick={() => this.logout()}
                button
            >
                <ListItemText>
                    Sign out
                </ListItemText>
            </ListItem>
            </List>;

            let logos = (<div className={classes.logosWrapper}>
                <Typography type="body2" className={classes.logosHeader}>
                    Now partnered with
                </Typography>
                <img src="/images/printcom.png" alt="" />
            </div>);
            logos = false;

        return(
            <nav className={classes.drawer}>
                <SignOutConfirm open={this.state.signOut} setSignoutBoxDefault={this.setSignoutBoxDefault} />
                <Hidden mdUp implementation="css">
                    <Drawer
                        container={this.props.container}
                        variant="temporary"
                        anchor="left"
                        open={this.props.mobileOpen}
                        onClose={this.props.handleNavigationToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div>
                            {logo}
                            <Divider />
                            {links}
                            <Divider />
                            {logos}
                        </div>
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <div>
                            {logo}
                            <Divider />
                            {links}
                            <Divider />
                            {logos}
                        </div>
                    </Drawer>
                </Hidden>
                <Foot />
            </nav>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Navigate);