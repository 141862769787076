import React, { Component } from 'react';
import IconButton from "@material-ui/core/IconButton"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import Popper from '@material-ui/core/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    popper: {
        zIndex:9999
    },
    popperContent:{
        padding: 20,
        maxWidth:400,
        maxHeight:600,
        overflowY:'scroll'
    }
});

class UserWidget extends Component {
    handleClickAway = (popupState) => {
        popupState.close();
    };

    render() {
        const { classes, email, client } = this.props;

        return(
            <div>
                <PopupState variant="popper" popupId="user-popover">
                    {popupState => (
                        <div>
                            <IconButton color="inherit" variant="contained" {...bindToggle(popupState)}>
                                <AccountCircleIcon />
                            </IconButton>
                            <Popper {...bindPopper(popupState)}
                                    className={classes.popper}
                                    transition
                                    modifiers={{
                                        flip: {
                                            enabled: true,
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            boundariesElement: 'scrollParent',
                                        },
                                        arrow: {
                                            enabled: true,
                                            element: "basket-popover"
                                        }
                                    }}
                            >

                                {({ TransitionProps }) => (
                                    <ClickAwayListener onClickAway={() => this.handleClickAway(popupState)}>

                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper>
                                                <Typography className={classes.popperContent}>
                                                    Logged in as - {email} ({client})
                                                </Typography>
                                            </Paper>
                                        </Fade>
                                    </ClickAwayListener>
                                )}
                            </Popper>
                        </div>
                    )}
                </PopupState>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(UserWidget);