import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import OrdersTableSmall from '../components/page/OrdersTableSmall';
import CreateBespokeProductBox from '../components/page/CreateBespokeProductBox';
import { withStyles } from '@material-ui/core/styles/index';
import AdminDashboard from "./admin/AdminDashboard";

const styles = theme => ({
    paper: {
        marginBottom: theme.spacing(1),
        flexDirection: 'column',
        height:'100%'
    },
    divider: {
        margin: `${theme.spacing(2)}px 0`,
    }
});

class Dashboard extends Component {

    basketUpdate = () => {
        this.props.basketUpdate();
    };

    render() {

        const { classes } = this.props;

        if(this.props.userGroup==='Admin') {
            return (<AdminDashboard />);
        }

        /*const products = (
            <Grid item xs={12}>
            <Paper className={classes.paper}>
            <Typography variant="h5">Recently ordered products coming soon...</Typography>
            </Paper>
            </Grid>
        );*/
        const products = false;

        /* <Grid item md={9} sm={12}> */
        /* <Grid item md={3} sm={12}> */
        return(
            <div>
                <Grid container spacing={2}>
                    <Grid item md={9} sm={9}>
                        <Paper>
                            <OrdersTableSmall basketUpdate={this.basketUpdate} userGroup={this.props.userGroup} user={this.props.user} />
                        </Paper>
                    </Grid>

                    <Grid item md={3} sm={3}>
                        <Paper className={classes.paper}>
                            <CreateBespokeProductBox />
                        </Paper>
                    </Grid>

                    {products}
                </Grid>
            </div>
        )
    }
}


export default withStyles(styles)(Dashboard);