import React, { Component } from 'react';
import {Route, Switch} from "react-router-dom"
import compose from 'recompose/compose';
import Amplify from 'aws-amplify';
import { Greetings, SignIn } from "aws-amplify-react";
import { Auth } from 'aws-amplify';
import awsmobile from '../aws-exports';
import CustomSignIn from "./SignIn";
import { Authenticator } from "aws-amplify-react/dist/Auth";
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline/index';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles/index';
import withWidth from '@material-ui/core/withWidth/index';
import Navigate from './Navigate';
import Bar from './Bar';
import Utils from "../utils";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dashboard from "../pages/Dashboard";
import Catalogue from "../pages/Catalogue";
import Orders from "../pages/Orders";
import OrderPlaced from "../pages/OrderPlaced";
import RequestBespokeProduct from "../pages/RequestBespokeProduct";
import Register from "../pages/Register";
import ActivatePortal from "../pages/ActivatePortal";

import ProductList from "../pages/admin/ProductList";
import ProductAdd from "../pages/admin/ProductAdd";
import ClientList from "../pages/admin/ClientList";
import ClientAdd from "../pages/admin/ClientAdd";
import UserList from "../pages/admin/UserList";
import UserAdd from "../pages/admin/UserAdd";

import MAINLINKS from "../data/MAINLINKS";
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import SnackbarContent from "@material-ui/core/SnackbarContent";

Amplify.configure(awsmobile);

const styles = theme => ({
    root: {
        display: 'flex',
        maxWidth: 1600,
        //width:1600,
        flex:1,
        height:'100%',
       // background:'#efefef'
    },
    main: {
        height:'100%'
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        flex: 1,
        flexDirection:'column',
        padding: theme.spacing(3),
        height:'100%',
       // background:"url(\"/images/background.png\")"
    },
    snackBar: {
        backgroundColor:'#ffa000',
        marginBottom:15,
        width: '100%',
        maxWidth: '100%',
        padding:'7px 15px'
    }
});

class App extends Component {

    state = {
        loaded: false,
        mobileOpen: false,
        title: '',
        loggedIn:false,
        group: false,
        email: false,
        jwtToken: false
    };

    async componentWillMount() {
        const session = await Auth.currentSession();
        const userObj = Utils.getUserByToken(session.accessToken.jwtToken);
        let user = {};

        if(session.accessToken.payload['cognito:groups'][0]!=='Admin') {
            let userDb = await Utils.getUserDB(userObj.username);
            userDb = userDb.Items[0];
            userDb['ClientName'] = await Utils.getClientNameById(userDb.Client);
            userDb['ClientTheme'] = await Utils.getClientTheme(userDb.Client);
            if (!userDb['ClientTheme'][0].LogoDefault) {
                userDb['ClientTheme'][0].LogoDefault = '/images/tpplogo.png';
            } else {
                userDb['ClientTheme'][0].LogoDefault = await Utils.getMedia(userDb['ClientTheme'][0].LogoDefault);
            }

            user = userDb;

        } else {
            user = {
                'Client' : 'Admin',
                'ClientName' : 'Admin',
                'ClientTheme' : [{ 'LogoDefault' : '/images/tpplogo.png' }]
            }
        }

        this.setState({
            loggedIn: true,
            group: session.accessToken.payload['cognito:groups'][0],
            email: session.idToken.payload.email,
            token: session.accessToken.jwtToken,
            client: user.ClientName,
            loaded: true,
            user
        });

        if(this.state.group==='Admin') {
           // return this.props.history.push('/products');
        }
    }

    componentDidMount() {
        this.setTitle();
        this.props.history.listen((location, action) => {
            this.setTitle();
        });

        const classes =
            window.location.pathname.split('/');

        var root =
            document.getElementsByTagName( 'html' )[0];

        if(Array.isArray(classes) && classes.length) {
            for(let i=0; i<classes.length;i++) {
                if(classes[i]!=='') {
                    root.classList.add(classes[i]);
                    document.body.classList.add(classes[i]);
                }
            }

        }
    }

    setTitle = () => {
        const { location } = this.props.history;
        const parentSlug = location.pathname.replace(/^.*\/\/[^\/]+/, '');

        MAINLINKS.forEach(function(l) {
            if(parentSlug===l.route) {
                this.setState({title:l.title});
            }

            if(l.subs) {
                l.subs.forEach(function(s) {
                    var slug,
                        route = s.route;
                    if(route.indexOf("/*") !== -1) {
                        route = route.replace("/*", "");
                        slug = parentSlug.substr(parentSlug.lastIndexOf('/') + 1);
                        slug = parentSlug.replace('/' + slug, '');
                    } else {
                        slug = parentSlug;
                    }

                    if(slug===route) {
                        this.setState({title: s.title});
                    }
                }.bind(this));
            }
        }.bind(this));

    };

    handleNavigationToggle = () => {
        if(this.props.width==='sm' || this.props.width==='xs') {
            this.setState(state => ({mobileOpen: !state.mobileOpen}));
        }
    };

    basketUpdate = () => {
        this.forceUpdate();
    };

    render() {
        const { classes } = this.props;
        const { user } = this.state;

        let theme = createMuiTheme(Utils.setTheme('Admin'));
        let demo = false;
        if(Utils.isDemo(user)) {
            demo = <SnackbarContent
                className={classes.snackBar}
                aria-describedby="client-snackbar"
                message={
                    <div>
                        <b>Important!</b><br />
                        This Print Portal is for demonstration purposes only and is not capable of processing orders. We are on with creating your Personal Print Portal and you will have it shortly. In the meantime, if you're yet to send over the products you currently buy and their costs or would like to add new products too, please do as soon as possible to: <Link href="mailto:info@thepersonalprintportal.com" variant="body2">info@thepersonalprintportal.com</Link>
                    </div>
                }
            />
        }

        let main =
            <MuiThemeProvider theme={theme}>
                <Route exact path="/" render={() => <Authenticator hide={[Greetings, SignIn]} amplifyConfig={awsmobile}><CustomSignIn /></Authenticator>} />
                <Route path="/register" render={() => <Register />} />
            </MuiThemeProvider>;

        if (this.state.loggedIn) {
            if (!this.state.loaded) {
                main = (<CircularProgress color="secondary"/>)
            } else {
                theme = createMuiTheme(Utils.setTheme(user.ClientTheme));
                main =
                    <MuiThemeProvider theme={theme}>
                        <div className={classes.root}>
                            <CssBaseline/>
                            <Bar
                                handleNavigationToggle={this.handleNavigationToggle}
                                title={this.state.title}
                                logo={user.ClientTheme[0].LogoDefault}
                                email={this.state.email}
                                client={this.state.client}
                                user={this.state.user}
                            />
                            <Navigate
                                group={this.state.group}
                                location={this.props.location}
                                logo={user.ClientTheme[0].LogoDefault}
                                handleNavigationToggle={this.handleNavigationToggle}
                                mobileOpen={this.state.mobileOpen}
                                user={this.state.user}
                            />
                            <main className={classes.content}>
                                <div className={classes.toolbar}/>
                                <Route component={ScrollToTop} />
                                {demo}
                                <Switch>
                                    <Route exact path="/"
                                           render={() => <Dashboard basketUpdate={this.basketUpdate} user={this.state.user} userGroup={this.state.group}/>}/>
                                    <Route path="/catalogue"
                                           render={() => <Catalogue basketUpdate={this.basketUpdate} user={this.state.user} userGroup={this.state.group}/>}/>
                                    <Route path="/orders"
                                           render={() => <Orders basketUpdate={this.basketUpdate} user={this.state.user} userGroup={this.state.group}/>}/>
                                    <Route path="/products" render={() => <ProductList user={this.state.user} userGroup={this.state.group}/>}/>
                                    <Route path="/product/bespoke"
                                           render={() => <RequestBespokeProduct user={this.state.user}  userGroup={this.state.group}/>}/>
                                    <Route path="/product/add" render={() => <ProductAdd type="add" jwtToken={this.state.jwtToken}
                                                                                         userGroup={this.state.group}/>}/>
                                    <Route path="/product/edit/:ID"
                                           render={() => <ProductAdd type="edit" jwtToken={this.state.jwtToken}
                                                                     userGroup={this.state.group}/>}/>
                                    <Route path="/clients" render={() => <ClientList userGroup={this.state.group} user={this.state.user}/>}/>
                                    <Route path="/client/add" render={() => <ClientAdd type='add' userGroup={this.state.group}/>}/>
                                    <Route path="/client/edit/:ID"
                                           render={() => <ClientAdd type="edit" userGroup={this.state.group}/>}/>
                                    <Route path="/users" render={() => <UserList token={this.state.token} user={this.state.user}
                                                                                 userGroup={this.state.group}/>}/>
                                    <Route path="/user/add"
                                           render={() => <UserAdd token={this.state.token} userGroup={this.state.group}/>}/>
                                    <Route path="/user/edit/:ID" render={() => <UserAdd token={this.state.token} type="edit"
                                                                                        userGroup={this.state.group}/>}/>
                                    <Route path="/order-placed" render={() => <OrderPlaced text="Your order has<br /> been placed" userGroup={this.state.group}/>}/>
                                    <Route path="/bespoke-request-placed" render={() => <OrderPlaced text="Your request has<br /> been placed" userGroup={this.state.group}/>}/>
                                    <Route path="/product-enquiry-placed" render={() => <OrderPlaced text="Your product enquiry has<br /> been sent" userGroup={this.state.group}/>}/>
                                    {(Utils.isDemo(user) ?
                                        <Route path="/activate" render={() => <ActivatePortal user={this.state.user} />}/>
                                        : '')}
                                </Switch>
                            </main>
                        </div>
                    </MuiThemeProvider>
            }
        }
        return (
            <div id="main">{main}</div>
        );
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
};

const ScrollToTop = () => {
    window.scrollTo(0, 0);
    return null;
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withWidth()
)(App);

