import React from 'react';
import validator from 'validator';
import Typography from '@material-ui/core/Typography';
import PRODUCTS from './data/PRODUCTS';
import { API, Storage, Cache, Auth } from 'aws-amplify';
import uuid from "uuid";

export default class Utils {
    static getProductById = (productId) => {
        let found = false;

        PRODUCTS.forEach(function(PRODUCT) {
            if(productId===PRODUCT.id) {
                found = PRODUCT;
            }
        });

        return found;
    };

    static getProductByIdDb =  async (id) => {
        const product = await API.get("PrintPortalAPI", '/products/' + id, {
            headers: {}
        });

        return product[0];
    };

    static getMediaForProduct = (product) => {
        let files = [];
        if(product.Thumb) {
            files.push(product.Thumb);
        }
        if(product.Files) {
            product.Files.fileKeys.forEach(function(key) {
                files.push(key);
            })
        }
        if(product.Gallery) {
            product.Gallery.imageKeys.forEach(function(key) {
                files.push(key);
            })
        }

        return files;
    };

    static processForm = (formObj) => {
        let data = Object.assign({}, formObj);
        let success = true;

        Object.keys(data).forEach(function (fieldName) {
            const { id, field, value, required } = data[fieldName];
            if(field==='email') {
                if(!Utils.validateEmail(value)) {
                    data[id].error = true;
                    data[id].errorMsg = 'Invalid email';
                    success = false;
                } else {
                    data[id].error = false;
                    data[id].errorMsg = false;
                }
            }

            if(fieldName==='Quantity') {
                if(parseInt(data[fieldName])<parseInt(data['minQtyAmt'])) {
                    data['QuantityError'] = 'This product requires a minimum quantity of ' +data['minQtyAmt']
                    success = false;
                } else {
                    data['QuantityError'] = false;
                }
                data['Quantity'] = data['Quantity'].toString()
            }

            if(
                fieldName==='loading' ||
                fieldName==='preview' ||
                fieldName==='showHiddenAttributes'
            ) {
                delete data[fieldName];
            }


            if(required) {
                if(!value) {
                    data[id].error = true;
                    data[id].errorMsg = 'Required';
                    success = false;
                } else {
                    data[id].error = false;
                    data[id].errorMsg = false;
                }
            }

            if(validator.isUUID(fieldName)) {
                if(!data['Attributes']) {
                    data['Attributes'] = [];
                }

                    data['Attributes'].push({
                        attID: data[fieldName].id,
                        label: data[fieldName].label,
                        value: ((typeof data[fieldName].value === 'undefined' || data[fieldName].value==='') ? false : data[fieldName].value)
                    });


                /*formObj['Attributes'] = Array.from(new Set(formObj['Attributes'].map(a => a.attID)))
                    .map(id => {
                        return formObj['Attributes'].find(a => a.attID === id)
                    });*/
                delete data[fieldName];
            }

        });

        return {
            formObj:data,
            success
        }
    };

    static async processBasketData(formData) {
        let data = Object.assign({}, formData);

        data['ID'] = uuid.v4();

        const userAuth = await Auth.currentAuthenticatedUser({
            bypassCache: false
        });

        const userData = await Utils.getUserDB(userAuth.username);
        data['userId'] = userData.Items[0].ID;
        data['Username'] = userData.Items[0].Username;
        data['Client'] = userData.Items[0].Client;

        for (var property in data) {
            if (data.hasOwnProperty(property)) {
                if(validator.isUUID(property)) {
                    delete data[property];
                }

                if(
                    property==='minQtyAmt' ||
                    property==='QuantityError' ||
                    property==='Cost' ||
                    property==='Selling'
                ) {
                    delete data[property]
                }
            }
        }

        return data;
    }

    static validateEmail = (email) => {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    static generateRandomString = (count) => {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < count; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return '!@#' + text;
    };

    static accessDenied() {
        return (<Typography>Access Denied</Typography>);
    }

    static async insertMedia(e) {
        const   file = e.target.files[0];
        let     filename = file.name;

        const getFileName = async(filename) => {
            const res = await Storage.list(filename, {
                level: 'public'
            });

            if(res.length>0) {
                return true;
            }

            return false;
        };

        for(let i = 0; i<999; i++) {
            let file = filename + '-' + i;
            if(!await getFileName(file)) {
                filename = file;
                break;
            }
        }

        return await Storage.put(filename, file, {
            level: 'public',
            contentDisposition: '',
            contentType: file.type
        });
    }

    static async deleteMedia(items) {
        if(typeof items==='string') {
            items = [items];
        }

        await items.forEach(function(item) {
            Storage.remove(item, {
                level: 'public'
            });
        });

        return true;
    }

    static async getClientNameById(id) {
        let name = Cache.getItem('client-' + id);
        if(!name) {
            const client = await API.get("PrintPortalAPI", '/clients/' + id, {
                headers: {}
            });

            Cache.setItem('client-' + id, client[0].Name);

            return client[0].Name;
        }

        return name;
    }

    static async getClientTheme(client) {
        return await API.get("PrintPortalAPI", '/clients/' + client, {
            headers: {}
        });
    }

    static getUserByToken(token) {
        var base64Url = token.split('.')[1];
        var base64 = decodeURIComponent(atob(base64Url).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(base64);
    }

    static async getUserDB(Username) {
        return await API.post("PrintPortalAPI", '/users/getby/username', {
            headers: {},
            body:{
                Username
            }
        });
    }

    static async getMedia(key) {
        try {
            return Storage.get(key, { level : 'public' });
        } catch(e) {
            console.log(e);
        }
        return false;
    }

    static getNiceDate(date) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        function nth(d) {
            if (d > 3 && d < 21) return 'th';
            switch (d % 10) {
                case 1:  return "st";
                case 2:  return "nd";
                case 3:  return "rd";
                default: return "th";
            }
        }

        let d = new Date(date);
        return d.getDate() + nth(d.getDate()) + ' ' + monthNames[d.getMonth()] + ' ' + d.getFullYear() + '\t' + ("0" + d.getHours()).slice(-2) + ':' + d.getMinutes() + ':' + d.getSeconds()
    }

    static sortByKey(array, key, direction) {
        array.sort(function(a, b) {
            var x = a[key];
            var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });

        if(direction==='ASC') {
            array.reverse();
        }

        return array;
    }

    static pruneReference(reference) {
        let parts = reference.split('-');
        return parts[0];
    }

    static setTheme(DBtheme) {

        let primaryColourHex = Utils.rgbToHex(37, 65, 88);
        let primaryColour = Utils.hexToRgb(primaryColourHex),
            primaryColourL = Utils.hexToRgb(Utils.shadeColor(primaryColourHex, 20)),
            primaryColourD = Utils.hexToRgb(Utils.shadeColor(primaryColourHex, -20));

        primaryColour = "rgba("+primaryColour.r+","+primaryColour.g+","+primaryColour.b+",1)";
        primaryColourL = "rgba("+primaryColourL.r+","+primaryColourL.g+","+primaryColourL.b+",1)";
        primaryColourD = "rgba("+primaryColourD.r+","+primaryColourD.g+","+primaryColourD.b+",1)";

        let primary = {
            "light":primaryColourL,
            "main":primaryColour,
            "dark":primaryColourD,
            "contrastText":"#fff"
        };

        // SECONDARY
        let secondaryColourHex = Utils.rgbToHex(243, 54, 76);
        let secondaryColour = Utils.hexToRgb(secondaryColourHex),
            secondaryColourL = Utils.hexToRgb(Utils.shadeColor(secondaryColourHex, 20)),
            secondaryColourD = Utils.hexToRgb(Utils.shadeColor(secondaryColourHex, -20));

        secondaryColour = "rgba(" + secondaryColour.r + "," + secondaryColour.g + "," + secondaryColour.b + ",1)";
        secondaryColourL = "rgba(" + primaryColourL.r + "," + secondaryColourL.g + "," + secondaryColourL.b + ",1)";
        secondaryColourD = "rgba(" + primaryColourD.r + "," + secondaryColourD.g + "," + secondaryColourD.b + ",1)";

        let secondary = {
            "light": secondaryColourL,
            "main": secondaryColour,
            "dark": secondaryColourD,
            "contrastText": "#fff"
        };

        if(DBtheme!=='Admin') {

            DBtheme = DBtheme[0];

            if (typeof DBtheme.BrandingColourPrimary !== "undefined") {
                let primaryColour = Utils.hexToRgb(DBtheme.BrandingColourPrimary),
                    primaryColourL = Utils.hexToRgb(Utils.shadeColor(DBtheme.BrandingColourPrimary, 20)),
                    primaryColourD = Utils.hexToRgb(Utils.shadeColor(DBtheme.BrandingColourPrimary, -20));

                primaryColour = "rgba(" + primaryColour.r + "," + primaryColour.g + "," + primaryColour.b + ",1)";
                primaryColourL = "rgba(" + primaryColourL.r + "," + primaryColourL.g + "," + primaryColourL.b + ",1)";
                primaryColourD = "rgba(" + primaryColourD.r + "," + primaryColourD.g + "," + primaryColourD.b + ",1)";

                primary = {
                    "light": primaryColourL,
                    "main": primaryColour,
                    "dark": primaryColourD,
                    "contrastText": "#fff"
                };
            }


            if (typeof DBtheme.BrandingColourSecondary !== "undefined") {
                let secondaryColour = Utils.hexToRgb(DBtheme.BrandingColourSecondary),
                    secondaryColourL = Utils.hexToRgb(Utils.shadeColor(DBtheme.BrandingColourSecondary, 20)),
                    secondaryColourD = Utils.hexToRgb(Utils.shadeColor(DBtheme.BrandingColourSecondary, -20));

                secondaryColour = "rgba(" + secondaryColour.r + "," + secondaryColour.g + "," + secondaryColour.b + ",1)";
                secondaryColourL = "rgba(" + secondaryColourL.r + "," + secondaryColourL.g + "," + secondaryColourL.b + ",1)";
                secondaryColourD = "rgba(" + secondaryColourD.r + "," + secondaryColourD.g + "," + secondaryColourD.b + ",1)";

                secondary = {
                    "light": secondaryColourL,
                    "main": secondaryColour,
                    "dark": secondaryColourD,
                    "contrastText": "#fff"
                }
            }
        }

        return {
            "typography": {
                useNextVariants: true,
                fontFamily:[
                    'Nunito','sans-serif'
                ]
            },
            "palette":{
                "common":{
                    "black":"#000",
                    "white":"#fff"
                },
                "background":{
                    "paper":"#fff",
                    "default":"#fafafa"
                },
                primary,
                secondary,
                "error":{
                    "light":"#e57373",
                    "main":"#f44336",
                    "dark":"#d32f2f",
                    "contrastText":"#fff"
                },
                "text":{
                    "primary":"rgba(0, 0, 0, 0.87)",
                    "secondary":"rgba(0, 0, 0, 0.54)",
                    "disabled":"rgba(0, 0, 0, 0.38)",
                    "hint":"rgba(0, 0, 0, 0.38)"
                }
            },
            "logos": {
                "default" : "",
                "inverted": ""
            }
        }
    }

    static LightenDarkenColor(col, amt) {

        var usePound = false;

        if (col[0] === "#") {
            col = col.slice(1);
            usePound = true;
        }

        var num = parseInt(col,16);

        var r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if  (r < 0) r = 0;

        var b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if  (b < 0) b = 0;

        var g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);

    }

    static shadeColor(color, percent) {

        var R = parseInt(color.substring(1,3),16);
        var G = parseInt(color.substring(3,5),16);
        var B = parseInt(color.substring(5,7),16);

        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);

        R = (R<255)?R:255;
        G = (G<255)?G:255;
        B = (B<255)?B:255;

        var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
        var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
        var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));

        return "#"+RR+GG+BB;
    }

    static hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }


    static rgbToHex(r, g, b) {
        function componentToHex(c) {
            var hex = c.toString(16);
            return hex.length === 1 ? "0" + hex : hex;
        }
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    static filterObjectForDB(object) {
        if(typeof object === 'object')  {
            for (var propName in object) {
                if (object[propName] === null || object[propName] === undefined || object[propName] === '') {
                    delete object[propName];
                }
            }

            return object;
        }

        return false;
    }

    static checkIfAttHidden = (attVal) => {
        if(attVal && attVal.includes('!##!')) {
            return true;
        }
        return false;
    }

    static stripHiddenPrefix = (value) => {
        if(value.includes('!##!')) {
            return value.replace('!##!', '');
        }

        return false;
    }

    static isDemo = (user) => {
        if(user && user.Demo) {
            return true;
        }

        return false;
    }

    static reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    static formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {

        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    };

    /*static getArrayItemByObjectKeyVal(array, key, val) {
        return array.filter(obj => {
            return obj[key] === val
        });
    }

    static setNewKeyValue(array, key, val) {
        return array.filter(obj => {
            return obj[key].value = val
        });
    }*/

    static poundsToPence(value){
        return Math.round(100 * parseFloat(value));
    }

    static penceToPounds(value) {
        return value / 100;
    }

    static getPercentageDiff(a,b) {
        return String(Math.round((1 -(a/b)) * 100)).replace('-', '');
    }

    static getGenericClientId() {
        return '55b7b572-8914-4cda-95cb-4fb459b565fa';
    }
}