import React, { Component } from "react"
import { withStyles } from '@material-ui/core/styles/index';
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Utils from "../utils";
import DeleteIcon from "@material-ui/icons/Delete";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";


const styles = theme => ({
    imageThumbContainer : {
        height: 200
    },
    imageThumb : {
        maxHeight : "100%",
        maxWidth: "100%"
    },
    selected : {
        backgroundColor: theme.palette.secondary.main,
        color:'white'
    }
});

class ProductImages extends Component {
    state = {
        imageKeys : [],
        imageVals : [],
        loading: false,
        confirmRemoveImg: false,
        removeImageKey: false,
        thumb: false
    };

    componentWillReceiveProps = async () => {

        if(this.props.value) {
            this.setState({loading:true});
            const { imageKeys } = this.props.value;

            let imageVals = [];
            await Promise.all(imageKeys.map(async (imageKey) => {
                let val = await Utils.getMedia(imageKey);
                imageVals.push(val);
            }));

            this.setState({
                imageKeys,
                imageVals,
                loading:false
            });
        }
    };

    componentDidMount = async () => {
        if(this.props.value) {
            const { imageKeys } = this.props.value;
            const { theThumb } = this.props;

            let imageVals = [];
            await Promise.all(imageKeys.map(async (imageKey) => {
                let val = await Utils.getMedia(imageKey);
                imageVals.push(val);
            }));

            this.setState({
                imageKeys,
                imageVals,
                loading:false,
                thumb:theThumb
            });
        }
    };


    handleImageAdd = async (e) => {
        this.setState({loading:true});
        const { imageKeys, imageVals } = this.state;
        const mediaObject = await Utils.insertMedia(e);
        let key = mediaObject.key;
        imageKeys.push(key);
        let val = await Utils.getMedia(key);
        imageVals.push(val);
        this.setState({
            imageKeys,
            imageVals,
            loading: false
        });
        this.props.onChange({
            imageVals,
            imageKeys
        });
    };


    handleImageRemove = () => {
        const { imageKeys, imageVals, removeImageKey } = this.state;

        for(let i = 0; i<imageKeys.length; i++) {
            if(removeImageKey===imageKeys[i]) {
                imageKeys.splice(i,1);
                imageVals.splice(i,1);
            }
        }

        this.setState({
            imageKeys,
            imageVals,
            removeImageKey : false,
            confirmRemoveImg: false
        })
    };

    setGalleryImageAsThumb = (thumb) => {
       this.setState({thumb});
       this.props.setThumb("Thumb", thumb);
    };

    openDialogBox = (key) => {
        this.setState({
            confirmRemoveImg: true,
            removeImageKey : key
        });
    };

    closeDialogBox = () => {
        this.setState({
            confirmRemoveImg: false,
            removeImageKey : false
        });
    };

    render() {
        const { imageVals, imageKeys, loading, thumb } = this.state;
        const { classes, theThumb } = this.props;

        let images = false;
        if(imageVals.length>0) {
            let i=0;
            images = imageVals.map((src) => {
                let key = imageKeys[i];
                let isThumbClass = '';
                if(key===thumb) {
                    isThumbClass = classes.selected;
                }
                i++;
                return (
                    <Grid key={i} item md={6} sm={2}>
                        <Card className={classes.card}>
                            <CardContent className={classes.imageThumbContainer} >
                                <img className={classes.imageThumb} src={src} alt="" />
                            </CardContent>
                            <CardActions>
                                <Button
                                    className={isThumbClass}
                                    size="small"
                                    onClick={(e) => this.setGalleryImageAsThumb(key)}
                                ><AddToPhotosIcon /> Set thumb</Button>
                                <Button
                                    size="small"
                                    onClick={(e) => this.openDialogBox(key)}
                                ><DeleteIcon /> Remove</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                );
            });
        }

        let wait = false;
        if(loading) {
            wait = <CircularProgress color="secondary" />
        }

        return(
            <div>
                <Grid  container spacing={2}>
                    {images}
                    {wait}
                </Grid>
                <br />
                <br />
                <div>
                    <InputLabel shrink>
                        Add Image
                    </InputLabel>
                    <br />
                </div>
                <input
                    accept="image/*"
                    id="Thumb"
                    type="file"
                    onChange={(e) => this.handleImageAdd(e)}
                />

                <Dialog
                    open={this.state.confirmRemoveImg}
                    onClose={this.closeDialogBox}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Remove image?</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.closeDialogBox} color="primary">
                            No
                        </Button>
                        <Button onClick={this.handleImageRemove} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )

    }
}

export default withStyles(styles, { withTheme: true })(ProductImages);