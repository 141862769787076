import React, { useState, useEffect } from 'react';

import { withStyles } from '@material-ui/core/styles/index';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { API } from 'aws-amplify';

import Utils from "../utils";
import ClientSelector from '../components/page/ClientSelector';
import ProductBox from '../components/page/ProductBox';

//  ------------------------------------------------------------------------------------------------------------------------------------------

const styles = (theme) => ({
    paper: {
        marginBottom: theme.spacing(1),
        flexDirection: 'column',
        height: '100%'
    },
    tabsRoot: {
        borderBottom: '1px solid ' + theme.palette.secondary.main
    },
    tabsIndicator: {
        backgroundColor: theme.palette.secondary.main,
        color:"white"
    },
    tabSelected: {},
    moreResults: {
        margin:"10px 0",
        textAlign:"center"
    }
});

//  ------------------------------------------------------------------------------------------------------------------------------------------

function Catalogue(props) {

   const genericClient = Utils.getGenericClientId();

   return (
      <div> {
         ( props.userGroup === 'Admin' ) ? (
            <ClientSelector render={(client) => <ProductList client={client} enquireOnly={false} {...props} />} />
         ) : ( props.user.Client !== genericClient ) ? (
            <CategorySelector genericClient={genericClient} {...props} />
         ) : (
            <ProductList client={genericClient} enquireOnly={false} {...props} />
         )
      } </div>
   );

}

//  ------------------------------------------------------------------------------------------------------------------------------------------

function CategorySelector(props) {

   const { genericClient, classes, user } = props;

   const [ client, setClient ] = useState(user.Client);

   return (
      <>
         <Grid>
            <Grid item md={6} sm={6} xs={6}>
               <Tabs value={client} onChange={(event, selected) => setClient(selected)} >
                  <Tab disableRipple classes={{selected: classes.tabSelected}} label={user.ClientName} value={user.Client} />
                  <Tab disableRipple classes={{selected: classes.tabSelected}} label="Generic"         value={genericClient} />
               </Tabs>
               <br/>
            </Grid>
         </Grid>
         <ProductList client={client} enquireOnly={(client === genericClient)} {...props} />
      </>
   );

}

//  ------------------------------------------------------------------------------------------------------------------------------------------

const INITIAL_STATE = {
   loading          : 0,
   client           : null,
   products         : null,
   lastEvaluatedKey : false
};

function ProductList(props) {

   const [ state, setState ] = useState(INITIAL_STATE);

   const [ loadMore, setLoadMore ] = useState(false);

   function updateState(loading, client, products, lastEvaluatedKey) {
      setState({ loading, client, products, lastEvaluatedKey });
   }

   function triggerProductLoad() {
      setLoadMore(!loadMore);
   }

   useEffect(() => {
      if ( state.loading === 0 ) {
         let loading = 1;
         let { client, products, lastEvaluatedKey } = state
         if ( props.client !== client ) {
            loading          = 2;
            client           = props.client;
            products         = [ ];
            lastEvaluatedKey = false;
         }
         updateState(loading, client, products, lastEvaluatedKey);
         API.post("PrintPortalAPI", "/products/client", {
            headers : { },
            body : {
               client,
               lastEvaluatedKey
            },
            response : false
         }).then(async (response) => {
            for ( let i = 0 ; i < response.data.length ; i++ ) {
               const product = response.data[i];
               product.Gallery = product.Gallery ? await Promise.all(product.Gallery.imageKeys.filter((k) => k !== product.Thumb).map((k) => Utils.getMedia(k))) : [ ];
               if ( product.Thumb ) {
                  const thumb = await Utils.getMedia(product.Thumb)
                  product.Thumb = thumb;
                  product.Gallery.unshift(thumb);
               }
            }
            updateState(0, client, products.concat(response.data), (response.lastEvaluatedKey || false));
         }).catch((error) => {
            console.log(error);
         })
      }
   }, [ props.client, loadMore ]);

   const { classes } = props;

   return ( state.loading === 2 || state.products === null ) ? (
      <CircularProgress color="secondary"/>
   ) : ( state.products.length === 0 ) ? (
      <Typography variant="h4" gutterBottom>No products in database</Typography>
   ) : (
      <>
         <Grid container spacing={2}> {
            state.products.map((product) => {
               return (
                  <Grid key={product.ID} item xs={3} sm={3} md={3}>
                     <Paper className={classes.paper}>
                        <ProductBox basketUpdate={props.basketUpdate}
                                    user={props.user}
                                    data={product}
                                    enquireOnly={props.enquireOnly} />
                     </Paper>
                  </Grid>
               );
            })
         } </Grid> {
            ( state.lastEvaluatedKey ) ? (
               <div className={ classes.moreResults }> {
                  ( state.loading === 1 ) ? (
                     <CircularProgress color="secondary"/>
                  ) : (
                     <Button color="primary" onClick={(ev) => triggerProductLoad()}>Get More Results<br /><ExpandMoreIcon /></Button>
                  )
               } </div>
            ) : (
               <div className={ classes.moreResults }><Typography>All Results Loaded</Typography></div>
            )
         }
      </>
   );

}

//  ------------------------------------------------------------------------------------------------------------------------------------------

export default withStyles(styles, { withTheme: true })(Catalogue);

