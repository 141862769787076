import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import SideContent from "./SideContent";
import OrderDetails from "./OrderDetails";
import { API } from 'aws-amplify';
import Utils from '../../utils';


// Utils
// import Utils from "../../utils";

const styles = theme => ({
    tableWrapper: {
        overflowX: 'auto',
        padding:15
    },
    table: {
        width: '100%'
    },
    tableFirstCell: {
        paddingLeft:0
    },
    tableLastCell: {
        paddingRight:"0 !important"
    },
    tableHeading: {
        paddingTop:15,
        paddingBottom:15,
        display:'flex',
        justifyContent: 'space-between'
    },
    tableTitle: {
        justifyContent: 'left'
    },
    viewAllOrdersButton: {
        justifyContent: 'right'
    },
    button: {
        justifyContent:'right'
    }
});

class OrdersTableSmall extends Component {

    apiPath = '/orders';
    state = {
        selectedOrderId: false,
        openSidebarContent: false,
        content: '<div />',
        orders: false
    };

    basketUpdate = () => {
        this.props.basketUpdate();
    };

    componentDidMount = async () => {
        const { user } = this.props;
        try {
            let orders;
            orders = await API.get("PrintPortalAPI", this.apiPath + '/client/limit/' + user.Client, {
                headers: {},
            });

            this.setState({orders:orders.data});
        } catch(e) {
            console.log(e);
        }
    };

    gotoOrders = () => {
        this.props.history.push("/orders");
    };

    viewOrder = (order) => {
        this.setState({
            selectedOrderId: order.orderId,
            openSidebarContent: true,
            content:<OrderDetails closeOrderDetails={this.closeOrderDetails} basketUpdate={this.basketUpdate} user={this.props.user} userGroup={this.props.userGroup} order={order} />
        });
    };

    closeOrderDetails = () => {
        this.setState({
            openSidebarContent: false
        })
    }

    render() {
        const { classes } = this.props;
        const ORDERS = this.state.orders;

        let ordersTable = (<CircularProgress color="secondary"/>);
        if(ORDERS) {
            ordersTable =
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableFirstCell}>Order Ref</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Products</TableCell>
                            <TableCell className={classes.tableLastCell}>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            ORDERS.map(ORDER => {
                                //const product = Utils.getProductById(ORDER.Products[0].productID);

                                return (
                                    <TableRow key={ORDER.Reference}>
                                        <TableCell className={classes.tableFirstCell}>{Utils.pruneReference(ORDER.Reference)}</TableCell>
                                        <TableCell>{Utils.getNiceDate(ORDER.Date)}</TableCell>
                                        <TableCell>| {
                                            ORDER.Products.map(PRODUCT => {
                                                return PRODUCT.Title + " x " + PRODUCT.Quantity + " | ";
                                            })
                                        }</TableCell>
                                        <TableCell className={classes.tableLastCell}>
                                            <div className={classes.button}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => this.viewOrder(ORDER)}
                                                >
                                                    View
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>;
        }

        return(
            <div className={classes.tableWrapper}>
                <div className={classes.tableHeading}>
                    <Typography className={classes.tableTitle} color="primary" variant="h5">
                        Orders
                    </Typography>
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.viewAllOrdersButton}
                        onClick={this.gotoOrders}
                    >
                        View all orders
                    </Button>
                </div>
                {ordersTable}
                <SideContent open={this.state.openSidebarContent} content={this.state.content} />
            </div>
        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(OrdersTableSmall));