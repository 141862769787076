import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import { withStyles } from '@material-ui/core/styles/index';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PageviewIcon from "@material-ui/icons/Pageview";

import { API } from 'aws-amplify';

import uuid from "uuid";

import Utils from "../../utils";

import ClientSelector from "../../components/page/ClientSelector";
import ProductGallery from "../../components/page/ProductGallery";
import ProductOrder from "../../components/page/ProductOrder";

import { withAdminUserGuard } from "./components/AccessDenied";

//  ------------------------------------------------------------------------------------------------------------------------------------------

const styles = (theme) => ({
   root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
   },
   tableWrapper: {
      overflowX: 'auto',
      padding:15
   },
   table: {
      width: '100%'
   },
   tableFirstCell: {
      paddingLeft:0
   },
   tableLastCell: {
      paddingRight:"0 !important"
   },
   tableHeading: {
      paddingTop:15,
      paddingBottom:15,
      display:'flex',
      justifyContent: 'space-between'
   },
   tableTitle: {
      justifyContent: 'left'
   },
   highlight: {
      background:'#FFFFE0'
   },
   moreResults: {
      margin:"10px 0",
      textAlign:"center"
   },
   previewInner: {
      padding:15
   }
});

//  ------------------------------------------------------------------------------------------------------------------------------------------

function ProductList(props) {
   return (<ClientSelector render={(client) => <ClientProductList client={client} {...props} />} />);
}

//  ------------------------------------------------------------------------------------------------------------------------------------------

const INITIAL_STATE = {
   loading          : 0,
   client           : null,
   products         : null,
   lastEvaluatedKey : false,
   dialogContent    : false
};

function ClientProductList(props) {

   const [ state, setState ] = useState(INITIAL_STATE);

   const [ loadMore, setLoadMore ] = useState(false);

   function resetState() {
      setState(INITIAL_STATE);
   }

   function updateState(loading, client, products, lastEvaluatedKey, dialogContent) {
      setState({ loading, client, products, lastEvaluatedKey, dialogContent });
   }

   function setDialogContent(dialogContent) {
      updateState(state.loading, state.client, state.products, state.lastEvaluatedKey, dialogContent);
   }

   function triggerProductLoad() {
      setLoadMore(!loadMore);
   }

   useEffect(() => {
      if ( state.loading === 0 ) {
         let loading = 1;
         let { client, products, lastEvaluatedKey } = state
         if ( props.client !== client ) {
            loading          = 2;
            client           = props.client;
            products         = [ ];
            lastEvaluatedKey = false;
         }
         updateState(loading, client, products, lastEvaluatedKey, false);
         API.post("PrintPortalAPI", "/products/client", {
            headers : { },
            body : {
               client,
               lastEvaluatedKey,
               listAll : true
            },
            response : false
         }).then((response) => {
            updateState(0, client, products.concat(response.data), (response.lastEvaluatedKey || false), false);
         }).catch((error) => {
            console.log(error);
         })
      }
   }, [ props.client, loadMore ]);

   function handleGotoProduct(event, id) {
      event.preventDefault();
      return props.history.push('/product/edit/' + id);
   }

   function handleCloseDialog() {
      setDialogContent(false);
   }

   async function handleOpenProductPreviewDialog(ref) {
      const product = Object.assign({ }, ref);
      product.Gallery = product.Gallery ? await Promise.all(product.Gallery.imageKeys.filter((k) => k !== product.Thumb).map((k) => Utils.getMedia(k))) : [ ];
      if ( product.Thumb ) {
         const thumb = await Utils.getMedia(product.Thumb)
         product.Thumb = thumb;
         product.Gallery.unshift(thumb);
      }
      const dialogContent = (
         <>
            <DialogTitle id="alert-dialog-title">Preview of: <b>{product.Title}</b></DialogTitle>
            <div>
               <ProductGallery gallery={product.Gallery} />
               <div className={classes.previewInner}>
                  <ProductOrder addToBasket={false}
                                onChange={(e) => this.onChange(e)}
                                enquireOnly={false}
                                data={product}
                                order={false}
                                user={false}
                                previewOnly={true}/>
                  <Button color="primary" onClick={() => handleCloseDialog()}>Close Preview</Button>
               </div>
            </div>
         </>
      );
      setDialogContent(dialogContent);
   }

   function handleOpenProductCloneDialog(ref) {
      const dialogContent = (
         <>
            <DialogTitle id="alert-dialog-title">Clone product <b>{ref.Title}</b>?</DialogTitle>
            <DialogActions>
               <Button onClick={() => handleCloseDialog()} color="primary">No</Button>
               <Button onClick={() => handleProductClone(ref)} color="primary" autoFocus>Yes</Button>
            </DialogActions>
         </>
      );
      setDialogContent(dialogContent);
   }

   function handleProductClone(ref) {

      ///  ---------------------------------------------------------------------------
      ///  WARNING: References the same images and other media as the original product
      ///  ---------------------------------------------------------------------------

      resetState();
      Utils.getProductByIdDb(ref.ID).then((product) => {
         product.Title = '!!! ' + product.Title;
         product.ID = uuid.v4();
         return API.put("PrintPortalAPI", "/products", {
            headers: { },
            body: product
         });
      }).then((response) => {
         triggerProductLoad();
      }).catch((error) => {
         console.log(error);
      });
   }

   function handleOpenProductDeleteDialog(ref) {
      const dialogContent = (
         <>
            <DialogTitle id="alert-dialog-title">Delete product <b>{ref.Title}</b>?</DialogTitle>
            <DialogActions>
               <Button onClick={() => handleCloseDialog()} color="primary">No</Button>
               <Button onClick={() => handleProductDelete(ref)} color="primary" autoFocus>Yes</Button>
            </DialogActions>
         </>
      );
      setDialogContent(dialogContent);
   }

   function handleProductDelete(ref) {

      ///  ------------------------------------------------------
      ///  WARNING: Does not delete product images or other media
      ///  ------------------------------------------------------

      resetState();
      API.del("PrintPortalAPI", "/products/object/" + ref.ID, {
         headers: { },
         response : false
      }).then((response) => {
         triggerProductLoad();
      }).catch((error) => {
         console.log(error);
      });
   }

   const { classes } = props;

   return ( state.loading === 2 || state.products === null ) ? (
      <CircularProgress color="secondary"/>
   ) : ( state.products.length === 0 ) ? (
      <Typography variant="h4" gutterBottom>No products in database</Typography>
   ) : (
      <Paper className={classes.root} elevation={1}>
         <Table>
             <TableHead>
                 <TableRow>
                     <TableCell className={classes.tableFirstCell}>Title</TableCell>
                     <TableCell className={classes.tableLastCell}>Actions</TableCell>
                 </TableRow>
             </TableHead>
             <TableBody>
             {
                state.products.map((product) => (
                   <TableRow key={product.ID} className={(product.Highlight ? classes.highlight : false)}>
                      <TableCell>
                         <Link href="" onClick={(e) => handleGotoProduct(e, product.ID)}>{product.Title}</Link>
                      </TableCell>
                      <TableCell>
                         <IconButton onClick={(e) => handleGotoProduct(e, product.ID)} aria-label="Edit"><EditIcon /></IconButton>
                         <IconButton onClick={()  => handleOpenProductPreviewDialog(product)} aria-label="Open Preview"><PageviewIcon /></IconButton>
                         <IconButton onClick={()  => handleOpenProductCloneDialog(product)} aria-label="Clone"><FileCopyIcon /></IconButton>
                         <IconButton onClick={()  => handleOpenProductDeleteDialog(product)} aria-label="Delete"><DeleteIcon /></IconButton>
                      </TableCell>
                   </TableRow>
                ))
             }
             </TableBody>
         </Table> 

         {
            ( state.lastEvaluatedKey ) ? (
               <div className={ classes.moreResults }> {
                  (state.loading === 1) ? (
                     <CircularProgress color="secondary"/>
                  ) : (
                     <Button color="primary" onClick={(ev) => triggerProductLoad()}>Get More Results<br /><ExpandMoreIcon /></Button>
                  )
               } </div>
            ) : (
               <div className={ classes.moreResults }><Typography>All Results Loaded</Typography></div>
            )
         }

         {
            state.dialogContent && (
               <Dialog open={true}
                       onClose={() => handleCloseDialog()}
                       aria-labelledby="alert-dialog-title"
                       aria-describedby="alert-dialog-description">
                  { state.dialogContent }
               </Dialog>
            )
         }

      </Paper>
   );

}

//  ------------------------------------------------------------------------------------------------------------------------------------------

export default withAdminUserGuard(withRouter(withStyles(styles, { withTheme: true })(ProductList)));

