import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import { API } from 'aws-amplify';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Auth } from 'aws-amplify'
const ReactGA = require('react-ga');

ReactGA.initialize('UA-223058-53', {
   // debug: true
});

const styles = theme => ({
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    root: {
        margin:0,
        padding:0
    },
    clearButton: {
        textAlign:'right',
        float:'right',
        cursor:'pointer'
    },
    terms : {
        width:'100%'
    },
    actions : {
        width:'100%'
    }
});

class Register extends Component {

    registerApiPath = "/register";

    defaultState = () => {
        return {
            error: false,
            body: {
                Name: '',
                Email: '',
                Company: '',
                Phone: '',
                Code:'',
                Terms: false
            },
            buttonText: 'Set up account',
            loading: false,
            success: false
        }
    };

    state = this.defaultState();

    registerUser = async (ev) => {
        ev.preventDefault();

        const { body } = this.state;

        if(!body.Terms) {
            const error = {
                field: "Terms",
                message: "This field is required"
            };

            this.setState({
                error : [error]
            });

            return false;
        }

        if(!body.Phone) {
            body.Phone = ' ';
        }

        this.setState({
            buttonText: <CircularProgress color="secondary"/>,
            error: false
        });

        try {
            const apiResponse = await API.put("PrintPortalAPI", this.registerApiPath, {
                headers: {},
                body
            });

            if (apiResponse.error) {
                this.setState({
                    error: apiResponse.error
                })
            } else {
                if (apiResponse.formResponse.error.length > 0) {
                    this.setState({error: apiResponse.formResponse.error});
                } else {
                    ReactGA.event({
                        category: 'SignUp',
                        action: `${body.Company} Created Account`,
                        label : 'SignUp Form Success'
                    });
                    this.setState({success:true});
                }
            }
        } catch (e) {
            console.log(e);
        }

        this.setState({
            buttonText : 'Set up account'
        });
    };

    handleInputChange = async (property, e) => {
        let value;
        value = e.target.value;

        if(property==='Terms') {
            value = e.target.checked;
        }

        let body = {...this.state.body};
        body[property] = value;
        this.setState({body});
    };

    getError(field) {
        if(this.state.error) {
            for(let i = 0; i < this.state.error.length; i++) {
                if(field===this.state.error[i].field) {
                    return this.state.error[i].message;
                }
            }
        }
        return false;
    }

    clearForm = () => {
        this.setState({
            body: {
                Name: '',
                Email: '',
                Company: '',
                Phone: '',
                Terms: false
            }
        })
    }

    render() {

        Auth.currentSession()
            .then((data) => {
                if(data) {
                    Auth.signOut(); window.location.reload()
                }
            })
                .catch(err => console.log(err));

        const { classes } = this.props;
        const { body, success } = this.state;

        if(success) {
            return (
                <Typography variant="body2">
                    An account has been created for <b>{body.Email}.</b><br />
                    Please check your e-mail for instructions on how to activate it.
                </Typography>
            );
        } else {
            return (

                    <form onSubmit={this.registerUser} noValidate autoComplete="off">
                        <TextField
                            error={this.getError('Name')}
                            helperText={this.getError('Name')}
                            fullWidth
                            required
                            id="Name"
                            label="Name"
                            margin="normal"
                            value={body.Name}
                            onChange={(e) => this.handleInputChange('Name', e)}
                        />
                        <TextField
                            error={this.getError('Email')}
                            helperText={this.getError('Email')}
                            fullWidth
                            required
                            id="Email"
                            label="Email"
                            margin="normal"
                            value={body.Email}
                            onChange={(e) => this.handleInputChange('Email', e)}
                        />
                        <TextField
                            error={this.getError('Company')}
                            helperText={this.getError('Company')}
                            fullWidth
                            required
                            id="Company"
                            label="Company"
                            margin="normal"
                            value={body.Company}
                            onChange={(e) => this.handleInputChange('Company', e)}
                        />
                        <TextField
                            helperText={this.getError('Phone')}
                            fullWidth
                            id="Phone"
                            label="Phone"
                            margin="normal"
                            value={body.Phone}
                            onChange={(e) => this.handleInputChange('Phone', e)}
                        />
                        <TextField
                            helperText={this.getError('Code')}
                            fullWidth
                            id="Code"
                            label="Gift Code"
                            margin="normal"
                            value={body.Code}
                            onChange={(e) => this.handleInputChange('Code', e)}
                        />
                        <br/><br/>

                        <FormControl error={this.getError('Terms')} className={classes.actions}>
                            <FormGroup>
                                <div className={classes.terms}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(e) => this.handleInputChange('Terms', e)}
                                            />
                                        }
                                        label="I agree to the Privacy Policy"
                                    />

                                    <Link
                                        className={classes.clearButton}
                                        onClick={this.clearForm}
                                    >
                                        <Typography>Clear Form</Typography>
                                    </Link>
                                </div>
                            </FormGroup>
                            <FormHelperText>{this.getError('Terms')}</FormHelperText>
                        </FormControl>
                        <br /><br />

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            {this.state.buttonText}
                        </Button>
                    </form>

            )
        }
    }
}

export default withStyles(styles)(Register);