import React, { Component } from 'react';
import IconButton from "@material-ui/core/IconButton"
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket"
import DeleteIcon from "@material-ui/icons/Delete"
import CloseIcon from "@material-ui/icons/Close"
import SendIcon from "@material-ui/icons/Send"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import Badge from '@material-ui/core/Badge';
import Popper from '@material-ui/core/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FieldIncrementDecrement from '../common/FieldIncrementDecrement';
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API } from 'aws-amplify';
import Utils from "../../utils";
import uuid from "uuid";
import OrderPlaced from "../../pages/OrderPlaced";
import validator from "validator";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from '@material-ui/core/InputLabel';

const styles = theme => ({
    logoContainer: {
        maxWidth:150,
        width:"100%",
        margin:"0 auto",
        "& img" : {
            width:"100%"
        }
    },
    popper: {
        zIndex:1200,
        width:"100%"
        /*height:"100%" */
    },
    popperContent:{
        padding: 20,
        width:'100%',
        height:'100%',
        overflowY:'scroll'
    },
    popupFull : {
        "& [style]": {
            transform: "translate3d(0, 0, 0) !important"
        },
        position:"fixed"
    },
    miniBasketContainer: {
        margin:"0 auto",
        width:"100%",
        maxWidth:600
    },
    thumb: {
        maxWidth:"100%"
    },
    divider: {
        margin: "20px 0"
    },
    valign: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    active: {
        backgroundColor: "pink"
    },
    close: {
        position:"absolute",
        top:10,
        right:10
    },
    orderButton: {
        float:'right',
        marginTop:50,
        marginBottom:50
    },
    middleLoader: {
        display:'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    backButton :{
        textAlign:"center",
        marginTop:50,
        marginBottom:50
    },
    overview: {
        marginTop:25,
        marginBottom:25
    }
});

class BasketWidget extends Component {

    basketApiPath = "/basket";
    productApiPath = "/products";
    orderApiPath = "/orders";

    state = {
        refreshing: false,
        deleteOpen:false,
        orderOpen:false,
        deleteTitle: false,
        deleteAtts: false,
        loading: false,
        basket: [],
        count:0,
        sendingOrder:false,
        sendingOrderSuccess:false,
        showAllAttributes: false,
        showAllAttributesTitle : false,
        showAllAttributesAttributes : false
    };

    getData = async () => {
        const { user } = this.props;

        this.setState({
            deleteOpen:false,
            loading:true
        });

        try {
            const basket = await API.get("PrintPortalAPI", this.basketApiPath + '/' + user.ID, {
                headers: {}
            });

            let product;
            for(let i =0; i<basket.length; i++) {
                product = await API.get("PrintPortalAPI", this.productApiPath + '/object/' + basket[i].ProductID);
                basket[i]['Thumb'] = await Utils.getMedia(product.Thumb);
                basket[i]['Title'] = product.Title;
                basket[i]['QuantitySteps'] = product.QuantitySteps;
                basket[i]['QuantityOptions'] = product.Prices;
            }

            this.setState({
                loading:false,
                count:basket.length,
                basket
            });
        } catch(e) {
            console.log(e);
        }
    };

    componentWillReceiveProps = async() => {
            this.setState({
                refreshing:true,
                sendingOrder:false,
                sendingOrderSuccess:false
            });
            await this.getData();

            this.setState({
                refreshing:false
            })
    };

    componentDidMount = async() => {
        this.setState({
            sendingOrder:false,
            sendingOrderSuccess:false
        });
        await this.getData();
    };

    handleDeleteAccept = async () => {
        let { basket, activeLine, count } = this.state;
        basket = basket.filter(function(e) {
            return e.ID !== activeLine;
        });

        this.setState({
            deleteOpen:false,
            activeLine:false,
            deleteTitle:false,
            deleteAtts:false,
            count:count-1,
            basket
        });

        await API.del("PrintPortalAPI", this.basketApiPath + '/object/' + activeLine, {
            headers: {}
        });
    };

    handleDeleteCancel = () => {
        this.setState({
            deleteOpen:false,
            activeLine:false,
            deleteTitle:false,
            deleteAtts:false
        })
    };

    handleDeleteOpen = (e, activeLine, title, attributes) => {
        this.setState({
            deleteOpen:true,
            deleteTitle:title,
            deleteAtts:attributes,
            activeLine
        })
    };

    handleOrderCancel = () => {
        this.setState({
            orderOpen:false
        })
    };

    handleOrderAccept = async () => {
        const { basket } = this.state;
        const { user } = this.props;

        this.setState({
            sendingOrder:true,
            orderOpen:false
        });

        let Products = [];
        for(let i = 0; i<basket.length; i++) {

            if(validator.isUUID(basket[i].Quantity.toString())) {
                const productDetails = await Utils.getProductByIdDb(basket[i].ProductID);
                productDetails.Prices.map((key, index) => {
                    if(key.id===basket[i].Quantity) {
                        basket[i].Quantity = key.quantity;
                        basket[i].Selling = key.sellingPrice;
                    }
                });
            }

            Products[i] = {
                ProductID: basket[i].ProductID,
                Title: basket[i].Title,
                Quantity: parseInt(basket[i].Quantity),
                Selling: (basket[i].Selling ? parseFloat(basket[i].Selling) : 0)
            };

            if(basket[i].Attributes) {
                for(let o = 0; o<basket[i].Attributes.length;o++) {
                    Products[i][basket[i].Attributes[o].label]
                        = basket[i].Attributes[o].value
                }
            }

            await API.del("PrintPortalAPI", this.basketApiPath + '/object/' + basket[i].ID);
        }

        if(Utils.isDemo(user)) {
            this.setState({
                sendingOrderSuccess:true
            });
        } else {
            let order = {
                Reference : uuid.v4(),
                ClientEmail : user.Email,
                ClientName : user.ClientName,
                ClientTheme: user.ClientTheme,
                Client: user.Client,
                Date: new Date(),
                Products
            };

            try {
                await API.put("PrintPortalAPI", this.orderApiPath, {
                    headers: {},
                    body: order
                });

                this.setState({
                    sendingOrderSuccess:true
                });
            } catch(e) {
                console.log(e);
            }
        }

    };

    handleOrderOpen = () => {
        this.setState({
            orderOpen:true
        })
    };

    handleQtyChange = (val, activeLine) => {
        let { basket } = this.state;
        let self = this;

        if(typeof val === 'object') {
            val = val.target.value;
        }

        basket = basket.filter(function(e) {
            if(e.ID===activeLine) {

                e.Quantity = val;

                let body = Object.assign({}, e);

                delete body.QuantitySteps;
                delete body.Thumb;
                delete body.Title;

                try {
                    const response = API.put("PrintPortalAPI", self.basketApiPath, {
                        headers: {},
                        body
                    });

                } catch(e) {
                    console.log(e);
                }

            }

            return e;
        });

        this.setState({basket});
    };

    openShowAllAttributes = (title, attributes) => {
        this.setState({
            showAllAttributes: true,
            showAllAttributesTitle : title,
            showAllAttributesAttributes : attributes
        })
    };

    closeShowAllAttributes = () => {
        this.setState({
            showAllAttributes: false,
            showAllAttributesTitle : false,
            showAllAttributesAttributes : false
        })
    };

    close = (p) => {
        p.close();
    };

    render() {
        const { classes } = this.props;
        let { logo, user } = this.props;
        const {
            activeLine,
            loading,
            count,
            basket,
            deleteTitle,
            deleteAtts,
            sendingOrder,
            sendingOrderSuccess
        } = this.state;

        let htmlAtts = [];

        if(logo) {
            logo = (<div className={classes.logoContainer}><img className={classes.logo} src={logo} alt="" /></div>);
        }

        let content;
        if(loading) {
            content = <CircularProgress color="secondary" />
        } else {
            content = (
                <div>
                    <Dialog
                        open={this.state.deleteOpen}
                        onClose={this.handleDeleteCancel}
                    >
                        <DialogTitle id="alert-dialog-title">Remove <b>{deleteTitle}</b> from
                            basket?</DialogTitle>
                        <DialogContent>
                            {
                                (deleteAtts ? (
                                    <Typography variant="body2" gutterBottom>
                                        <i>
                                            {
                                                deleteAtts.map(ATT =>{
                                                    if(ATT.value && !ATT.value.includes('!##!')) {
                                                        return <div><b>{ATT.label}</b>: {ATT.value}</div>
                                                    }

                                                    return false;
                                                })
                                            }
                                        </i>
                                    </Typography>
                                ) : '')
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDeleteCancel} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.handleDeleteAccept} color="primary" autoFocus>
                                Accept
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.showAllAttributes}
                        onClose={this.closeShowAllAttributes}
                    >
                        <DialogTitle id="alert-dialog-title">Showing all product details for {this.state.showAllAttributesTitle}</DialogTitle>
                        <DialogContent>
                            {
                                (this.state.showAllAttributesAttributes ? (
                                    <Typography variant="body2" gutterBottom>
                                        <i>
                                            {
                                                this.state.showAllAttributesAttributes.map(ATT =>{
                                                    if(ATT.value && !ATT.value.includes('!##!')) {
                                                        return <div><b>{ATT.label}</b>: {ATT.value}</div>
                                                    }

                                                    return false;
                                                })
                                            }
                                        </i>
                                    </Typography>
                                ) : '')
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeShowAllAttributes} color="primary" autoFocus>
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.orderOpen}
                        onClose={this.handleOrderCancel}
                    >
                        {(user.Demo ?
                            <div>
                                <DialogTitle id="alert-dialog-title">Note</DialogTitle>
                                <DialogContent>
                                    <Typography>
                                        This portal is for demonstration purposes only and is not capable of processing orders.  We are on with creating your Personal Print Portal and you will have it shortly.  If you’re yet to send over products you currently buy and their costs, please do ASAP to: <Link href="mailto:info@thepersonalprintportal.com" variant="body2">info@thepersonalprintportal.com</Link>
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleOrderCancel} color="primary">
                                        Close
                                    </Button>
                                </DialogActions>
                            </div>
                            :
                            <div>
                                <DialogTitle id="alert-dialog-title">Proceed with order?</DialogTitle>
                                <DialogActions>
                                <Button onClick={this.handleOrderCancel} color="primary">
                                Cancel
                                </Button>
                                <Button onClick={this.handleOrderAccept} color="primary" autoFocus>
                                Accept
                                </Button>
                                </DialogActions>
                            </div>
                            )}

                    </Dialog>

                    <PopupState className={classes.popupFull} variant="popper" popupId="basket-popover">
                        {popupState => (
                            <div>
                                <IconButton color="inherit" variant="contained" {...bindToggle(popupState)}>
                                    <Badge badgeContent={count} color="secondary">
                                        <ShoppingBasketIcon/>
                                    </Badge>
                                </IconButton>
                                <Popper {...bindPopper(popupState)}
                                        className={classes.popper}
                                        transition
                                        style={{width: "100%", height: "100%"}}
                                        popperOptions={{
                                            modifiers: {
                                                preventOverflow: {
                                                    padding: 0
                                                }
                                            }
                                        }}
                                >

                                    {({TransitionProps}) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper className={classes.popperContent}>
                                                {
                                                    (sendingOrder ?
                                                        (sendingOrderSuccess ? <div><OrderPlaced text="Your order has<br /> been placed" /></div> : <div className={classes.middleLoader}><CircularProgress color="secondary" /></div>)
                                                        : <div>

                                                        <IconButton
                                                            className={classes.close}
                                                            onClick={() => this.close(popupState)}
                                                        >
                                                            <CloseIcon fontSize="large"/>
                                                        </IconButton>
                                                        {logo}
                                                        <Typography className={classes.overview} align="center" color="primary" variant="h5" gutterBottom>
                                                            There&nbsp;
                                                            {(count<2 && count!==0 ? 'is' : 'are')}
                                                            &nbsp;<b>{(count===0 ? 'no' : count)}</b>&nbsp;
                                                            {(count<2 && count!==0 ? 'product' : 'products')}
                                                            &nbsp;in your basket
                                                        </Typography>

                                                        <div className={classes.miniBasketContainer}>
                                                            {
                                                                basket.map((ITEM, I) => {
                                                                    return(
                                                                        <Grid
                                                                            container
                                                                            spacing={2}
                                                                            key={ITEM.ID}
                                                                            className={activeLine === ITEM.ID ? classes.active : ''}
                                                                        >
                                                                            <Grid item md={3} sm={3} xs={3} >
                                                                                <img src={ITEM.Thumb} className={classes.thumb} alt={ITEM.Title} />
                                                                            </Grid>
                                                                            <Grid item md={5} sm={5} xs={5}>
                                                                                <Typography color="primary" variant="body2" gutterBottom>
                                                                                    <b>{ITEM.Title}</b>
                                                                                </Typography>
                                                                                {
                                                                                    (ITEM.Attributes ? (
                                                                                        <Typography variant="body2" gutterBottom>
                                                                                            <i>
                                                                                                {
                                                                                                    ITEM.Attributes.map((ATT) =>{
                                                                                                        if(!htmlAtts[I]) {
                                                                                                            htmlAtts[I] = [];
                                                                                                        }
                                                                                                        if(ATT.value && !ATT.value.includes('!##!')) {
                                                                                                            if(htmlAtts[I].indexOf(ATT)===-1) {
                                                                                                                htmlAtts[I].push(ATT)
                                                                                                            }
                                                                                                        }

                                                                                                        return false;
                                                                                                    })
                                                                                                }
                                                                                                {
                                                                                                    htmlAtts[I].slice(0, 3).map((ATT, index) => {
                                                                                                        return <span key={index}>{ATT.label}: {ATT.value}</span>
                                                                                                    })
                                                                                                }
                                                                                                {
                                                                                                    (htmlAtts[I].length>3 ? (<Link
                                                                                                        component="button"
                                                                                                        variant="body2"
                                                                                                        onClick={() => {
                                                                                                            this.openShowAllAttributes(ITEM.Title, htmlAtts[I])
                                                                                                        }}
                                                                                                    >
                                                                                                        ... more product details
                                                                                                    </Link>) : '')
                                                                                                }
                                                                                            </i>
                                                                                        </Typography>
                                                                                    ) : '')
                                                                                }
                                                                            </Grid>
                                                                            <Grid item md={3} sm={3} xs={3} className={classes.valign}>
                                                                                {(validator.isUUID(ITEM.Quantity.toString()) ?
                                                                                 <div>
                                                                                    <InputLabel>Quantity</InputLabel>
                                                                                    <Select
                                                                                            value={ITEM.Quantity}
                                                                                            onChange={(val) => this.handleQtyChange(val, ITEM.ID)}
                                                                                        >
                                                                                            {
                                                                                                ITEM.QuantityOptions.map((key, index) => {
                                                                                                    return <MenuItem
                                                                                                        key={key.id}
                                                                                                        value={key.id}
                                                                                                    >
                                                                                                        {key.quantity}&nbsp;<small>(&pound;{Utils.formatMoney(key.sellingPrice)})</small>
                                                                                                    </MenuItem>
                                                                                                })
                                                                                            }
                                                                                        </Select>
                                                                                    </div>
                                                                                    :
                                                                                        <FieldIncrementDecrement
                                                                                            max={99999999999999999}
                                                                                            min={parseInt(ITEM.QuantitySteps)}
                                                                                            step={parseInt(ITEM.QuantitySteps)}
                                                                                            current={parseInt(ITEM.Quantity)}
                                                                                            onChange={(val) => this.handleQtyChange(val, ITEM.ID)}
                                                                                            label="Quantity"
                                                                                        />
                                                                                    )}
                                                                            </Grid>
                                                                            <Grid item md={1} sm={1} xs={1} className={classes.valign}>
                                                                                <IconButton onClick={(e) => this.handleDeleteOpen(e, ITEM.ID, ITEM.Title, ITEM.Attributes)}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </Grid>
                                                                            <Divider variant="middle" className={classes.divider}/>
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                (count>0 ? <div><Button
                                                                    variant="contained"
                                                                    size="large"
                                                                    color="primary"
                                                                    className={classes.orderButton}
                                                                    onClick={this.handleOrderOpen}
                                                                >
                                                                    ORDER&nbsp;&nbsp;<SendIcon />
                                                                </Button></div> : <div className={classes.backButton}>
                                                                    <Button
                                                                    variant="contained"
                                                                    size="large"
                                                                    color="primary"
                                                                    onClick={() => this.close(popupState)}
                                                                >
                                                                    <ArrowBackIcon />&nbsp;&nbsp;GO BACK
                                                                </Button></div>)
                                                            }

                                                        </div>
                                                    </div>)
                                                }
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                            </div>
                        )}
                    </PopupState>
                </div>
            );
        }

        return(
            content
        )
    }
}

export default withStyles(styles, { withTheme: true })(BasketWidget);