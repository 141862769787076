import React, { Component } from "react"
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles/index';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Divider from '@material-ui/core/Divider';
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import Utils from '../../utils';
import MenuItem from "@material-ui/core/MenuItem";
import Select from '@material-ui/core/Select';
import validator from "validator";
import {API} from "aws-amplify";

const styles = theme => ({
    formControl: {
        padding: "10px",
        boxSizing:'border-box',
        width: "100%",
    },
    spacer : {
        padding:"15px 0",
        boxSizing:'border-box'
    },
    attributes: {
       // width:'100%',
        marginLeft: "-10px"
    },
    hiddenAttributes : {
        marginLeft: "-10px"
    },
    attributesContainer: {
        width:'100%'
    },
    select : {
       // marginLeft:"-15px",
       // paddingRight: 0,
        paddingLeft:0
    },
    moveUp : {
        position:"relative",
        top:"-5px"
    }
});

class ProductOrder extends Component {

    state = {
        loading: false,
        Quantity : false,
        ProductID : false,
        showHiddenAttributes : false,
        preview : false,
        minQtyAmt: false,
        QuantityError: false,
        Selling: 0
       // order:{}
    };

    componentWillMount() {
        const { Attributes, ID, Quantity, QuantitySteps, Cost, Selling, Prices } = this.props.data;
        const { showHiddenAttributes, preview } = this.props;

        this.setState({
            ProductID : ID,
            minQtyAmt: QuantitySteps
        });

        let attributes = Attributes;
        if(typeof attributes === 'string' || attributes instanceof String) {
            attributes = JSON.parse(Attributes);
        }

        if(attributes) {
            attributes.forEach(function (att) {
                att.error = false;
                att.errorMsg = false;
                if(att.hidden) {
                    if(!Utils.checkIfAttHidden(att.value)) {
                        att.value = '!##!' + att.value;
                    }
                }
                this.setState({[att.id]: att});
            }.bind(this));
        }

        if(QuantitySteps) {
            if(Quantity) {
                this.setState({Quantity});
            } else {
                if(!this.state.Quantity) {
                    this.setState({Quantity:QuantitySteps});
                }
            }
        }

        if(Cost) {
            this.setState({Cost});
        }

        if(Selling) {
            this.setState({Selling});
        }

        if(showHiddenAttributes) {
            this.setState({showHiddenAttributes})
        }

        if(preview) {
            this.setState({preview})
        }

        if(Prices && Prices.length>0) {
            this.setState({Quantity: Prices[0].id});
        }

    };

    placeOrder = () => {
        const { data } = this.props;
        const formObj = this.state;

        if(validator.isUUID(formObj.Quantity)) {
            data.Prices.map((key, index) => {
                if(key.id===formObj.Quantity) {
                    formObj.Quantity = key.quantity;
                }
            });
        }
        //if(formObj.Quantity<data.QuantitySteps) {
        //    this.setState({minQuantityError:'This product requires a minimum quantity of ' + data.QuantitySteps})
        //} else {
        //    this.setState({minQuantityError:false});


            const processedForm = Utils.processForm(formObj);
            this.setState(processedForm.formObj);

            if(processedForm.success) {
                this.props.orderReview(processedForm.formObj);
            }
       // }
    };

    submitEnquiry = async () => {
        this.setState({
            loading: true
        });

        const { data, user } =  this.props;

        //console.log(data);

        const body = {
            ClientName: user.ClientName,
            ClientEmail: user.Email,
            ProductName : data.Title
        };

        try {
            await API.post("PrintPortalAPI", '/products/enquire', {
                headers: {},
                body
            });

            return this.props.history.push('/product-enquiry-placed');

        } catch(e) {
            console.log(e);

            this.setState({
                loading:false
            })
        }
    };

    addToBasket = async () => {

        this.setState({
            loading: true
        });

        const formObj = this.state;

        delete formObj.Attributes;

        const processedForm = Utils.processForm(formObj);
        const cartData = await Utils.processBasketData(processedForm.formObj);

        this.setState(processedForm.formObj);

        if(processedForm.success) {
            this.props.addToBasket(cartData);
        } else {
            this.setState({
                loading:false
            })
        }
    };

    handleFormChange = (id, field, e) => {
        const { data } = this.props;

        let Selling = 0;
        if(field==='Quantity') {
            let value = e.target.value;
            if(validator.isUUID(value)) {
                data.Prices.map((key, index) => {
                    if(key.id===value) {
                        Selling = key.sellingPrice;
                    }
                })
            } else {
                value = value.replace(/[^0-9]/, '');
            }

            if(value) {
                this.setState({'Quantity': value, Selling});
            }
        } else {
            const f = { ...this.state[id], value : e.target.value };
            this.setState({[id] : f});
        }

    };

    toggleHiddenAttributes = (showHiddenAttributes) => {
        this.props.toggleHiddenAttributes(showHiddenAttributes);
    };

    render() {
        const {data, classes, showHiddenAttributes, enquireOnly, previewOnly } = this.props;
        const form = this.state;

        if(typeof data.Attributes === 'string' || data.Attributes instanceof String) {
            data.Attributes = JSON.parse(data.Attributes);
        }

        /*let buttonText = (<div><ShoppingCartIcon />&nbsp;&nbsp;Add to Basket</div>);
        if(loading) {
            buttonText = ();
        }*/

        let attributes = (<div />);
        if(data.Attributes) {
            attributes = (<div className={classes.attributesContainer}>
                <Typography  variant="h6">
                    Print Specification
                </Typography>
                <Divider /><br />
                <Grid container>
                {
                data.Attributes.map((att) => {

                    let
                        hide = false,
                        readOnly = false,
                        error = form[att.id].error,
                        value = form[att.id].value || '',
                        className = classes.attributes;

                    if(att.hidden) {
                        hide = true;
                        readOnly = true;

                        if(showHiddenAttributes) {
                            hide = false;
                            if(!form[att.id].label.includes('[HIDDEN]')) {
                                form[att.id].label = form[att.id].label + ' [HIDDEN]';
                            }
                            value = Utils.stripHiddenPrefix(value);
                            className = classes.hiddenAttributes;
                        }
                    }


                    if(att.readonly) {
                        readOnly = true;
                    }

                    if (att.field === 'text') {

                        return (
                            <Hidden key={att.id} xsUp={hide}>
                                <Grid className={className} item sm={6} xs={12}>
                                    <FormControl error={error} className={classes.formControl}>
                                        <TextField
                                            id={att.id}
                                            label={att.label}
                                            value={value}
                                            onChange={this.handleFormChange.bind(this, att.id, att.field)}
                                            required={att.required}
                                            fullWidth
                                            InputProps={{
                                                readOnly
                                            }}
                                            error={error}
                                        />
                                        <FormHelperText>{att.errorMsg}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Hidden>
                        )
                    }
                    else if (att.field === 'textarea') {
                        return (
                            <Hidden key={att.id} xsUp={hide}>
                                <Grid className={classes.attributes}  item sm={6} xs={12}>
                                    <FormControl error={error} className={classes.formControl}>
                                        <TextField
                                            multiline
                                            id={att.id}
                                            label={att.label}
                                            value={value}
                                            onChange={this.handleFormChange.bind(this, att.id, att.field)}
                                            required={att.required}
                                            fullWidth
                                            InputProps={{
                                                readOnly
                                            }}
                                            error={error}
                                        />
                                        <FormHelperText>{att.errorMsg}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Hidden>
                        )
                    }
                    else if (att.field === 'select') {
                        const menuItems = att.options.map((OPTION) => {
                            return <MenuItem key={OPTION.value} value={OPTION.value}>{OPTION.value}</MenuItem>
                        });

                        return (
                            <Hidden key={att.id} xsUp={hide}>
                                <Grid className={classes.attributes} item sm={6} xs={12}>
                                    <FormControl error={error} className={classes.formControl}>

                                        <TextField
                                            label={att.label}
                                            id={att.id}
                                            select
                                            value={value}
                                            onChange={this.handleFormChange.bind(this, att.id, att.field)}
                                            required={att.required}
                                            fullWidth
                                            error={error}
                                            className={classes.select}
                                            InputProps={{
                                                readOnly
                                            }}
                                        >
                                            {menuItems}
                                        </TextField>
                                        <FormHelperText>{att.errorMsg}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Hidden>
                        );
                    }
                    else if (att.field ==='hidden') {
                      return (
                          <Hidden>
                              <TextField
                                  id={att.id}
                                  value={value}
                                  type="hidden"
                              />
                          </Hidden>
                      )
                    }

                    return false;
                })
                } </Grid>
            </div>);
        }


        let quantity = (this.state.Quantity ? this.state.Quantity : data.QuantitySteps);
        /*if(order && order.Quantity) {
            quantity = order.Quantity;
        }*/


        let quantityField;
        if(data.Prices && data.Prices.length>0) {
            data.QuantitySteps = false;

            if(!validator.isUUID(quantity.toString())) {
                quantity = data.Prices[0].id;
            }

            quantityField =
                <Grid className={classes.attributes} item sm={6} xs={12}>
                    <FormControl error={this.state.QuantityError} className={classes.formControl}>
                        <Select
                            displayEmpty={false}
                            value={quantity}
                            onChange={this.handleFormChange.bind(this, 'Quantity', 'Quantity')}
                        >
                        {
                            data.Prices.map((key, index) => {
                                return <MenuItem
                                        selected={(quantity===key.id)}
                                        key={key.id}
                                        value={key.id}
                                >
                                    {key.quantity}&nbsp;<small>(&pound;{Utils.formatMoney(key.sellingPrice)})</small>
                                </MenuItem>
                            })
                        }
                        </Select>
                    </FormControl>
                </Grid>
        } else {
            quantityField = (data.QuantitySteps ? (
                <Grid className={classes.attributes} item sm={6} xs={12}>
                    <FormControl error={this.state.QuantityError} className={classes.formControl}>
                        <TextField
                            fullWidth
                            readOnly
                            type="number"
                            onChange={this.handleFormChange.bind(this, 'Quantity', 'Quantity')}
                            value={quantity}
                            error={this.state.QuantityError}
                            inputProps={{ min: data.QuantitySteps, step: data.QuantitySteps }}
                        />
                        <FormHelperText>{this.state.QuantityError}</FormHelperText>
                    </FormControl>
                </Grid>
            ) : '');
        }


        return(
            <div>
                <br />
                {
                    (data.Description ? (
                        <div className={classes.spacer}>
                            <Typography  variant="h6">
                                Description
                            </Typography>
                            <Divider /><br />
                            <Typography variant="body1">
                                {data.Description}
                            </Typography>
                        </div>) : '')
                }

                {(!enquireOnly ? (
                    <div>
                        <Grid container spacing={0}>
                            {attributes}
                        </Grid>
                        <div className={classes.spacer}>
                            <Typography variant="h6">
                                Quantity {
                                (data.QuantitySteps ? <small>(Min {data.QuantitySteps})</small> : '')
                            } </Typography>
                            <Divider /><br />
                            {
                                quantityField
                            }
                        </div>
                        {(!previewOnly ? (
                            this.props.noOrderButton ? '' : (<Button
                                className={classes.placeOrderButton}
                                variant="contained"
                                size="large"
                                color="secondary"
                                onClick={() => this.addToBasket()}
                            >
                                {
                                    (
                                        this.state.loading ?
                                            <CircularProgress /> :
                                            <div><ShoppingCartIcon /><span className={classes.moveUp}>&nbsp;&nbsp;Add to Basket</span></div>
                                    )
                                }
                            </Button>)
                        ) : "")}
                    </div>
                ) : (
                    <div>
                        <Button
                            className={classes.placeOrderButton}
                            variant="contained"
                            size="large"
                            color="secondary"
                            onClick={() => this.submitEnquiry()}
                        >
                            {
                                (
                                    this.state.loading ?
                                        <CircularProgress /> :
                                        <div>&nbsp;&nbsp;Submit Enquiry</div>
                                )
                            }
                        </Button>
                    </div>
                ) )}

            </div>
        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(ProductOrder));