import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ForwardIcon from '@material-ui/icons/Forward';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import {withStyles} from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RootRef from "@material-ui/core/RootRef";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import uuid from "uuid";
import Utils from "../utils";
import ATTRIBUTE_PRESETS from "../data/ATTRIBUTES.js";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    card: {
        boxSizing:"borderBox",
        width:"100%",
        margin:"15px auto"
    },
    defaultSelected: {
        backgroundColor:theme.palette.secondary.main
    }
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)"
    })
});

class ProductAttributes extends Component {
    state = {
        attributePreset : false,
        attributes : false,
        tempOption : '',
        allExpanded: []
    };

    componentDidMount() {
        if(this.props.value) {
            let allExpanded = [];
            for(let i=0; i<this.props.value.length;i++) {
                allExpanded[i] = false;
            }
            this.setState({
                attributes: this.props.value,
                allExpanded
            })
        }
    }

    handleInputChange(id, key, el) {
        let { attributes } = this.state;
        let value;

        /*console.log(el);
        let valueEl = document.getElementById('value'),
            attValue = false;
        if(key==='field') {
            attValue = true
        }*/

        if(key==='required' || key==='hidden' || key==='readonly') {
            value = el.target.checked;
        } else {
            value = el.target.value;
        }

        for(let i = 0; i<attributes.length; i++) {
            if(attributes[i].id===id) {
                attributes[i][key] = value;

                if(key==='field') {
                    if(value==='select') {
                        attributes[i]['options'] = [];
                    } else {
                        attributes[i]['options'] = false;
                    }

                    if(value==='readonly') {
                        attributes[i]['readonly'] = true;
                    } else if(value==='hidden') {
                        attributes[i]['hidden'] = true;
                    } else {
                        attributes[i]['readonly'] = false;
                        /*if(!attributes[i]['value'].includes('!##!')) {
                            attributes[i]['value'] = '!##!'.attributes[i]['value'];
                        }*/
                        //attributes[i]['value'] = false;
                    }

                    /*if(attValue) {
                        attributes[i]['value'] = attValue;
                    }*/
                }
            }

        }

        /* let thisItem  = attributes.filter(item => {
            return item.id === id
        });
        console.log(thisItem);
//console.log(attributes); */

        this.setState(attributes);
        this.props.onChange(attributes);
    }

    handlePresetChange = (id, el) => {

        const index = el.target.value;

        if (typeof index === 'number') {
            this.setState({
                attributePreset: index
            });

            const preset = ATTRIBUTE_PRESETS.find(o => {
                return o.id === index;
            });

            let attributes = this.state.attributes;
            for(let i = 0; i<attributes.length; i++) {
                if (attributes[i].id === id) {
                    attributes[i]['label'] = preset.label;
                    attributes[i]['field'] = preset.field;
                    attributes[i]['value'] = preset.value;
                    if(preset.field==='select') {
                        attributes[i]['options'] = [];
                        preset.options.forEach(function(value) {
                            attributes[i]['options'].push({
                                id: uuid.v4(),
                                value
                            });
                        });
                    } else {
                        attributes[i]['options'] = false;
                    }
                }
            }

            this.setState(attributes);
            this.props.onChange(attributes);
            this.setState({
                attributePreset: false
            });

        } else {
            this.setState({
                attributePreset: false
            });
        }
    };

    setExpanded = (expanded, index) => {
        let {allExpanded} = this.state;

        allExpanded[index] = expanded;

        this.setState({allExpanded});
    };

    setAllExpanded = () => {
        const { attributes } = this.state;
        let { allExpanded } = this.state;

        for(let i=0; i<attributes.length; i++) {
            allExpanded[i] = true;
        }

        this.setState({allExpanded});
    };

    setAllShrink = () => {
        const { attributes } = this.state;
        let { allExpanded } = this.state;

        for(let i=0; i<attributes.length; i++) {
            allExpanded[i] = false;
        }

        this.setState({allExpanded});
    };

    form = () => {
        const { classes } = this.props;
        let { attributes, allExpanded } = this.state;

        if(typeof attributes === 'string' || attributes instanceof String) {
            attributes = JSON.parse(attributes);
        }

        return attributes.map((key, index) => {
            let options = false,
                optionVals = false,
                value = false;

            key.label = key.label.replace(' [HIDDEN]', '');

            if(key.field==='select') {

                if(key.value) {
                    key.value = key.value.replace('!##!', '');
                }

                optionVals = <List key={key.id}> {
                    key.options.map((option) => {

                        let className = '';
                        let selectIcon =
                            <IconButton aria-label="Default"
                              onClick={() => this.setDefaultOption(key.id, option.value)}>
                            <ForwardIcon />
                            </IconButton>;

                        if(key.value===option.value) {
                            className = classes.defaultSelected;
                            selectIcon =
                                <IconButton aria-label="Default"
                                            onClick={() => this.removeDefaultOption(key.id, option.value)}>
                                    <CloseIcon />
                                </IconButton>;
                        }

                        return (
                            <ListItem key={option.id} className={className}>
                                <ListItemText>{option.value}</ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton aria-label="Delete"
                                                onClick={() => this.removeOption(key.id, option.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                    {selectIcon}
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })
                } </List>;


                options = (
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="option">Add option</InputLabel>
                        <Input
                            id={"option-" + key.id}
                            required
                            onChange={(e) => this.setTempOption(key.id, e)}
                            value={this.state.tempOption[key.id]}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={(ev) => this.addOption(key.id, ev)}>
                                        <AddIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {optionVals}
                    </FormControl>

                );
            }

            if(key.field==='text') {
                value = (
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="value">Value</InputLabel>
                        <Input
                            id="value"
                            multiline
                            rows="4"
                            required
                            fullWidth
                            value={(key.value ? key.value.replace('!##!', '') : '')}
                            onChange={(e) => this.setValue(key.id, e)}
                        />
                    </FormControl>
                );
            }

            if(key.field==='textarea') {
                value = (
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Value"
                            id="value"
                            multiline
                            rows="4"
                            defaultValue={(key.value ? key.value.replace('!##!', '') : '')}
                            onChange={(e) => this.setValue(key.id, e)}
                        />
                    </FormControl>
                );
            }

            /*if(key.readonly) {
                value = (
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="readonly">Readonly value</InputLabel>
                        <Input
                            id="readonly"
                            required
                            fullWidth
                            value={this.state.value}
                            onChange={(e) => this.setValue(key.id, e)}
                        />
                    </FormControl>
                );
            }*/

            /*if(key.hidden) {
                value = (
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="hidden">Hidden Field value</InputLabel>
                        <Input
                            id="hidden"
                            required
                            fullWidth
                            value={this.state.value}
                            onChange={(e) => this.setValue(key.id, e)}
                        />
                    </FormControl>
                );
            }*/

            if(key.label==='**NEW ATTRIBUTE**') {
                key.required = true;
            }

            return(
                <Draggable key={key.id} draggableId={key.id} index={index}>
                    {(provided, snapshot) => (
                        <RootRef rootRef={provided.innerRef}>
                        <ExpansionPanel className={classes.panel}
                                        expandIcon={<ExpandMoreIcon />}
                                        expanded={allExpanded[index]}
                                        onChange={(ev, expanded) => { this.setExpanded(expanded, index) }}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{key.label}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>

                                <Grid  container spacing={2}>
                                    <Grid item md={6} sm={12}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor={"attlabel-" + key.id}>Preset</InputLabel>
                                            <Select
                                                fullWidth
                                                required
                                                value={this.state.attributePreset}
                                                onChange={(e) => {
                                                    this.handlePresetChange(key.id, e);
                                                }}
                                                id={"attfield-" + key.id}
                                            >
                                                <MenuItem value={false}>
                                                </MenuItem>
                                                {
                                                    Utils.sortByKey(ATTRIBUTE_PRESETS, 'label').map(ATT => {
                                                        return(
                                                            <MenuItem key={ATT.id} value={ATT.id}>
                                                                {ATT.label}
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor={"attlabel-" + key.id}>Title of attribute (ie... size)</InputLabel>
                                            <Input
                                                variant="filled"
                                                id={"attlabel-" + key.id}
                                                fullWidth
                                                required
                                                value={key.label}
                                                onChange={(e) => this.handleInputChange(key.id, 'label', e)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <FormControl className={classes.formControl}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={"attrequired-" + key.id}
                                                        checked={key.required}
                                                        value={`${key.required}`}
                                                        onChange={(e) => this.handleInputChange(key.id, 'required', e)}
                                                    />
                                                }
                                                label="Is mandatory?"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <FormControl className={classes.formControl}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={"attrequired-" + key.id}
                                                        checked={key.hidden}
                                                        value={`${key.hidden}`}
                                                        onChange={(e) => this.handleInputChange(key.id, 'hidden', e)}
                                                    />
                                                }
                                                label="Is hidden from client?"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor={"attfield-" + key.id}>Type of field</InputLabel>
                                            <Select
                                                fullWidth
                                                required
                                                value={key.field}
                                                onChange={(e, b, c) => {
                                                    this.handleInputChange(key.id, 'field', e, b, c);
                                                }}
                                                id={"attfield-" + key.id}
                                            >
                                                <MenuItem value="text">
                                                    Text
                                                </MenuItem>
                                                <MenuItem value="textarea">
                                                    Textarea
                                                </MenuItem>
                                                <MenuItem value="select">
                                                    Select
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <FormControl className={classes.formControl}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={"attrequired-" + key.id}
                                                        checked={key.readonly}
                                                        value={`${key.readonly}`}
                                                        onChange={(e) => this.handleInputChange(key.id, 'readonly', e)}
                                                    />
                                                }
                                                label="Is readonly?"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12} sm={12}>
                                        {options}
                                        {value}
                                        <Button
                                            style={{float: 'right'}}
                                            component="span"
                                            onClick={() => this.removeAttribute(key.id)}
                                        >
                                            x Remove Attribute
                                        </Button>
                                    </Grid>

                                </Grid>

                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        </RootRef>
                    )}
                </Draggable>
            );

        });
    };

    addAttribute = () => {
        const empty = {
            id:uuid.v4(),
            label:"**NEW ATTRIBUTE**",
            name:"",
            field:"text",
            value:false,
            qtySteps:1,
            options:false,
            required:false,
            readonly:false
        };

        if(!this.state.attributes) {
            this.setState({attributes: [empty]});
        } else {
            let attributes = this.state.attributes;
            attributes.push(empty);
            this.setState({attributes});
        }

    };

    removeAttribute = (id) => {
        let attributes = this.state.attributes;
        for(let i = 0; i<attributes.length; i++) {
            if(attributes[i].id===id) {
                attributes.splice(i,1);
                this.props.onChange(attributes);
                this.setState({attributes});
            }
        }
    };

    addOption = (attId) => {
        let attributes = this.state.attributes;
        for(let i = 0; i<attributes.length; i++) {
            if (attributes[i].id === attId) {
                attributes[i].options.push({id:uuid.v4(), value : this.state.tempOption[attId]});
            }
        }
        this.setState({attributes, tempOption:''});
        this.props.onChange(attributes);
    };

    removeOption = (attId, optId) => {
        let attributes = this.state.attributes;
        for(let attIndex = 0; attIndex<attributes.length; attIndex++) {
            if(attributes[attIndex].id===attId) {
                let options = attributes[attIndex].options;
                for(let optIndex = 0; optIndex<options.length; optIndex++) {
                    if(options[optIndex].id===optId) {
                        delete attributes[attIndex].options[optIndex];
                        attributes[attIndex].options = attributes[attIndex].options.filter(function(){return true;});
                    }
                }


                this.setState({attributes, tempOption:''});
                this.props.onChange(attributes);
            }
        }
    };

    setDefaultOption = (attId, value) => {
        let attributes = this.state.attributes;
        for(let attIndex = 0; attIndex<attributes.length; attIndex++) {
            if (attributes[attIndex].id === attId) {
                attributes[attIndex].value = value;
            }
        }

        this.setState({attributes, tempOption:''});
        this.props.onChange(attributes);

    };

    removeDefaultOption = (attId, value) => {
        let attributes = this.state.attributes;
        for(let attIndex = 0; attIndex<attributes.length; attIndex++) {
            if (attributes[attIndex].id === attId) {
                attributes[attIndex].value = '';
            }
        }

        this.setState({attributes, tempOption:''});
        this.props.onChange(attributes);
    };

    setValue = (id, e) => {
        let attributes = this.state.attributes;
        for(let i = 0; i < attributes.length; i++) {
            if(attributes[i].id===id) {
                attributes[i].value = e.target.value;
            }
        }
        this.props.onChange(attributes);
    };

    setTempOption = (id, e) => {
        const val = e.target.value;
        this.setState({ tempOption : { [id] : val } });
    };

    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        let { attributes } = this.state;

        attributes = reorder(
            attributes,
            result.source.index,
            result.destination.index
        );

        this.setState({attributes});
        this.props.onChange(attributes);
    };

    render() {

        let form = false;
        if(this.state.attributes) {
            form = this.form();
        }

        return(
            <div>
            <div style={{ display: 'flex', justifyContent:'flex-end', marginBottom:5 }}>
                <Button size="small" color="inherit" onClick={this.setAllExpanded}>
                    Expand all
                </Button>
                <Button size="small" color="inherit" onClick={this.setAllShrink}>
                    Shrink all
                </Button>
            </div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <RootRef rootRef={provided.innerRef}>
                                <div>
                                    {form}
                                    {provided.placeholder}
                                </div>
                            </RootRef>
                        )}
                    </Droppable>
                </DragDropContext>
                <Button
                    component="span"
                    onClick={this.addAttribute}
                >
                    + Add Attribute
                </Button>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ProductAttributes);