import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const styles = theme => ({
    content : {
        textAlign:"center"
    },
    headline: {
        color: theme.palette.secondary.main
    },
    icon: {
        color: theme.palette.secondary.main,
        fontSize: 50
    }
});

class OrderPlaced extends Component {

    gotoDash = () => {
        return this.props.history.push('/');
    };

    render() {
        const { classes } = this.props;

        return(
            <div className={classes.content}>
                <CheckCircleOutlineIcon className={classes.icon} />
                <Typography className={classes.headline} variant="h5">
                    <div dangerouslySetInnerHTML={{__html : this.props.text}} />
                </Typography>
                <br />
                <Typography>
                    Any questions please get in touch
                </Typography>
                <br />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.gotoDash}
                >
                    Go back to dashboard
                </Button>
            </div>
        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(OrderPlaced));