/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:0c8bd4d4-557e-4053-9847-ef8e0709c611",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_UpjCgD4Xd",
    "aws_user_pools_web_client_id": "1unhp0a9hai4de1k1ocg6ngq0n",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "PrintPortalAPI",
            "endpoint": "https://yzibn8k0mg.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "eu-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "PrintPortalProducts-dev",
            "region": "eu-west-2"
        },
        {
            "tableName": "PrintPortalOrders-dev",
            "region": "eu-west-2"
        },
        {
            "tableName": "PrintPortalClients-dev",
            "region": "eu-west-2"
        },
        {
            "tableName": "PrintPortalUsers-dev",
            "region": "eu-west-2"
        },
        {
            "tableName": "PrintPortalBasket-dev",
            "region": "eu-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "printportalmedia-dev",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_content_delivery_bucket": "printportaldev-dev",
    "aws_content_delivery_bucket_region": "eu-west-2",
    "aws_content_delivery_url": "https://d22y6vw14fe0wl.cloudfront.net",
    "aws_mobile_analytics_app_id": "3f9e06b131e1414da96a08fcede9b8cc",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
