const ATTRIBUTE_PRESETS = [
    {
        id:0,
        label:"Pages",
        field:"select",
        options:[
            "2pp",
            "4pp",
            "8pp",
            "12pp",
            "16pp",
            "20pp",
            "24pp",
            "32pp",
            "64pp"
        ]
    },
    {
        id:1,
        label:"Lined",
        field:"select",
        options:[
            "Yes",
            "No"
        ]
    },
    {
        id:2,
        label:"Rule thickness",
        field:"select",
        options:[
            "0.5",
            "1",
            "1.5",
            "2"
        ]
    },
    {
        id:3,
        label:"Perforated",
        field:"select",
        options:[
            "Yes",
            "No"
        ]
    },
    {
        id:4,
        label:"Sheets",
        field:"select",
        options:[
            "25",
            "50",
            "100",
            "150"
        ]
    },
    {
        id:5,
        label:"Stock (gsm)",
        field:"select",
        options:[
            "80gsm",
            "100gsm",
            "120gsm",
            "150gsm",
            "200gsm",
            "280gsm",
			"300gsm",
            "400gsm",
            "450gsm",
			"600gsm"
        ]
    },
    {
        id:6,
        label:"Size/Style",
        field:"select",
        options:[
            "A0 841mm x 1189mm",
            "A1 594mm x 841mm",
            "A2 420mm x 594mm",
            "A3 297mm x 420mm",
            "A4 210mm x 297mm",
            "A5 148mm x 210mm",
            "A6 105mm x 148mm",
            "A7 74mm x 105mm",
            "A8 52mm x 74mm",
            "Square (small) 148mm x 148mm",
            "Square (large)	201mm x 210mm",
            "DL	99mm x 210mm"
        ]
    },
    {
        id:7,
        label:"Envelope Sizes",
        field:"select",
        options:[
            "DL (letter) 110mm x 220mm",
            "C3 428mm x 324mm",
            "C4 324mm x 229mm",
            "C5 162mm x 229mm",
            "C6 114mm x 162mm",
            "C7 84mm x 114mm"
        ]
    },
    {
        id:8,
        label:"Material",
        field:"select",
        options:[
			"Gloss",
            "Pearl",
            "Silk",
			"Uncoated"
        ]
    },
    {
        id:9,
        label:"Paper Folds",
        field:"select",
        options:[
            "Half-Fold",
            "Tri-Fold",
            "Z-Fold",
            "4-Panel Accordion Fold",
            "3-Panel Gate Fold",
            "Double Gate Fold",
            "Roll Fold",
            "Double Parallel Fold",
            "Vertical Half Fold",
            "French Fold (Quarter Fold)",
            "Map Fold",
            "Cross Fold"
        ]
    },
    {
        id:10,
        label:"Sides",
        field:"select",
        options:[
            "Single-sided",
            "Double-sided"
        ]
    },
    {
        id:11,
        label:"VAT Rated",
        field:"select",
        options:[
            "Plus VAT",
            "No VAT"
        ]
    },
    {
        id:12,
        label:"Finish",
        field:"select",
        options:[
            "Crease",
			"Debossing (Letterpress)",
			"Di-Bond",
			"Die Cut",
			"Embossing",
			"Fabric",
			"Fabric Cover and Frame",
			"Fabric Only",
			"Foil",
			"Fold",
			"Laminated and Embossed",
			"Laminated and Spot UV",
			"Laminated and Soft Touch",
			"Laminated",
			"Mesh",
			"Plastic",
			"PVC",
            "Trim",
			"Scratchcard",
            "Soft Touch",
			"Spot UV - single sided",
			"Spot UV - double sided",
            "Stitch",
            "Vinyl Cutting"	
        ]
    },
    {
        id:13,
        label:"Turnaround",
        field:"select",
        options:[
            "Express",
            "Standard"
        ]
    },
    {
        id:14,
        label:"Full Name",
        field:"text"
    },
	{
        id:15,
        label:"First Name",
        field:"text"
    },
	{
        id:16,
        label:"Middle Name",
        field:"text"
    },
	{
        id:17,
        label:"Last Name",
        field:"text"
    },
    {
        id:18,
        label:"Position",
        field:"text"
    },
    {
        id:19,
        label:"Company Address",
        field:"textarea"
    },
    {
        id:20,
        label:"Telephone",
        field:"text"
    },
    {
        id:21,
        label:"Email",
        field:"text"
    },
    {
        id:22,
        label:"Mobile",
        field:"text"
    },
    {
        id:23,
        label:"Website",
        field:"text"
    },
    {
        id:24,
        label:"Delivery Instructions",
        field:"textarea"
    },
    {
        id:25,
        label:"Delivery Date",
        field:"textarea"
    },
    {
        id:26,
        label:"Envelope Window",
        field:"select",
        options:[
            "Non-Window",
            "Window"
        ]
    },
    {
        id:27,
        label:"Price",
        field:"text"
    },
	{
        id:28,
        label:"WiFi Network",
        field:"text"
    },
	{
        id:29,
        label:"WiFi Password",
        field:"text"
    },
	{
        id:30,
        label:"Print Instructions",
        field:"textarea"
    },
	{
        id:31,
        label:"Fabric Banner Sizes",
        field:"select",
        options:[
            "1190mm x 3590mm",
			"1500mm x 3000m",
            "970mm x 2820mm"
        ]
    },
	{
        id:32,
        label:"Fabric Banner Types",
        field:"select",
        options:[
            "C-Shaped - Small",
			"C-Shaped - Large",
            "J-Shaped",
			"U-Shaped",
			"Sloped",
			"Private Booth"
        ]
    },
	{
        id:33,
        label:"Colour",
        field:"select",
        options:[
            "No graphic",
			"Double sided",
			"Full Colour Throughout"
        ]
    },
	{
        id:34,
        label:"Binding",
        field:"select",
        options:[
            "wiro Binding",
			"Comb Binding",
			"Half Canadian Wiro Binding",
			"Velo Binding",
			"Ring Binding"
        ]
    },
	{
        id:35,
        label:"General Notes",
        field:"textarea"
    },
	{
        id:36,
        label:"Peel and Seal",
        field:"select",
        options:[
            "Yes",
			"No"
        ]
    },
	{
        id:37,
        label:"Spine Capacity",
        field:"select",
        options:[
            "1mm",
			"2mm",
			"3mm",
			"4mm",
			"5mm",
			"6mm",
			"7mm"
        ]
    },
	{
        id:38,
        label:"Purchase Order Number",
        field:"text"
    },
    {
        id:39,
        label:"Delivery Price",
        field:"text"
    },
    {
        id:40,
        label:"Proof Type",
        field:"select",
        options:[
            "Print-Ready",
			"Draft"
        ]
    },
    {
        id:41,
        label:"Print Spec Only",
        field:"select",
        options:[
            "1 (Print Spec Only)",
			"0 (Print Spec and Print Ready PDF)"
        ]
    },	
	{
        id:42,
        label:"Card slot on pocket",
        field:"select",
        options:[
            "Yes",
			"No"
        ]
    }			
];

export default ATTRIBUTE_PRESETS;