import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from "@material-ui/core/Button";
import { API } from 'aws-amplify';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Utils from "../utils";
import Link from "@material-ui/core/Link";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        //marginLeft: theme.spacing.unit,
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    labelHead: {
        marginLeft:10,
        marginTop:15
    },
    divider : {
        marginTop:25,
        marginBottom:25
    }
});

class RequestBespokeProduct extends Component {

    apiPath = '/products';
    state = {
        body: {
            JobTitle : '',
            //SizeStyle: '',
            //Quantity: '',
            //PrintSpecFront:'',
            //PrintSpecBack:'',
            //MaterialType:'',
            //MaterialGSM:'',
            //Finishing:[],
            //DeliveryInstructions:'',
            PrintSpecifics:''
        },
        buttonText : "Submit",
        demoHandleSubmit : false
    };

    handleInputChange = (e, key) => {

        let { body } = this.state;

        if(e.target.type==='checkbox') {
            let currentVal = body[key];
            if(currentVal.length===0) {
                currentVal.push(e.target.value);
            }
            else {
                if(!e.target.checked) {
                    let index = currentVal.indexOf(e.target.value);
                    if (index !== -1) currentVal.splice(index, 1);
                } else {
                    currentVal.push(e.target.value);
                }
            }

            body[key] = currentVal;


        } else {
            if(e.target.value) {
                body[key] = e.target.value;
            }
        }

        this.setState(body);

    };

    handleSubmit = async () => {
        const { user } = this.props;
        const { body } = this.state;

        if(Utils.isDemo(user)) {

            this.setState({
                demoHandleSubmit: true
            })

        } else {

            this.setState({
                buttonText: <CircularProgress color="primary"/>
            });

            body.ClientName = user.ClientName;
            body.ClientEmail = user.Email;

            try {
                const apiResponse = await API.post("PrintPortalAPI", this.apiPath + '/request-bespoke', {
                    headers: {},
                    body
                });

                if (apiResponse.formResponse.error.length > 0) {
                    this.setState({
                        error: apiResponse.formResponse.error,
                        buttonText: "Submit"
                    });
                    window.scrollTo(0, 0);
                } else {
                    return this.props.history.push('/bespoke-request-placed');
                }

            } catch (e) {
                console.log(e);
            }
        }
    };

    getError(field) {
        if(this.state.error) {
            for(let i = 0; i < this.state.error.length; i++) {
                if(field===this.state.error[i].field) {
                    return true;
                }
            }
        }
        return false;
    }

    handleDemoPopupClose = () => {
        this.setState({
            demoHandleSubmit: false
        })
    };

    render() {
        const { body } = this.state;
        const { classes } = this.props;
        return(
            <Paper className={classes.root} elevation={1}>
            <form className={classes.container} noValidate autoComplete="off">
                <Grid container spacing={2}>

                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5">
                                Use this form to give us an idea of what you would like
                            </Typography>
                            <TextField
                                error={this.getError('JobTitle')}
                                fullWidth
                                id="jobtitle"
                                label="Job Title"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                value={body.JobTitle}
                                onChange={(e) => this.handleInputChange(e, 'JobTitle')}
                            />

                            <Divider className={classes.divider} />
                            <Typography variant="h6">
                                Describe the product you would like
                            </Typography>
                            <Typography>
                                i.e. Business Cards, 300GSM, Gloss...
                            </Typography>
                            <br />

                            <TextField
                                fullWidth
                                id="printspecifics"
                                className={classes.textField}
                                label=""
                                multiline
                                rows="4"
                                variant="outlined"
                                value={body.PrintSpecifics}
                                onChange={(e) => this.handleInputChange(e, 'PrintSpecifics')}
                            />
                        </Grid>
                    </Grid>

                    {/*<Grid item xs={12} sm={6}>
                        <Divider className={classes.divider} />
                        <Typography variant="h5">
                            You can provide some more detail below
                        </Typography>

                        <TextField
                            fullWidth
                            id="sizestyle"
                            label="Size/Style"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={body.SizeStyle}
                            onChange={(e) => this.handleInputChange(e, 'SizeStyle')}                        />
                        <TextField
                            error={this.getError('Quantity')}
                            fullWidth
                            id="quantity"
                            label="Quantity"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={body.Quantity}
                            onChange={(e) => this.handleInputChange(e, 'Quantity')}
                        />
                    </Grid>*/}
                </Grid>

                {/*<Grid container spacing={16}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" className={classes.labelHead}>Print Spec</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={16}>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            id="printspecfront"
                            label="Front"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={body.PrintSpecFront}
                            onChange={(e) => this.handleInputChange(e, 'PrintSpecFront')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            id="printspecback"
                            label="Back"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={body.PrintSpecBack}
                            onChange={(e) => this.handleInputChange(e, 'PrintSpecBack')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={16}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" className={classes.labelHead}>Material</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={16}>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            id="materialtype"
                            label="Type"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={body.MaterialType}
                            onChange={(e) => this.handleInputChange(e, 'MaterialType')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            id="materialgsm"
                            label="GSM"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={body.MaterialGSM}
                            onChange={(e) => this.handleInputChange(e, 'MaterialGSM')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={16}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" className={classes.labelHead}>Finishing</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={16}>
                    <Grid item xs={12} sm={6}>
                        <FormGroup row>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    className={classes.textField}
                                    control={
                                        <Checkbox
                                            value="Laminate"
                                            onChange={(e) => this.handleInputChange(e, 'Finishing')}
                                        />
                                    }
                                    label="Laminate"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    className={classes.textField}
                                    control={
                                        <Checkbox
                                            value="Soft touch"
                                            onChange={(e) => this.handleInputChange(e, 'Finishing')}
                                        />
                                    }
                                    label="Soft touch"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    className={classes.textField}
                                    control={
                                        <Checkbox
                                            value="Trim"
                                            onChange={(e) => this.handleInputChange(e, 'Finishing')}
                                        />
                                    }
                                    label="Trim"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    className={classes.textField}
                                    control={
                                        <Checkbox
                                            value="Crease"
                                            onChange={(e) => this.handleInputChange(e, 'Finishing')}
                                        />
                                    }
                                    label="Crease"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    className={classes.textField}
                                    control={
                                        <Checkbox
                                            value="Fold"
                                            onChange={(e) => this.handleInputChange(e, 'Finishing')}
                                        />
                                    }
                                    label="Fold"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    className={classes.textField}
                                    control={
                                        <Checkbox
                                            value="Stitch"
                                            onChange={(e) => this.handleInputChange(e, 'Finishing')}
                                        />
                                    }
                                    label="Stitch"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    className={classes.textField}
                                    control={
                                        <Checkbox
                                            value="Embossing"
                                            onChange={(e) => this.handleInputChange(e, 'Finishing')}
                                        />
                                    }
                                    label="Embossing"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    className={classes.textField}
                                    control={
                                        <Checkbox
                                            value="Spot-UV"
                                            onChange={(e) => this.handleInputChange(e, 'Finishing')}
                                        />
                                    }
                                    label="Spot-UV"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    className={classes.textField}
                                    control={
                                        <Checkbox
                                            value="Die-cut"
                                            onChange={(e) => this.handleInputChange(e, 'Finishing')}
                                        />
                                    }
                                    label="Die-cut"
                                />
                            </Grid>
                        </FormGroup>
                    </Grid>
                </Grid>

                <Grid container spacing={16}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" className={classes.labelHead}>&nbsp;</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={16}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="deliveryinstructions"
                            label="Delivery instructions"
                            multiline
                            rows="4"
                            variant="outlined"
                            value={body.DeliveryInstructions}
                            onChange={(e) => this.handleInputChange(e, 'DeliveryInstructions')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={16}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" className={classes.labelHead}>&nbsp;</Typography>
                    </Grid>
                </Grid>*/}

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" className={classes.labelHead}>&nbsp;</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.handleSubmit}
                        >
                            {this.state.buttonText}
                        </Button>
                    </Grid>
                </Grid>
            </form>
                <Dialog
                    open={this.state.demoHandleSubmit}
                    onClose={this.handleDemoPopupClose}
                >
                    <div>
                        <DialogTitle id="alert-dialog-title">Note</DialogTitle>
                        <DialogContent>
                            <Typography>
                                This portal is for demonstration purposes only and is not capable of processing orders.  We are on with creating your Personal Print Portal and you will have it shortly.  If you’re yet to send over products you currently buy and their costs, please do ASAP to: <Link href="mailto:info@thepersonalprintportal.com" variant="body2">info@thepersonalprintportal.com</Link>
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDemoPopupClose} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>
            </Paper>
        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(RequestBespokeProduct));