import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    divider: {
        margin:"15px 0"
    }
});

class ActivatePortal extends Component {

    state = {
        buttonText : "Activate my Bespoke Portal"
    };

    handleSubmit = () => {
        const { user } = this.props;

        this.setState({
            buttonText : <CircularProgress color="primary" />
        });
    };

    render() {

        const { classes } = this.props;

        return(
            <Paper className={classes.root} elevation={1}>
                <Typography variant="h5">
                    Clicking the button below will inform our team you wish to talk to them about creating a Bespoke Portal.
                </Typography>

                <Divider className={classes.divider} />
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.handleSubmit}
                >
                    {this.state.buttonText}
                </Button>
            </Paper>
        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(ActivatePortal));