import React, { Component } from 'react';
import OrdersTable from '../components/page/OrdersTable';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
});


class Orders extends Component {
    basketUpdate = () => {
        this.props.basketUpdate();
    };

    render() {
        const { classes } = this.props;
        return(
            <Paper className={classes.root} elevation={1}>
                <Typography color="primary" variant="h5">
                    Orders
                </Typography>
                <br /><br />
                <OrdersTable basketUpdate={this.basketUpdate} userGroup={this.props.userGroup} user={this.props.user} />
            </Paper>
        )
    }
}

export default withStyles(styles)(Orders);