import React, { Component } from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Typography from "@material-ui/core/Typography"
import {withStyles} from "@material-ui/core/styles";
import BasketWidget from "./widget/BasketWidget";
import UserWidget from "./widget/UserWidget";
import Utils from "../utils";

const drawerWidth = 240;

const styles = theme => ({
    appBar: {
        marginLeft: drawerWidth,
        display:'flex',
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    title: {
        flexGrow: 1,
    }
});

class Bar extends Component {
    render() {
        const { classes, email, client, user, logo, title } = this.props;

        return(
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={this.props.handleNavigationToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                        {title} {Utils.isDemo(user) ? '(Demo)' : ''}
                    </Typography>
                    {
                        (user.Client!=='Admin' ? <BasketWidget logo={logo} user={user} />
                            : '')
                    }
                    <UserWidget email={email} client={client} />
                    {/*<Typography variant="body2" color="inherit" noWrap>
                        Logged in as - {email} ({client})
                    </Typography>*/}

                </Toolbar>
            </AppBar>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Bar);