import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from '@material-ui/core/Checkbox';
import Paper from "@material-ui/core/Paper";
import {withStyles} from "@material-ui/core/styles";

import { API } from 'aws-amplify';

import uuid from "uuid";

import Utils from '../../utils';

const styles = theme => ({
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
});

class UserAdd extends Component {

    userApiPath = "/users";
    clientApiPath = "/clients";

    defaultState = () => {
        return {
            access: 'Admin',
            error: false,
            errorMessage: false,
            body: {
                ID: uuid.v4(),
                Name: '',
                Email: '',
                Client: '',
                Phone: '',
                Code: '',
                Demo: false,
                Token: this.props.token
            },
            buttonText: 'Add User',
            type: 'add',
            clients: [],
            loading: false
        }
    };

    constructor(props) {
        super(props);

        this.state = this.defaultState();
    }

    componentDidMount = async () => {

        const { props } = this;

        if(props.type==='edit') {
            const {match: {params}} = props;

            this.setState({
                buttonText: 'Update user',
                type: 'edit'
            });

            try {
                const user = await API.get("PrintPortalAPI", this.userApiPath + '/' + params.ID, {
                    headers: {}
                });

                if(user.length>0) {
                    this.setState({
                        body: user[0]
                    });

                    this.setState({loading:false})
                } else {
                    return props.history.push('/users');
                }
            } catch(e) {
                console.log(e);
            }
        } else {
            this.setState(this.defaultState());
        }

        try {
            const clients = await API.get("PrintPortalAPI", this.clientApiPath, {
                headers: {}
            });

            this.setState({clients:clients.data});
        } catch(e) {
            console.log(e);
        }
    };

    insertUser = async (ev) => {
        ev.preventDefault();

        const {body} = this.state;
        const {type} = this.props;

        this.setState({
            buttonText: <CircularProgress color="secondary"/>
        });

        if(!body.Phone) {
            body.Phone = ' ';
        }

        if(type==='edit') {
            await this.updateUser(body);
        } else {

            try {
                const apiResponse = await API.put("PrintPortalAPI", this.userApiPath, {
                    headers: {},
                    body,
                    token: this.props.token
                });

                if (apiResponse.error) {
                    this.setState({
                        error: true,
                        errorMessage: apiResponse.error.message
                    })
                } else {
                    if (apiResponse.formResponse.error.length > 0) {
                        this.setState({error: apiResponse.formResponse.error});
                    } else {
                        return this.props.history.push('/users');
                    }
                }

            } catch (e) {
                console.log(e);
            }
        }

        this.setState({
            buttonText : 'Add User'
        });
    };

    updateUser = async(body) => {
        try {
            const apiResponse = await API.post("PrintPortalAPI", this.userApiPath + '/update/', {
                headers: {},
                body
            });

            if (apiResponse.error) {
                this.setState({
                    error: true,
                    errorMessage: apiResponse.error.message
                })
            } else {
                if (apiResponse.formResponse.error.length > 0) {
                    this.setState({error: apiResponse.formResponse.error});
                } else {
                    return this.props.history.push('/users');
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    handleInputChange = async (property, e) => {
        let value;
        value = e.target.value;

        if(property==='Demo') {
            value = e.target.checked;
        }

        let body = {...this.state.body};
        body[property] = value;

        this.setState({body});
    };

    getError(field) {
        if(this.state.error) {
            for(let i = 0; i < this.state.error.length; i++) {
                if(field===this.state.error[i].field) {
                    return true;
                }
            }
        }
        return false;
    }

    render() {
        const { userGroup, classes } = this.props;
        const { body } = this.state;

        if(userGroup!==this.state.access) {
            return Utils.accessDenied();
        }

        let errorMessage = false;
        if(this.state.errorMessage) {
            errorMessage = (<Snackbar open={true}>
                <SnackbarContent
                    className={classes.error}
                    message={this.state.errorMessage}
                /></Snackbar>)
        }

        return(
            <Paper className={classes.root} elevation={1}>
                <form onSubmit={this.insertUser} noValidate autoComplete="off">
                    <Grid container spacing={24}>
                        <Grid item md={6} sm={12}>
                            <Typography variant="h4" gutterBottom>
                                Details
                            </Typography>

                            {errorMessage}

                            <TextField
                                error={this.getError('Name')}
                                fullWidth
                                required
                                id="Name"
                                label="Name"
                                margin="normal"
                                value={body.Name}
                                onChange={(e) => this.handleInputChange('Name', e)}
                            />

                            <TextField
                                error={this.getError('Email')}
                                fullWidth
                                required
                                id="Email"
                                label="Email"
                                margin="normal"
                                value={body.Email}
                                onChange={(e) => this.handleInputChange('Email', e)}
                            />

                            <TextField
                                fullWidth
                                id="Phone"
                                label="Phone"
                                margin="normal"
                                value={body.Phone}
                                onChange={(e) => this.handleInputChange('Phone', e)}
                            />


                            <TextField
                                fullWidth
                                id="Code"
                                label="Discount Code"
                                margin="normal"
                                value={body.Code}
                                onChange={(e) => this.handleInputChange('Code', e)}
                            />

                            <br /><br />

                            <InputLabel shrink>
                                Client
                            </InputLabel>
                            <Select
                                error={this.getError('Client')}
                                fullWidth
                                required
                                id="Client"
                                label="Client"
                                value={body.Client}
                                onChange={(e) => this.handleInputChange('Client', e)}
                            >
                                {
                                    this.state.clients.map(CLIENT => {
                                        return(
                                            <MenuItem key={CLIENT.ID} value={CLIENT.ID}>
                                                {CLIENT.Name}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>

                            <br /><br />
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={body.Demo}
                                            onChange={(e) => this.handleInputChange('Demo', e)}
                                            value={`${body.Demo}`}
                                        />
                                    }
                                    label="Is demo?"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item md={6} sm={12}>
                            <br /><br />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {this.state.buttonText}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        );
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(UserAdd));