import React, { Component } from 'react';
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove"
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    field : {
        width:"100%",
        '& input': {
            textAlign: "center"
        }
    }
});

class FieldIncrementDecrement extends Component {
    state = {
        value: 0,
        observer: false,
        error:false
    };

    add = () => {
        const { step, max, min, current } = this.props;
        const value = current + step;

        if(value>=min && value<=max) {
            this.setState({value});

            this.props.onChange(value);
        }
    };

    sub = () => {
        const { step, max, min, current } = this.props;
        const value = current - step;

        if(value>=min && value<=max) {
            this.setState({value});

            this.props.onChange(value);
        }

    };

    change = (e) => {
        const { min } = this.props;

        this.setState({
            observerPause : true
        });

        let value = e.target.value.replace(/[^0-9]/, '');
        if(value==='') {
            value=min;
        }

        this.setState({
            value,
            observerPause: false
        });

        this.props.onChange(value);
    };

    componentWillMount() {
        const { current } = this.props;

        this.setState({
            value : current,
        });
    }

    componentDidMount() {
        let observer = setInterval(this.observer,1000);

        this.setState({
            observer
        })
    }

    componentWillUnmount() {
        let { observer } = this.state;

        clearInterval(observer);
    }

    observer = () => {
        const { max, min } = this.props;
        let { value, observerPause } = this.state;

        if(!observerPause) {
            if (value > max) {
                this.setState({
                    error: 'Value needs to be < ' + max
                });
                return false;
            }
            if (value < min) {
                this.setState({
                    error: 'Value needs to be > ' + min
                });
                return false;
            }

            this.setState({
                error: false
            })
        }
    };

    render() {
        const { label, classes } = this.props;
        const { value, error } = this.state;

        return(
            <FormControl error>
                <Grid container>
                    <Grid item md={4} sm={4} xs={4}>
                        <IconButton
                            onClick={this.sub}
                        >
                            <RemoveIcon />
                        </IconButton>
                    </Grid>
                    <Grid item md={4} sm={4} xs={4}>
                        <TextField
                            ref={(value) => this.valInput = value}
                            className={classes.field}
                            onChange={(e) => this.change(e)}
                            label={label}
                            value={value}
                            error={error}
                        />
                    </Grid>
                    <Grid item md={4} sm={4} xs={4}>
                        <IconButton
                            onClick={this.add}
                        >
                            <AddIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <FormHelperText align="center">{error}</FormHelperText>
            </FormControl>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FieldIncrementDecrement);