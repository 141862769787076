import React, { Component } from "react"
import { withStyles } from '@material-ui/core/styles/index';
import { API  } from 'aws-amplify';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import ProductOrder from './ProductOrder';
import ProductGallery from "./ProductGallery";
import OrderReview from './OrderReview';
import ProductDialog from './ProductDialog';

const styles = theme => ({
    box: {
        textAlign: 'center',
        padding:10,
        display: 'flex',
        flexDirection: 'column',
        height:'100%'
    },
    button: {
        marginTop:'auto'
    },
    title: {
        marginTop:10,
        marginBottom:10
    },
    paper: {
        position: 'absolute',
        maxWidth: 650,
        minWidth: 400,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    thumb: {
        maxWidth:'100%'
    },
    subhead: {
        marginBottom:10
    },
    placeOrderButton: {
        marginTop:10,
        marginBottom:10
    }

});

class ProductBox extends Component {
    state = {
        modalOpen: false,
        reviewOrder: false,
        showHiddenAttributes : false
        //data : false,
        //order:{}
    };

    componentWillMount = () => {
        //const { data } = this.props;
        //this.setState({data})
    };

    /*componentWillUpdate = (prevProps, prevState) => {
        const { data } = prevProps;
        if(this.state.data!==data) {
            this.setState({data})
        }
    };*/

    viewProduct = () => {
        this.setState({ modalOpen: true });
    };

    closeProduct = () => {
        this.setState({modalOpen: false});
    };

    /*orderReview = (order) => {
        this.setState({
            reviewOrder: true,
            order
        });
    };*/

    addToBasket = async (body) => {
        try {
            await API.put("PrintPortalAPI", '/basket', {
                headers: {},
                body
            });

            this.closeProduct();
            this.props.basketUpdate();
        } catch(e) {
            console.log(e);
        }

        /*this.setState({
            order
        });*/
    };

    backToProduct = (order) => {
        this.setState({
            reviewOrder:false,
            order
        });
    };

    onChange = (order) => {
        this.setState({order});
    };

    /*toggleHiddenAttributes = () => {
        //console.log('hit me');
    };*/

    render() {
        const {data, classes, user, enquireOnly } = this.props;
        const { order } = this.state;

        if(typeof data.Attributes === 'string' || data.Attributes instanceof String) {
            data.Attributes = JSON.parse(data.Attributes);
        }

        if(order) {

            for (var property in order) {
                if (order.hasOwnProperty(property)) {

                    if(property==='Quantity') {
                        data.Quantity = order[property];
                    } else {
                        let id = order[property].id;
                        let value = order[property].value;

                        for(let i = 0; i<data.Attributes.length; i++) {
                            if(data.Attributes[i].id===id) {
                                data.Attributes[i].value = value
                            }
                        }
                    }

                }
            }
        }

        let content =
            <div>
                <ProductGallery gallery={data.Gallery} />
                <ProductOrder
                    addToBasket={this.addToBasket}
                    onChange={(e) => this.onChange(e)}
                    enquireOnly={enquireOnly}
                    data={data}
                    order={order}
                    user={user}
                />
            </div>,
            actions = false;

        if(this.state.reviewOrder) {
            content = <OrderReview
                data={data}
                user={user}
                backToProduct={(order) => this.backToProduct(order)}
                title={data.Title}
                order={order}
            />;
        }

        let buttonText = 'Order';
        if(enquireOnly) {
            buttonText = 'Enquire';
        }
        return(
            <div className={classes.box}>
                { (data.Thumb ? <div>
                    <img className={classes.thumb} src={data.Thumb} alt={data.Title} />
                    </div> : '')
                }

                <Typography className={classes.Title} color="primary" variant="h6">
                    {data.Title}
                </Typography>

                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={this.viewProduct}
                >
                    {buttonText}
                </Button>
                <ProductDialog
                    title={"Order product - " + data.Title}
                    enquireOnly={enquireOnly}
                    content={content}
                    actions={actions}
                    open={this.state.modalOpen}
                    closeProduct={this.closeProduct}
                    backToProduct={this.backToProduct}
                    reviewOrder={this.state.reviewOrder}
                    toggleHiddenAttributes = {this.toggleHiddenAttributes}
                />
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ProductBox);