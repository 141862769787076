import React, { Component } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Auth } from 'aws-amplify'
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({});

class SignOutConfirm extends Component {

    constructor(props){
        super(props);
        this.state = {
            open: false
        };
    }

    handleClose = () => {
        this.setState({open:false});
        this.props.setSignoutBoxDefault();
    };

    handleAccept = () => {
        Auth.signOut();
        window.location.href = '/';
    };

    componentWillUpdate = (prevProps, prevState) => {
        const { open } = prevProps;
        if(this.state.open!==open) {
            this.setState({open})
        }
    };

    render() {
        return(
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
            >
                <DialogTitle id="alert-dialog-title">{"Really sign out?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleAccept} color="primary" autoFocus>
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>

        )
    }
}

export default withStyles(styles, { withTheme: true })(SignOutConfirm);