import React, { useState, useEffect } from 'react';

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { API } from 'aws-amplify';


const clientSelectorFilterOptions = createFilterOptions({
   stringify: (option) => option.Name
});


function ClientSelector(props) {

   const [ clientList, setClientList ] = useState(null)

   const [ client, setClient ] = useState(null);

   useEffect(() => {
      if ( clientList === null ) {
         API.get("PrintPortalAPI", "/clients", {
            headers  : { },
            response : false
         }).then((response) => {
            setClientList(response.data.map((c) => ({ ID : c.ID, Name : c.Name })).sort((a, b) => (a.Name < b.Name) ? -1 : (a.Name > b.Name) ? 1 : 0));
         }).catch((error) => {
            console.log(error);
         });
      }
   }, [ ]);

   return (clientList === null) ? (
      <CircularProgress color="secondary"/>
   ) : (
      <>
         <Grid>
            <Grid item md={6} sm={6} xs={6}>
               <Autocomplete options={clientList}
                             onChange={(event, option) => setClient(option)}
                             getOptionLabel={(option) => option.Name}
                             renderInput={(params) => <TextField label='Select Client' variant="outlined" fullWidth {...params} />}
                             filterOptions={clientSelectorFilterOptions} />
               <br />
            </Grid>
         </Grid>
         { client && props.render(client.ID) }
     </>
   );

}


export default ClientSelector;

